import React, { useState } from "react";
import Tabs from "src/js/components/Tabs";
import styled from "styled-components";

const DocumentListsTabsWrapper = ({ tabs }) => {
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);

	const handledTabChange = (selectedTabIndex) => {
		setSelectedTabIndex(selectedTabIndex);
	};

	if (tabs?.length <= 1) return tabs?.[0]?.content;

	const tab = tabs?.[selectedTabIndex];
	const tabsFormatted = tabs?.map((t) => ({ ...t, content: t.title }));

	return (
		<Wrapper>
			<Tabs tabs={tabsFormatted} selected={selectedTabIndex} onSelect={handledTabChange} />
			<ContentWrapper key={tab.id}>{tab?.content}</ContentWrapper>
		</Wrapper>
	);
};
export default DocumentListsTabsWrapper;

const Wrapper = styled.div`
	.Polaris-Tabs__Tab {
		padding: 0.375rem 0.1875rem;

		.Polaris-Tabs__Title {
			padding: 0.375rem 0.75rem;
		}
	}
`;
const ContentWrapper = styled.div``;
