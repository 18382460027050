import { Select } from "@shopify/polaris";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import API from "../API";
import { store } from "../store";
import { toastr } from "./toastr";
import Modal from "src/js/components/modal";

class WorkspaceNavigationMoveModal extends Component {
	constructor(props) {
		super(props);
		this.state = { form: props.child || {}, workspaces: [] };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open && this.props.open !== props.open) {
			const workspaces =
				this.getType(props.child) === "board"
					? store.getState().workspaces.flatMap((workspace) => [{ ...workspace, depth: 0 }, ...this.getMoveToWorkspaces(workspace, 1)])
					: store.getState().workspaces.map((workspace) => ({ ...workspace, depth: 0 }));
			this.setState({ form: props.child || {}, workspaces });
		}
	}

	getType(child) {
		return child.handle.replace("_" + child.id, "");
	}

	onChange(field, value) {
		this.setState((c) => ({ ...c, form: { ...c.form, [field]: value } }));
	}

	onSave() {
		this.setState({ loading: true });

		// store.dispatch({ type: "UPDATE_WORKSPACE", workspace: result.data.workspace });

		API.put(
			"/api/workspaces/move.json",
			{
				draggableId: this.props.child.handle,
				workspace_id: this.state.form.parent_id,
				// before_handle: beforeHandle != result.draggableId ? beforeHandle : null,
			},
			{ params: {} }
		)
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.props.onClose?.();
				this.props.onSuccess?.();
				toastr.success(
					this.props.t("workspace.responses.moved_to_workspace", "Flyttade {{title}} till {{toTitle}}", {
						title: this.state.form.title,
						toTitle: this.state.workspaces.find((w) => String(w.id) === String(this.state.form.parent_id))?.title,
					})
				);
			})
			.catch((error) => {
				toastr.error(error);
			})
			.finally(() => {
				this.setState({ loading: false });
			});
	}

	getMoveToWorkspaces(workspace, depth) {
		if (!workspace.children?.length) return [];
		return workspace.children.flatMap((workspace) => [{ ...workspace, depth }, ...this.getMoveToWorkspaces(workspace, depth + 1)]);
	}

	render() {
		const { child } = this.props;

		return (
			<Modal
				title={`${this.props.t("workspace.terms.move", "Flytta")} ${child?.title}`}
				open={this.props.open}
				onClose={this.props.onClose}
				primaryAction={{
					content: this.props.t("common.actions.save", "Spara"),
					onAction: this.onSave.bind(this),
					loading: this.state.loading,
				}}
				secondaryActions={[
					{
						content: this.props.t("common.actions.close", "Stäng"),
						onAction: this.props.onClose,
					},
				]}
			>
				<Modal.Section>
					<Select
						label={this.props.t("workspace.terms.choose_folder", "Välj mapp")}
						options={this.state.workspaces.map((workspace) => ({
							label: `${"-".repeat(workspace.depth)}${workspace.title}`,
							value: String(workspace.id),
						}))}
						onChange={this.onChange.bind(this, "parent_id")}
						value={String(this.state.form?.parent_id || this.state.form?.workspace_id)}
					/>
				</Modal.Section>
			</Modal>
		);
	}
}
export default withTranslation(["workspace", "common"], { withRef: true })(WorkspaceNavigationMoveModal);
