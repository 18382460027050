/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef } from "react";
import { Page as PolarisPage } from "@shopify/polaris";
import type { MenuActionDescriptor, PageProps as PolarisPageProps } from "@shopify/polaris";
// import { withRouter } from "react-router-dom";
import { WithTranslation, withTranslation } from "react-i18next";
import ActionsBar from "./ActionsBar";
import styled from "styled-components";
import { CardListWrapper } from "../CardList";
import Spinner from "../Spinner";
import SkeletonPage from "../Skeletons/Page";

export type SecondaryAction = {
	confirmation?: {
		title?: string | null;
		content?: string | null | React.ReactNode;
		confirm?: string | null;
		cancel?: string | null;
	};
	isGroup?: boolean;
	enabled?: boolean;
	actions?: SecondaryAction[];
} & MenuActionDescriptor;

type PageProps = {
	secondaryActions?: SecondaryAction[];
	history?: any;
	loading?: boolean;
	skeleton?: boolean;
} & PolarisPageProps &
	WithTranslation;

const Page = ({
	children,
	secondaryActions,
	actionGroups,
	primaryAction: propsPrimaryAction,
	title,
	backAction: propsBackAction,
	history,
	t,
	loading,
	titleMetadata,
	skeleton,
	...rest
}: PageProps) => {
	const ref = useRef<HTMLDivElement>(null);
	const [isLoadingPrimaryAction, setIsLoadingPrimaryAction] = React.useState(false);

	const handlePrimaryAction = async () => {
		const action = propsPrimaryAction as any;
		setIsLoadingPrimaryAction(true);
		try {
			if (React.isValidElement(propsPrimaryAction)) {
				await propsPrimaryAction.props.onClick();
			} else {
				await action?.onAction();
			}
		} catch (error) {
			console.error("Page primaryAction error:", error);
		}
		setIsLoadingPrimaryAction(false);
	};
	const primaryActionProps = !propsPrimaryAction
		? undefined
		: React.isValidElement(propsPrimaryAction)
		? React.cloneElement(propsPrimaryAction, {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				/* @ts-ignore */
				onClick: handlePrimaryAction,
				loading: isLoadingPrimaryAction || (propsPrimaryAction as any)?.loading,
		  })
		: { ...(propsPrimaryAction as any), onAction: handlePrimaryAction, loading: isLoadingPrimaryAction || (propsPrimaryAction as any)?.loading };
	const backAction =
		propsBackAction ||
		(history && history?.length > 1 ? { content: t("common.actions.back", "Tillbaka") as string, onAction: () => history.goBack() } : undefined);

	if (loading && skeleton) return <SkeletonPage title={title} />;

	return (
		<Wrapper ref={ref}>
			<PolarisPage
				title={title}
				primaryAction={primaryActionProps}
				backAction={backAction}
				titleMetadata={
					<>
						{titleMetadata}
						{!!loading && (
							<LoadingWrapper>
								<Spinner size={"small"} />
							</LoadingWrapper>
						)}
					</>
				}
				{...rest}
			>
				<ActionsBar secondaryActions={secondaryActions} actionGroups={actionGroups} style={{ opacity: 0.75, marginBottom: "0.5rem" }} />
				{children}
			</PolarisPage>
		</Wrapper>
	);
};
export default withTranslation(["common"])(Page);

// export default (props: PageProps) => {
// 	return <Page {...props} />;
// };

const Wrapper = styled.div`
	&&& {
		/* ${CardListWrapper} {
			.Polaris-Page {
				padding: 0 !important;
			}
		} */
	}
`;

const LoadingWrapper = styled.div`
	.Polaris-Spinner {
		display: flex;
	}
`;
