import React, { Component } from "react";
import { ChevronRightMinor, EditMajor, FavoriteMajor, CirclePlusMajor, SearchMajor } from "@shopify/polaris-icons";
import { LegacyCard, LegacyStack, Collapsible, Icon, ResourceList, Text, Button, Tooltip, TextField } from "@shopify/polaris";

import { withTranslation } from "react-i18next";
import API from "../../API";
import { store } from "../../store";
import { toastr } from "../../components/toastr";
import ProfileAvatar from "../../components/ProfileAvatar";
import InfoView from "../../components/InfoView";
import { StyledDocumentItem } from "../Workspaces/components/BoardUploadSheet";

class ContactContactsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			listOpen: !(props.items && !!props.items.length && props.items.find((c) => c.id == (props.contact && props.contact.default_child))),
			selectedContacts: props.selectedContacts || [],
		};
	}

	markAsFavorite(item) {
		this.props.updateForm("default_child", item.id);

		API.put(`/api/contacts/${this.props.contact.id}/default_child.json`, { default_child_id: item.id })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
				}
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	removeAsFavorite() {
		this.props.updateForm("default_child", null);

		API.delete(`/api/contacts/${this.props.contact.id}/default_child.json`)
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
				}
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	renderContact(item) {
		return (
			<ResourceList.Item
				id={item.id}
				// media={<Avatar source={item.email ? item.avatar : null} customer name={item.name} />}
				onClick={() => this.props.gotoContact && this.props.gotoContact(item, { parent: this.props.contact })}
				// shortcutActions={[
				// 	{
				// 		content: (
				// 			<span className={`${this.props.contact && this.props.contact.default_child == item.id ? "checked " : ""}`}>
				// 				<Icon source={FavoriteMajor} color={this.props.contact && this.props.contact.default_child == item.id && "yellow"} />
				// 			</span>
				// 		),
				// 		onAction: this.markAsFavorite.bind(this, item),
				// 	},
				// 	{
				// 		content: (
				// 			<LegacyStack spacing="tight">
				// 				<Icon source={EditMajor} color="inkLighter" />
				// 				<span>Ändra</span>
				// 			</LegacyStack>
				// 		),
				// 		onAction: () => this.props.openContactModal(item, false),
				// 	},
				// ].filter((i) => i)}
			>
				<div style={{ display: "flex", gap: "0.6250rem", alignItems: "center", justifyContent: "space-between" }}>
					<div style={{ display: "flex", gap: "0.6250rem" }}>
						<ProfileAvatar user={item} size={35} />
						<div style={{ marginTop: "calc(35px/2 - 10px)" }}>
							<div className="CustomerListItem__Main">
								<div className="CustomerListItem__Profile">
									<h3 className="CustomerListItem__Title">{item.name}</h3>
									<span className="CustomerListItem__Location">{item.title}</span>
								</div>
							</div>
							<p style={{ color: "var(--textColor2)" }}>{item.email}</p>
							<p style={{ color: "var(--textColor2)" }}>{item.phone}</p>
							{/* {exceptionList} */}
						</div>
					</div>
					<div
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							return false;
						}}
						style={{ display: "flex", gap: "0.6250rem" }}
					>
						{this.props.openContactModal && (
							<span className={`favorite_mark_button ${this.props.contact && this.props.contact.default_child == item.id ? "checked " : ""}`}>
								<Button plain icon={FavoriteMajor} onClick={this.markAsFavorite.bind(this, item)} />
							</span>
						)}
						{store.getState().user.roles.some((r) => ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"].includes(r)) && this.props.openContactModal && (
							<Button plain icon={EditMajor} onClick={() => this.props.openContactModal(item, false)} />
						)}
					</div>
				</div>
			</ResourceList.Item>
		);
	}

	render() {
		const favoriteContact =
			this.props.items && !!this.props.items.length && this.props.items.find((c) => c.id == (this.props.contact && this.props.contact.default_child));

		const contactInfo =
			favoriteContact &&
			[
				{ label: "Namn", value: favoriteContact.name },
				{ label: "Mailadress", value: favoriteContact.email, type: "email" },
				{ label: "Telefonnummer", value: favoriteContact.phone, type: "phone" },
			].filter(({ value }) => value);

		return (
			// <LegacyCard actions={contactActions}>
			<LegacyCard sectioned>
				<div className="contacts-list__wrapper">
					{favoriteContact && (
						<React.Fragment>
							<div className="pad contacts-list__header">
								<LegacyStack>
									<ProfileAvatar user={favoriteContact} size={40} />
									<div style={{ display: "flex", gap: "0.6250rem" }}>
										<h1>{favoriteContact.name}</h1>

										<span className="star">
											<Tooltip
												content={this.props.t("contact.actions.favorite.remove", "Ta bort {{name}} som favorit", { name: favoriteContact.name })}
											>
												<Button plain icon={FavoriteMajor} onClick={this.removeAsFavorite.bind(this)} />
												{/* <span onClick={this.removeAsFavorite.bind(this)}> */}
												{/* <Icon source={FavoriteMajor} color={"yellow"} /> */}
												{/* </span> */}
											</Tooltip>
										</span>
									</div>
								</LegacyStack>
								{favoriteContact && this.props.openContactModal && (
									<Button icon={EditMajor} onClick={() => this.props.openContactModal(favoriteContact, false)}>
										{this.props.t("common.actions.change", "Ändra")}
									</Button>
								)}
							</div>

							<div className="pad contact-card-info">
								<InfoView details={contactInfo} />
							</div>
							<div className="spacer" />
						</React.Fragment>
					)}

					<div style={{ display: "flex", justifyContent: "space-between", padding: "0 1.2500rem 1.2500rem" }}>
						<div style={{ display: "flex", cursor: "pointer" }} onClick={() => this.setState((c) => ({ listOpen: !c.listOpen }))}>
							<h2 style={{ fontSize: "16px" }}>
								{this.props.t("contact.terms.all", "Alla")} {this.props.t("contact.person.plural", "kontaktpersoner")}{" "}
								{this.props.items && !!this.props.items.length && `(${this.props.items.length})`}{" "}
							</h2>
							<span style={{ transition: "transform 250ms", display: "flex", transform: `rotate(${this.state.listOpen ? 90 : 0}deg)` }}>
								<Icon source={ChevronRightMinor} />
							</span>
						</div>
						{this.props.openContactModal && !this.props.contact?.removed && (
							<Button plain onClick={() => this.props.openContactModal(null, false)}>
								<span style={{ display: "flex", gap: "0.3125rem" }}>
									{this.props.t("common.actions.add_new", "Lägg till ny")}
									<Icon source={CirclePlusMajor} />
								</span>
							</Button>
						)}
					</div>
					<Collapsible open={this.state.listOpen}>
						{(!this.props.items || !this.props.items.length) && this.props.loading && (
							<div style={{ marginLeft: "1.2500rem" }}>
								<StyledDocumentItem style={{ height: "56px" }} />
								<StyledDocumentItem style={{ height: "56px" }} />
								<StyledDocumentItem style={{ height: "56px", marginBottom: "0.6250rem" }} />
							</div>
						)}
						{(!this.props.items || !this.props.items.length) && !this.props.loading ? (
							<LegacyCard.Section>
								<Text variation="subdued">{this.props.t("contact.company.no_connected_contacts", "Det finns inga kontaktpersoner kopplade")}</Text>
							</LegacyCard.Section>
						) : this.props.items && this.props.items.length ? (
							<div className="people">
								<div style={{ marginBottom: "0.3125rem" }}>
									<TextField
										prefix={<Icon source={SearchMajor} color="inkLighter" />}
										placeholder="Sök.."
										value={this.state.search}
										onChange={(search) => {
											this.setState({ search });
										}}
										clearButton
										onClearButtonClick={() => this.setState({ search: "" })}
									/>
								</div>
								<ResourceList
									disabled={this.props.contact?.removed}
									resourceName={{
										singular: this.props.t("contact.person.singular", "kontaktperson"),
										plural: this.props.t("contact.person.plural", "Kontaktpersoner"),
									}}
									items={this.props.items
										.filter((contact) => {
											if (!this.state.search) return true;
											const nameMatches = ["name", "email", "phone", "title"].some((key) =>
												contact?.[key]?.toLowerCase()?.includes(this.state.search.toLowerCase())
											);
											const tagsMatches = contact?.tags?.some((tag) => tag?.title?.toLowerCase().includes(this.state.search.toLowerCase()));
											const metafieldsMatches = contact?.metafields?.some((metafield) =>
												metafield?.value?.toLowerCase()?.includes(this.state.search.toLowerCase())
											);

											return nameMatches || tagsMatches || metafieldsMatches;
										})
										?.sort((a, b) => a.name.localeCompare(b.name))}
									onSelectionChange={(selection) => {
										this.setState({ selectedContacts: selection });
										if (this.props.handleContactChange) this.props.handleContactChange(selection);
									}}
									selectedItems={this.state.selectedContacts}
									// bulkActions={[
									// 	!this.props.roaring && {
									// 		items: [
									// 			{ icon: AddProductMajor, content: "Lägg till taggar", onAction: this.props.addTags },
									// 			{ icon: RemoveProductMajor, content: "Ta bort taggar", onAction: this.props.removeTags },
									// 		],
									// 	},
									// ]}
									renderItem={this.renderContact.bind(this)}
								/>
							</div>
						) : null}
					</Collapsible>
				</div>
			</LegacyCard>
		);
	}
}
export default withTranslation(["contact", "common"], { withRef: true })(ContactContactsList);
