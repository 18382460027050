/* eslint-disable quotes */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { Button, ButtonGroup, ModalProps as PolarisModalProps, Modal as PolarisModal } from "@shopify/polaris";
import type { ComplexAction } from "@shopify/polaris";
import { store } from "../../store";
import useMountTransition from "../../hooks/useMountTransition";
import { getPortalsMaxZIndex } from "../../Utilities";
import ConfirmPopup from "./ConfirmPopup";
import ActionButton from "../page/ActionButton";

interface SecondaryAction extends ComplexAction {
	confirm?: boolean;
	confirmText?: string | React.ReactNode;

	confirmation?: {
		title?: string | null;
		content?: string | null | React.ReactNode;
		confirm?: string | null;
		cancel?: string | null;
	};
}

interface PrimaryAction extends ComplexAction {
	enabled?: boolean;
	confirm?: boolean;
	confirmText?: string | React.ReactNode;
}

interface ModalProps extends PolarisModalProps {
	noTransition?: boolean;
	secondaryActions?: SecondaryAction[];
	primaryAction?: PrimaryAction;
	onOverlayClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

interface ModalComponent extends React.FC<ModalProps> {
	Section: typeof PolarisModal.Section;
}

const ANIMATION_IN = 100;
const ANIMATION_OUT = 200;
const DEFAULT_ZINDEX = 519;

// type ModalReturn = (ReactElement | null) & { Section: ReactElement | null };

const Modal: ModalComponent = ({
	children,
	onClose,
	open,
	title,
	primaryAction,
	noTransition,
	secondaryActions: propsSecondaryActions,
	large,
	footer,
	sectioned,
	onOverlayClick,
	...rest
}: ModalProps) => {
	const hasTransitionedIn = useMountTransition(open, ANIMATION_OUT);
	const ref = useRef<any>();
	const id = useRef<number | undefined>();
	const zIndex = useRef<number | undefined>(DEFAULT_ZINDEX);

	const handleOnClose = useCallback(
		(e?: any) => {
			// if (e) e.stopPropagation();
			e.stopPropagation();
			e.preventDefault();

			if ([2, 419, 418].includes(store.getState().user.id)) {
				onClose?.();
			}
		},
		[onClose]
	);

	const onKeyDown = useCallback(
		(e: any) => {
			if (e.key === "Escape") handleOnClose();
		},
		[handleOnClose]
	);

	const getElementIndex = () => {
		if (!ref.current) return 0;
		return Math.max(Array.from(document.querySelector("#PolarisPortalsContainer")?.children || []).indexOf(ref.current), 0);
	};

	useEffect(() => {
		const dataId = getElementIndex();
		id.current = dataId;
	}, []);

	useEffect(() => {
		if (open) {
			zIndex.current = Math.max(DEFAULT_ZINDEX, getPortalsMaxZIndex()) + 2;
		}
	}, [open]);

	const show = open || hasTransitionedIn;
	const container: Element | null = document.querySelector("#PolarisPortalsContainer") || document.querySelector("body");

	if (!container) return null;

	const getContent = () => {
		if (sectioned) return <PolarisModal.Section>{children}</PolarisModal.Section>;

		return children;
	};

	const handleOverlayClick = (e: any) => {
		onOverlayClick?.(e);
		// if (open) {
		// 	e.stopPropagation();
		// 	e.preventDefault();
		// }
	};

	const secondaryActions = propsSecondaryActions ? (Array.isArray(propsSecondaryActions) ? propsSecondaryActions : [propsSecondaryActions]) : [];

	return (
		<>
			{createPortal(
				// eslint-disable-next-line react/jsx-props-no-spreading
				<Wrapper ref={ref} data-id={id.current} data-zindex={(open && zIndex.current) || null} {...rest} onClick={handleOverlayClick}>
					<div>
						{show && (
							<InnerContainer data-zindex={zIndex.current} onKeyDown={onKeyDown}>
								<div>
									<InnerWrapper data-notransition={noTransition} data-hastransitionedin={hasTransitionedIn} data-open={open}>
										<InnerWrapper2 data-large={large}>
											<Box>
												<HorizontalGrid>
													<HorizontalStack>
														<Title>{title}</Title>
													</HorizontalStack>

													{onClose && (
														<CloseButton onClick={onClose}>
															<CloseButtonSpan>
																<span className="Polaris-Text--root Polaris-Text--visuallyHidden" />
																<CloseButtonSvg>
																	<CloseButtonPath />
																</CloseButtonSvg>
															</CloseButtonSpan>
														</CloseButton>
													)}
												</HorizontalGrid>

												<BodyWrapper>{getContent()}</BodyWrapper>
												<FooterWrapper>
													<FooterWrapperContent>
														{footer && <CustomStack>{footer}</CustomStack>}

														<CustomStack style={{ justifyContent: "space-between" }}>
															<ButtonGroup>
																{secondaryActions?.map((secondaryAction, index) => {
																	const { confirmation, content, onAction, loading, disabled, destructive, icon, confirm, confirmText } =
																		secondaryAction;

																	if (confirmation) {
																		return <ActionButton key={index} item={secondaryAction} plain={false} />;
																	}

																	if (confirm) {
																		return (
																			<ConfirmPopup
																				title={confirmText}
																				key={index}
																				activator={
																					<ModalActionButton
																						key={index}
																						onClick={onAction || (secondaryAction as any)?.onClick}
																						icon={icon}
																						loading={loading}
																						disabled={disabled}
																						destructive={destructive}
																					>
																						{content}
																					</ModalActionButton>
																				}
																			/>
																		);
																	}

																	return (
																		<ModalActionButton
																			key={index}
																			onClick={onAction || (secondaryAction as any)?.onClick}
																			icon={icon}
																			loading={loading}
																			disabled={disabled}
																			destructive={destructive}
																		>
																			{content}
																		</ModalActionButton>
																	);
																})}
															</ButtonGroup>
															<ButtonGroup>
																{primaryAction &&
																	(!("enabled" in primaryAction) || primaryAction.enabled) &&
																	(primaryAction?.confirm ? (
																		<ConfirmPopup
																			title={primaryAction.confirmText}
																			activator={
																				<ModalActionButton
																					primary={!primaryAction.destructive}
																					onClick={primaryAction.onAction}
																					icon={primaryAction.icon}
																					loading={primaryAction.loading}
																					disabled={primaryAction.disabled}
																					destructive={primaryAction.destructive}
																				>
																					{primaryAction.content}
																				</ModalActionButton>
																			}
																		/>
																	) : (
																		<ModalActionButton
																			primary={!primaryAction.destructive}
																			onClick={primaryAction.onAction}
																			icon={primaryAction.icon}
																			loading={primaryAction.loading}
																			disabled={primaryAction.disabled}
																			destructive={primaryAction.destructive}
																		>
																			{primaryAction.content}
																		</ModalActionButton>
																	))}
															</ButtonGroup>
														</CustomStack>
													</FooterWrapperContent>
												</FooterWrapper>
											</Box>
										</InnerWrapper2>
									</InnerWrapper>
								</div>
							</InnerContainer>
						)}
					</div>

					{open && <Backdrop data-zindex={(zIndex.current || DEFAULT_ZINDEX) - 1} onClick={handleOnClose} />}
				</Wrapper>,
				container
			)}
		</>
	);
};

Modal.Section = PolarisModal.Section;

export default Modal;

const ModalActionButton = ({ children, onClick, loading, ...props }) => {
	const [isLoaded, setIsLoaded] = useState(false);

	const handleAction = async () => {
		try {
			setIsLoaded(true);
			return await onClick?.();
		} catch (error) {
			console.error("error:", error);
		} finally {
			setIsLoaded(false);
		}
	};

	return (
		<Button {...props} onClick={handleAction} loading={loading || isLoaded}>
			{children}
		</Button>
	);
};

const Wrapper = styled.div.attrs((props) => ({ "data-portal-id": `modal-Polarisportal${props["data-id"]}` }))`
	.Polaris-Tabs__Outer > .Polaris-Box {
		padding-inline: 0;
	}
`;

type InnerWrapperProps = {
	"data-hastransitionedin"?: boolean | undefined | null;
	"data-open"?: boolean;
	"data-notransition"?: boolean;
	"data-zindex"?: number | null | undefined;
};
const InnerWrapper = styled.div.attrs<InnerWrapperProps>({ className: "Polaris-Modal-Dialog", role: "dialog", "aria-modal": "true", tabindex: "-1" })`
	transition: ${(props) => {
		if (props["data-notransition"]) return null;

		if (props["data-open"]) {
			return `transform ${ANIMATION_IN}ms, opacity ${ANIMATION_IN / 2}ms, filter ${ANIMATION_IN / 2}ms;`;
		}
		return `transform ${ANIMATION_OUT}ms, opacity ${ANIMATION_OUT * 2}ms, filter ${ANIMATION_OUT * 2}ms;`;
	}};
	transform: ${(props) => (props["data-hastransitionedin"] && props["data-open"] ? "translateY(0%)" : "translateY(35%)")};
	opacity: ${(props) => (props["data-hastransitionedin"] && props["data-open"] ? 1 : 0)};
	filter: ${(props) => (props["data-hastransitionedin"] && props["data-open"] ? "none" : "blur(2px)")};
`;
const InnerWrapper2 = styled.div.attrs((props) => {
	return {
		className: `Polaris-Modal-Dialog__Modal ${props["data-large"] ? "Polaris-Modal-Dialog--sizeLarge" : ""}`,
	};
})``;

const BodyWrapper = styled.div.attrs({ className: "Polaris-Modal__BodyWrapper" })`
	flex-direction: column;
	margin-top: 1rem;
`;
const FooterWrapper = styled.div.attrs({ className: "Polaris-Modal-Footer" })`
	margin-top: 1rem;
`;
const FooterWrapperContent = styled.div.attrs({ className: "Polaris-Modal-Footer__FooterContent" })``;

const Title = styled.h2.attrs({ className: "Polaris-Text--root Polaris-Text--headingLg Polaris-Text--break" })``;

type CloseButtonProps = {
	onClick: () => void;
};
const CloseButton = styled.button.attrs<CloseButtonProps>({ className: "Polaris-Modal-CloseButton", "aria-label": "" })``;
const CloseButtonSpan = styled.span.attrs({ className: "Polaris-Icon Polaris-Icon--colorBase Polaris-Icon--applyColor" })``;
const CloseButtonSvg = styled.svg.attrs({ viewBox: "0 0 20 20", className: "Polaris-Icon__Svg", focusable: "false", "aria-hidden": "true" })``;
const CloseButtonPath = styled.path.attrs({
	d: "m11.414 10 6.293-6.293a1 1 0 1 0-1.414-1.414l-6.293 6.293-6.293-6.293a1 1 0 0 0-1.414 1.414l6.293 6.293-6.293 6.293a1 1 0 1 0 1.414 1.414l6.293-6.293 6.293 6.293a.998.998 0 0 0 1.707-.707.999.999 0 0 0-.293-.707l-6.293-6.293z",
})``;

type BackdropProps = {
	onClick: () => void;
	"data-zindex"?: number | null | undefined;
};
const Backdrop = styled.div.attrs<BackdropProps>({
	className: "Polaris-Backdrop",
})`
	z-index: ${(props) => props["data-zindex"] || 518};
`;

type InnerContainerProps = {
	"data-zindex"?: number | null | undefined;
};
const InnerContainer = styled.div.attrs<InnerContainerProps>(() => ({
	className: "Polaris-Modal-Dialog__Container custom",
	"data-polaris-layer": "true",
	"data-polaris-overlay": "true",
}))`
	z-index: ${(props) => props["data-zindex"] || 519};
`;

const Box = styled.div.attrs({
	className: "Polaris-Box",
	style: {
		"--pc-box-border-color": "var(--p-color-border-subdued)",
		"--pc-box-border-style": "solid",
		"--pc-box-border-block-end-width": "var(--p-border-width-1)",
		"--pc-box-padding-block-end-xs": "var(--p-space-4)",
		"--pc-box-padding-block-start-xs": "var(--p-space-4)",
		"--pc-box-padding-inline-start-xs": "var(--p-space-5)",
		"--pc-box-padding-inline-end-xs": "var(--p-space-5)",
	} as React.CSSProperties, // Add type declaration for custom CSS properties
})`
	.Polaris-HorizontalGrid {
		--pc-horizontal-grid-grid-template-columns-x: 1fr auto;
		--pc-horizontal-grid-row-gap: var(--p-space-4);
	}

	.Polaris-HorizontalStack {
		--pc-horizontal-stack-block-align: center;
		--pc-horizontal-stack-wrap: wrap;
		--pc-horizontal-stack-gap-xs: var(--p-space-4);
	}
`;

const HorizontalGrid = styled.div.attrs({
	className: "Polaris-HorizontalGrid",
	style: {
		"--pc-horizontal-grid-grid-template-columns-x": "1fr auto",
		"--pc-horizontal-grid-row-gap": "var(--p-space-4)",
	} as React.CSSProperties,
})`
	grid-template-columns: 1fr auto;
`;

const HorizontalStack = styled.div.attrs({
	className: "Polaris-HorizontalStack",
	style: {
		"--pc-horizontal-stack-block-align": "center",
		"--pc-horizontal-stack-wrap": "wrap",
		"--pc-horizontal-stack-gap-xs": "var(--p-space-4)",
	} as React.CSSProperties,
})``;

const CustomStack = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
`;
