import { Icon, TextField, Thumbnail, FormLayout, Button, Text, ResourceItem, Spinner } from "@shopify/polaris";
import moment from "moment";
import React, { Component } from "react";
import { ArrowRightMinor, ViewMajor, MobileCancelMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import API from "../API";
import EditorView from "../views/Pdf/Editor/EditorView";
import { store } from "../store";
import { toastr } from "./toastr.js";
import BoardHelper from "../views/Workspaces/BoardHelper";
import Sheet from "./sheet";
import Page from "./page";

function getValueFromStandardData(keyString) {
	if (keyString.toLocaleLowerCase() === "today_full_date") {
		return moment().format("YYYY-MM-DD");
	}

	if (keyString.toLocaleLowerCase() === "today_full_date_with_time") {
		return moment().format("YYYY-MM-DD HH:mm:ss");
	}

	if (keyString.toLocaleLowerCase() === "today_time") {
		return moment().format("HH:mm:ss");
	}
}
function getValueFromObject(obj, keyString) {
	//!TEMP-ish, full_name ska vara fullname men full_name är redan sparat i databasen på templates so gör en liten hemmafix här för gammla templates.
	if (keyString === "contact.full_name") {
		keyString = "contact.fullname";
	}

	const contactMetafields = store.getState().contact_metafields;

	const keys = keyString.replace(/\[(\w+)\]/g, ".$1").split("."); // Converts indices into dot notation.

	const result = keys.reduce(
		(acc, key, index) => {
			if (acc.skip) {
				return acc;
			}

			const data = acc.data;
			if (data !== undefined && data !== null) {
				//maybe instead of this "magic" we could either add metafields_by_handle in the contact entity or we could add the metafields_by_handle before calling this function
				if (key === "metafields" && Array.isArray(data[key])) {
					const handleKey = keys[index + 1];
					const item = data[key].find((item) => item.handle == handleKey);

					if (item) {
						const field = contactMetafields.find((f) => f.handle == handleKey);
						if (field && field.type === "options") {
							const option = field.options.find((o) => o.value == item.value);
							return { data: option ? option.label : item.value, skip: true };
						}

						return { data: item.value, skip: true };
					}
					return { ...acc, skip: true };
				} else if (key in data) {
					return { data: data[key] };
				} else if (key.toLowerCase() in data) {
					return { data: data[key.toLowerCase()] };
				}

				return { data: "", skip: true };
			}
			return { ...acc, skip: true };
		},
		{ data: obj, skip: false }
	);

	return result.data;
}

class PdfTemplateSheet extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			template: props.template,
			variables: {},
			templates: [],
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			this.setState({ open: props.open });
		}
		if (props.open && !this.state.open) {
			this.setState(
				{
					variables: {},
					previewing: false,
					template: props.template,
				},
				() => {
					if (!this.state.template) {
						this.fetchTemplates();
					} else {
						if (this.state.template.variables.length < 1) {
							this.preview();
						} else {
							this.updateVariables();
						}
					}
				}
			);
		}
	}

	fetchTemplates() {
		this.setState({ loading: true });
		API.get("/api/pdf_templates.json", {})
			.then((result) => {
				this.setState({ loading: false });
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.setState({ templates: result.data.pdf_templates });
			})
			.catch((error) => {
				this.setState({ loading: false });
				toastr.error(error);
			});
	}

	onVariableChange(key, value) {
		this.state.variables[key] = value;
		this.setState({ variables: this.state.variables });
	}

	preview() {
		this.setState({ previewing: true });
	}

	async print(pages, name = this.props.name) {
		this.setState({ printing: true });

		try {
			const templateId = this.state.template.id;

			if (this.props.saveHandler) {
				return await this.props.saveHandler({ template: this.state.template, pages, name, variables: this.state.variables });
			} else if (this.props.save) {
				return await API.post(
					"/api/pdf_templates/" + templateId + "/download.json",
					{
						pages: pages.map((page) => page.data),
						name,
					},
					{
						params: this.state.variables,
					}
				).then((result) => {
					this.setState({ printing: false });
					if (result.data.error) {
						toastr.error(result.data.error);
						return;
					}
					if (this.props.onSave) this.props.onSave(result.data.upload);
					if (this.props.onClose) this.props.onClose();
				});
			} else {
				return await API.post(
					"/api/pdf_templates/" + templateId + "/print.pdf",
					{
						pages: pages.map((page) => page.data),
					},
					{
						responseType: "blob",
					}
				).then((result) => {
					const url = window.URL.createObjectURL(new Blob([result.data]));
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", this.state.template.title + ".pdf"); // or any other extension
					document.body.appendChild(link);
					link.click();
					this.setState({ printing: false });
					if (this.props.onClose) this.props.onClose();
				});
			}
		} catch (error) {
			toastr.error(error);
		} finally {
			this.setState({ printing: false });
		}
	}

	selectTemplate(template) {
		this.setState({ template });
		this.state.template = template;
		if (template.variables.length < 1) {
			this.preview();
		} else {
			this.updateVariables();
		}
	}

	updateVariables() {
		for (let i = 0; i < this.state.template.variables.length; i++) {
			const handle = this.state.template.variables[i];

			const value =
				getValueFromStandardData(handle) ||
				getValueFromObject(
					{
						user: store.getState().user,
						account: store.getState().account,
						contact: this.props.contact,
						...(this.props.data || {}),
					},
					handle
				);

			this.state.variables[handle.toLocaleLowerCase()] = String(value || "");
		}

		//! These are legeacy variables that should be removed in the future.
		if (this.state.template.variables.some((v) => v.toLocaleLowerCase() == "dagens datum")) {
			this.state.variables["dagens datum"] = moment().format("YYYY-MM-DD");
		}
		if (this.state.template.variables.some((v) => v.toLocaleLowerCase() == "användarens namn")) {
			this.state.variables["användarens namn"] = store.getState().user.name;
		}
		if (this.state.template.variables.some((v) => v.toLocaleLowerCase() == "användarens telefonnr")) {
			this.state.variables["användarens telefonnr"] = store.getState().user.phone;
		}
		if (this.state.template.variables.some((v) => v.toLocaleLowerCase() == "användarens e-post")) {
			this.state.variables["användarens e-post"] = store.getState().user.email;
		}
		const contactColumn = this.props.board && this.props.board.columns.find((c) => c.type === "contact");
		const rowContactValues = contactColumn && this.props.row && this.props.row.column_values[contactColumn.id];
		const rowContactValue = rowContactValues && Array.isArray(rowContactValues.value) && rowContactValues.value[0];
		const rowContact = BoardHelper.getContact(rowContactValue);

		const contact = (() => {
			if (this.props.contact && !this.props.contact.is_company) return this.props.contact;
			if (rowContact && !rowContact.is_company) return rowContact;
		})();
		const company = (() => {
			if (this.props.contact && this.props.contact.is_company) return this.props.contact;
			if (this.props.contact && this.props.contact.parent) return this.props.contact.parent;
			if (rowContact && rowContact.is_company) return rowContact;
		})();

		if (company) {
			if (this.state.template.variables.some((v) => v.toLocaleLowerCase() === "företagsnamn")) {
				this.state.variables["företagsnamn"] = company.name;
			}
			if (this.state.template.variables.some((v) => v.toLocaleLowerCase() == "organisationsnummer")) {
				this.state.variables.organisationsnummer = company.orgnr;
			}
			if (this.state.template.variables.some((v) => v.toLocaleLowerCase() == "företagets epost")) {
				this.state.variables["företagets epost"] = company.email;
			}
			if (this.state.template.variables.some((v) => v.toLocaleLowerCase() == "företagets gatuadress")) {
				this.state.variables["företagets gatuadress"] = company.address || company.address1 || company.street;
			}
			if (this.state.template.variables.some((v) => v.toLocaleLowerCase() == "företagets postnummer")) {
				this.state.variables["företagets postnummer"] = company.zip;
			}
			if (this.state.template.variables.some((v) => v.toLocaleLowerCase() == "företagets ort")) {
				this.state.variables["företagets ort"] = company.city;
			}
			if (this.state.template.variables.some((v) => v.toLocaleLowerCase() == "företagets telefon")) {
				this.state.variables["företagets telefon"] = company.phone;
			}
		}
		if (contact) {
			if (this.state.template.variables.some((v) => v.toLocaleLowerCase() == "kontaktperson namn")) {
				this.state.variables["kontaktperson namn"] = contact.name;
			}
			if (this.state.template.variables.some((v) => v.toLocaleLowerCase() == "kontaktperson epost")) {
				this.state.variables["kontaktperson epost"] = contact.email;
			}
			if (this.state.template.variables.some((v) => v.toLocaleLowerCase() == "kontaktperson telefon")) {
				this.state.variables["kontaktperson telefon"] = contact.phone;
			}
		}

		this.setState({ variables: this.state.variables });
	}

	componentDidUpdate() {
		$(".editor-sheet-close-btn").closest("[data-portal-id]").addClass("sheet-top");
	}

	render() {
		/*
			<Modal
				large
				title={this.state.template ? "Skriv in variablernas värde" : "Välj PDF mall"}
				open={this.props.open}
				onClose={this.props.onClose}
				secondaryActions={[{ content: "Stäng", onAction: this.props.onClose }]}
				primaryAction={
					this.state.template
						? this.props.save
							? { content: "Skapa och spara PDF", icon: SaveMinor, loading: this.state.printing, onAction: this.print.bind(this) }
							: { content: "Skriv ut", icon: PrintMajor, loading: this.state.printing, onAction: this.print.bind(this) }
						: null
				}
			>
		*/
		return (
			<Sheet open={this.props.open} onClose={this.props.onClose} hideHeader>
				<div
					className="editor-sheet-close-btn"
					style={{
						position: "absolute",
						top: 10,
						right: 10,
						zIndex: 400002,
					}}
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<Button accessibilityLabel="Cancel" icon={MobileCancelMajor} onClick={this.props.onClose} plain />
				</div>
				<div
					style={{ paddingTop: 20, overflow: "auto", width: "100%", height: "100%" }}
					className={this.state.template && this.state.previewing ? "embedded-editor bg" : null}
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					{this.state.template && !this.state.previewing ? (
						<Page
							title={this.props.t("common.pdf.terms.fill_in_values", "Fyll i värden")}
							primaryAction={
								<Button primary icon={ViewMajor} onClick={this.preview.bind(this)}>
									{this.props.t("common.pdf.terms.template.preview", "Förhandsgranska")}
								</Button>
							}
						>
							<div style={{ paddingTop: 20 }}>
								<FormLayout>
									{this.state.template &&
										this.state.template.variables &&
										this.state.template.variables.map((val, index) => {
											const value = this.state.variables && this.state.variables[val.toLocaleLowerCase()];
											return (
												<div key={index} className="varible-row">
													<span className="key">{val}</span>
													<Icon source={ArrowRightMinor} />
													<span className="value">
														<TextField value={value} onChange={this.onVariableChange.bind(this, val.toLocaleLowerCase())} />
													</span>
												</div>
											);
										})}
									<Button primary icon={ViewMajor} onClick={this.preview.bind(this)}>
										{this.props.t("common.pdf.terms.template.preview", "Förhandsgranska")}
									</Button>
								</FormLayout>
							</div>
						</Page>
					) : null}
					{this.state.template && this.state.previewing ? (
						<EditorView
							print={!this.props.save && !this.props.saveHandler}
							saving={this.state.printing}
							onSave={this.print.bind(this)}
							requireName={this.props.requireName}
							id={this.state.template.id}
							variables={this.state.variables}
							rowId={this.props.row && this.props.row.id}
						/>
					) : null}
					{!this.state.template ? (
						<Page title={this.props.t("common.pdf.template.title", "Välj mall")}>
							{this.state.templates.map((template, index) => (
								<ResourceItem key={index} media={<Thumbnail source={template.thumbnail_url} />} onClick={this.selectTemplate.bind(this, template)}>
									<h3>
										<Text variation="strong">{template.title}</Text>
									</h3>
								</ResourceItem>
							))}
							{this.state.loading && (!this.state.templates || !this.state.templates.length) && <Spinner />}
						</Page>
					) : null}
				</div>
			</Sheet>
		);
	}
}

export default withTranslation(["common"], { withRef: true })(PdfTemplateSheet);
