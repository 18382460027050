import React, { Component } from "react";
import axios from "axios";
import { Spinner } from "@shopify/polaris";
import moment from "moment";
import styled from "styled-components";
import type { AxiosRequestConfig, AxiosError, Canceler } from "axios";
import API from "../../API";
import { toastr } from "../toastr";
import { store } from "../../store";
import Goal from "../Goal";
import { currencyFormatter } from "../../Utilities";
import Modal from "src/js/components/modal";

interface GoalUsersStatsModalProps {
	open: boolean;
	goal: any;
	onClose: () => void;
}

interface GoalUsersStatsModalState {
	cancelToken?: Canceler | null;
	loadingStats?: boolean;
	// loading?: boolean;
	stats: any[];
	form?: GoalType;
	from?: string;
	to?: string;
	id?: number | string;
	myStats?: {
		value?: number;
		sum?: number;
		count?: number;
	};
}

const UserItem = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	padding-block: 0.625rem;
	padding-inline: 0.3125rem;
	align-items: center;

	&:nth-child(odd) {
		background-color: var(--main2);
	}

	.Polaris-Spinner svg {
		width: 15px;
		height: 15px;
	}
`;

class GoalUsersStatsModal extends Component<GoalUsersStatsModalProps, GoalUsersStatsModalState, any> {
	constructor(props: GoalUsersStatsModalProps) {
		super(props);
		this.state = { stats: [] };
	}

	UNSAFE_componentWillReceiveProps(props: GoalUsersStatsModalProps) {
		if (props.open !== this.props.open) {
			if (props.open) {
				// this.fetchItem(props.goal.id);
				this.setState({ form: props.goal });
				this.fetchStats(
					props.goal.id,
					props.goal.start_at,
					moment(props.goal.end_at).isAfter(moment()) ? moment().format("YYYY-MM-DD") : props.goal.end_at
				);
			} else {
				this.setState({ stats: [] });
				this.cancelRequest();
			}
		}
	}

	// async fetchItem(id: number) {
	// 	this.setState({ loading: true });
	// 	API.get("/api/goals/" + id + ".json")
	// 		.then((result) => {
	// 			this.setState(
	// 				{
	// 					loading: false,
	// 					from: result.data.goal.start_at,
	// 					to: result.data.goal.end_at,
	// 					form: result.data.goal,
	// 				},
	// 				() => {
	// 					// this.carouselRef.current.snapToItem(99);
	// 					this.fetchStats(result.data.goal.id);
	// 				}
	// 			);
	// 		})
	// 		.catch((error) => {
	// 			console.error("error:", error);
	// 			toastr.error(error);
	// 			this.setState({ loading: false });
	// 		});
	// }

	createCancelToken(c: any): void {
		this.setState({ cancelToken: c });
	}

	cancelRequest(): void {
		if (this.state.cancelToken) {
			this.state.cancelToken();
			this.setState({ cancelToken: null });
		}
	}

	async fetchStats(id: number, from: string | undefined, to: string | undefined) {
		this.cancelRequest();
		const CancelToken = axios.CancelToken;

		this.setState({ loadingStats: true });

		API.get("/api/goals/" + id + "/stats.json?total=1&from=" + from + "&to=" + to, {
			cancelToken: new CancelToken(this.createCancelToken.bind(this)),
		})
			.then((result: AxiosRequestConfig) => {
				if (result.data.error) {
					this.setState({
						loadingStats: false,
					});
					return;
				}
				const { mySum, myCount } =
					result?.data?.stats?.reduce(
						(
							acc: {
								mySum: number;
								myCount: number;
							},
							item: GoalUserStatType
						) => {
							if (item?.user_id == 2) {
								acc.mySum += item?.sum || 0;
								acc.myCount++;
							}
							return acc;
						},
						{ mySum: 0, myCount: 0 }
					) || {};
				// Argument of type 'number' is not assignable to parameter of type 'string'.ts(2345)
				const myValue = this.state.form?.users?.reduce((acc: number, item: GoalUserType) => {
					if (item?.user_id == 2) {
						return acc + (item.value || 0);
					}
					return acc;
				}, 0);

				this.setState({
					loadingStats: false,
					stats: result.data.stats,
					myStats: {
						value: myValue,
						sum: mySum,
						count: myCount,
					},
					from,
					to,
				});
			})
			.catch((error: AxiosError) => {
				if (axios.isCancel(error)) {
					this.setState({ loadingStats: false, from, to });
					return;
				}
				console.error("error:", error);
				toastr.error(error);
				this.setState({ loadingStats: false });
			});
	}

	getUsersWithStats() {
		return this.state.form?.users?.map((goalUser: GoalUserType) => {
			const { sum, count } = this.state.stats.reduce(
				(
					acc: {
						sum: number;
						count: number;
					},
					stat: GoalUserStatType
				) => {
					if (String(stat.user_id) === String(goalUser.user_id)) {
						acc.sum += stat.sum;
						acc.count += stat.count;
					}

					return acc;
				},
				{ sum: 0, count: 0 }
			);

			return { goalUser, sum, count };
		});
	}

	render() {
		const users = this.getUsersWithStats();

		return (
			<Modal title={this.state.form?.title} open={this.props.open} onClose={this.props.onClose}>
				<Modal.Section>
					<Goal
						disabled
						stats={this.state.myStats}
						goal={this.state.form}
						onDateChange={({ from, to } = { from: this.state.from, to: this.state.to }) => {
							if (this.state.form?.id) this.fetchStats(this.state.form.id, from, to);
						}}
					/>
					{users
						?.sort((a, b) => {
							return b.sum - a.sum;
						})
						?.map(({ goalUser, sum }) => {
							return (
								<UserItem key={goalUser.user_id}>
									<p>{goalUser.user?.name}</p>

									<p>
										{this.state.loadingStats ? (
											<Spinner size="small" />
										) : (
											currencyFormatter({
												value: sum,
												currency: store.getState().account.currency,
												locale: store.getState().user.locale,
												style: "decimal",
												maximumFractionDigits: 0,
											})
										)}{" "}
										{this.state.form?.suffix || ""}
									</p>
								</UserItem>
							);
						})}
				</Modal.Section>
			</Modal>
		);
	}
}
export default GoalUsersStatsModal;
