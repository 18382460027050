/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Component } from "react";
import { EmptyState, Spinner } from "@shopify/polaris";
import moment from "moment";
import { withTranslation } from "react-i18next";
import API from "../../API";
import BigData from "../../components/big_data.js";
import { toastr } from "../../components/toastr.js";
import StatsBoxWrapper from "../../components/statsBoxes";
import Colors, { colorToRgba } from "../../Colors";
import IconWithBackround from "../../components/IconWithBackround";
import { ReturnIcon, LeadsIcon, NotInterestedIcon, NoAnswerIcon, NotContactedIcon } from "../../icons";
import { store } from "../../store/index.js";
import ImportCompanyBoardModal from "../Workspaces/components/ImportCompanyBoardModal.js";
import IndexTable from "src/js/components/IndexTable";

class CallLists extends Component {
	constructor(props) {
		super(props);
		this.state = {
			empty: false,
			firstFetch: true,
			loadingStats: true,
		};
	}

	componentDidMount() {
		this.fetchBoard(store.getState().account.sales_board_id);
		this.fetchStats();
	}

	fetchBoard(id) {
		API.get(`/api/boards/${id}.json`)
			.then((result) => {
				if (result.data.error) {
					console.error("error:", result.data.error);
					toastr.error(result.data.error);
					return;
				}

				store.dispatch({ type: "SET_BOARDS", boards: [result.data.board] });
				this.setState({ board: result.data.board });
			})
			.catch((error) => {
				console.error("error:", error);
				toastr.error(error);
			});
	}

	gotoBoard(item) {
		this.props.history.push(`/admin/workspaces/${item.workspace_id || "none"}/boards/${item.id}#[]`);
	}

	renderCell(item, column) {
		const StatCount = ({ title, icon }) => (
			<div>
				<div className="CustomerListItem__Profile">
					<h3 className="CustomerListItem__Title email-campaigns-list-status">
						{title ? icon : null}
						{title || "-"}
					</h3>
				</div>
			</div>
		);

		if (column.handle == "TITLE") {
			return (
				<div>
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{item.title}</h3>
						<span className="CustomerListItem__Location" />
					</div>
				</div>
			);
		}

		if (column.handle == "CREATED_AT") {
			return (
				<div>
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{moment(item.created_at).locale("sv").calendar()}</h3>
					</div>
				</div>
			);
		}

		if (column.handle == "LEADS") {
			return <StatCount title={item.lead} icon={<LeadsIcon color={Colors.green} />} />;
		}
		if (column.handle == "RETURNS") {
			return <StatCount title={item.return} icon={<ReturnIcon color={Colors.blue} />} />;
		}
		if (column.handle == "NO_ANSWER") {
			return <StatCount title={item.no_answer} icon={<NoAnswerIcon color={Colors.yellow2} />} />;
		}
		if (column.handle == "NOT_INTERESTED") {
			return <StatCount title={item.not_interested} icon={<NotInterestedIcon color={Colors.red} />} />;
		}
		if (column.handle == "NOT_CONTACTED") {
			return <StatCount title={item.not_contacted} icon={<NotContactedIcon color={Colors.grey} />} />;
		}
	}

	fetchStats() {
		this.setState({ loadingStats: true });
		API.get("/api/call_lists/stats.json")
			.then((result) => {
				if (result.data.error) {
					console.error("error:", result.data.error);
					toastr.error(result.data.error);
				}

				this.setState({ stats: result.data.stats });
			})
			.catch((error) => {
				console.error("error:", error);
				toastr.error(error);
			})
			.finally(() => {
				this.setState({ loadingStats: false });
			});
	}

	onItemsFetched(items) {
		if (items.length < 1 && this.state.firstFetch) {
			this.setState({ empty: true });
		}

		this.setState({ firstFetch: false });
	}

	render() {
		const filters = [
			// {
			// 	key: "sent",
			// 	label: this.props.t("board.calllist.filters.sent.label", "Skickad"),
			// 	type: FilterType.Select,
			// 	options: [
			// 		{ value: "1", label: this.props.t("common.terms.yes", "Ja") },
			// 		{ value: "0", label: this.props.t("common.terms.no", "Nej") },
			// 	],
			// },
			// {
			// 	key: "type",
			// 	label: this.props.t("board.calllist.filters.type.label", "Typ"),
			// 	type: FilterType.Select,
			// 	options: [
			// 		{ label: this.props.t("board.calllist.type.options.newsletter.label", "Nyhetsbrev"), value: "newsletter" },
			// 		{ label: this.props.t("board.calllist.type.options.transactional.label", "Transaktionsmail"), value: "transactional" },
			// 	],
			// },
		];

		const columns = [
			{ handle: "TITLE", label: this.props.t("board.calllist.columns.title.label", "Namn"), sortable: true, type: "text" },
			{ handle: "LEADS", label: this.props.t("board.calllist.columns.leads.label", "Leads"), sortable: false, type: "text" },
			{ handle: "RETURNS", label: this.props.t("board.calllist.columns.returns.label", "Återkomma"), sortable: false, type: "text" },
			{
				handle: "NOT_INTERESTED",
				label: this.props.t("board.calllist.columns.not_interested.label", "Ej intresserade"),
				sortable: false,
				type: "text",
			},
			{ handle: "NO_ANSWER", label: this.props.t("board.calllist.columns.no_answer.label", "Inget svar"), sortable: false, type: "text" },
			{ handle: "NOT_CONTACTED", label: this.props.t("board.calllist.columns.not_contacted.label", "Ej kontaktade"), sortable: false, type: "text" },
			{ handle: "CREATED_AT", label: this.props.t("board.calllist.columns.create_at.label", "Skapad"), sortable: true, type: "text" },
		];

		const getBoxTitle = (key) => {
			if (this.state.loadingStats) return <Spinner size="small" />;

			return this.state.stats?.[key];
		};

		return (
			<div>
				<IndexTable
					history={this.props.history}
					// setRefreshHandler={(refreshHandler) => {
					// 	this.refresh = refreshHandler;
					// }}
					extraHeader={
						<StatsBoxWrapper
							perRow={5}
							items={[
								{
									title: getBoxTitle("lead"),
									text: this.props.t("board.calllist.stats.leads.title", "Leads"),
									icon: <IconWithBackround fill={false} icon={<LeadsIcon color={Colors.green} />} backgroundColor={colorToRgba(Colors.green, 0.2)} />,
								},
								{
									title: getBoxTitle("return"),
									text: this.props.t("board.calllist.stats.return.title", "Återkomma"),
									icon: <IconWithBackround fill={false} icon={<ReturnIcon color={Colors.blue} />} backgroundColor={colorToRgba(Colors.blue, 0.2)} />,
								},
								{
									title: getBoxTitle("not_interested"),
									text: this.props.t("board.calllist.stats.not_interested.title", "Ej intresserade"),
									icon: (
										<IconWithBackround fill={false} icon={<NotInterestedIcon color={Colors.red} />} backgroundColor={colorToRgba(Colors.red, 0.2)} />
									),
								},
								{
									title: getBoxTitle("no_answer"),
									text: this.props.t("board.calllist.stats.no_answer.title", "Inget svar"),
									icon: (
										<IconWithBackround fill={false} icon={<NoAnswerIcon color={Colors.yellow} />} backgroundColor={colorToRgba(Colors.yellow, 0.2)} />
									),
								},

								{
									title: getBoxTitle("not_contacted"),
									text: this.props.t("board.calllist.stats.not_contacted.title", "Ej kontaktat"),
									icon: (
										<IconWithBackround fill={false} icon={<NotContactedIcon color={Colors.grey} />} backgroundColor={colorToRgba(Colors.grey, 0.2)} />
									),
								},
							]}
						/>
					}
					title={this.props.t("board.calllist.plural", "Ringlistor")}
					primaryAction={{
						content: this.props.t("board.calllist.actions.new", "Ny ringlista"),
						onAction: () => {
							this.setState({ createModalIsOpen: true });
						},
					}}
					savedSearchHandle="call_lists"
					resourceUrl="/api/call_lists.json"
					params={{
						view_type: "call_list",
					}}
					resourceHandle="call_lists"
					onItemsFetched={this.onItemsFetched.bind(this)}
					resourceName={{
						singular: this.props.t("board.calllist.singular", "Ringlista"),
						plural: this.props.t("board.calllist.plural", "Ringlistor"),
					}}
					defaultSort="ID_DESC"
					renderCell={this.renderCell.bind(this)}
					onClickRow={(item) => {
						this.gotoBoard(item);
					}}
					columns={columns}
					filters={filters}
					promotedBulkActions={[
						{
							content: this.props.t("board.calllist.actions.delete", "Ta bort"),
							onAction: (items) => {},
						},
					]}
					bulkActions={[]}
					emptyState={
						this.state.empty && (
							<EmptyState
								heading={this.props.t("board.calllist.empty.heading", "Inga ringlistor")}
								action={{
									content: this.props.t("board.calllist.actions.create", "Skapa ringlista"),
									onAction: () => {
										this.setState({ createModalIsOpen: true });
									},
								}}
								image="/assets/images/empty_state/NoResults.png"
							>
								<p>{this.props.t("board.calllist.empty.text", "Du har inga ringlistor")}</p>
							</EmptyState>
						)
					}
				/>

				<ImportCompanyBoardModal
					redirect
					history={this.props.history}
					workspace={{ id: this.state.board?.workspace_id }}
					open={this.state.createModalIsOpen}
					onClose={() => {
						this.setState({ createModalIsOpen: false });
					}}
				/>
			</div>
		);
	}
}

export default withTranslation(["board", "common"], { withRef: true })(CallLists);
