import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import API from "../../../API";
import { toastr } from "../../../components/toastr.js";
import { store } from "../../../store";

import BoardHelper from "../BoardHelper.js";
import RowModalForm from "./RowModalForm.js";
import RowCommentForm from "./RowCommentForm.js";
import RowTicketForm from "./RowTicketForm.js";
import RowVisitorForm from "./RowVisitorForm.js";
import RowContactForm from "./RowContactForm.js";
import RowColumnForm from "./RowColumnForm.js";
import RowActivityLog from "./RowActivityLog.js";
import RowModalFormActions from "../BoardRow/RowModalFormActions";
import { Tabs } from "@shopify/polaris";

class RowSheetContent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingComments: true,
			comments: [],
			activities: [],
			tickets: [],
			selectedTabIndex: this.props.initialTabIndex || 0,
		};
		this.getContent = this.getContent.bind(this);
	}

	componentDidMount() {
		if (this.props.row && this.props.row.id) {
			this.fetchComments(this.props.board?.id, this.props.row.id);
			this.fetchActivities(this.props.board?.id, this.props.row.id);
		}
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open && !this.props.open) {
			this.setState({
				loadingComments: true,
				comments: [],
				activities: [],
				tickets: [],
				selectedTabIndex: props.initialTabIndex || 0,
			});
			if (props.row) {
				this.fetchComments(props.board.id, props.row.id);
				this.fetchActivities(props.board.id, props.row.id);
			}
		}
	}

	fetchComments(boardId, rowId, silent) {
		if (!silent) this.setState({ comments: [], loadingComments: true });
		API.get("/api/boards/" + boardId + "/rows/" + rowId + "/comments.json", {
			params: {},
		})
			.then((result) => {
				this.setState({
					comments: result.data.comments,
					tickets: result.data.tickets,
					loadingComments: false,
				});
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ loadingComments: false });
			});
	}

	fetchActivities(boardId, rowId) {
		this.setState({ activities: [], loadingActivity: true });
		API.get("/api/boards/" + boardId + "/rows/" + rowId + "/activities.json", {
			params: {},
		})
			.then((result) => {
				this.setState({
					activities: result.data.activities,
					loadingActivity: false,
				});
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ loadingActivity: false });
			});
	}

	getTabs() {
		const tabs = [
			{
				id: "row",
				content:
					store.getState().user.calendar_board && store.getState().user.calendar_board.id == this.props.board?.id
						? this.props.t("row.terms.info", "Info")
						: this.props.title || this.props.board?.singular || this.props.t("row.singular", "Rad"),
				panelID: "row",
				board: this.props.board,
				row: this.props.row,
			},
		];

		if (this.props.row) {
			tabs.push({
				id: "comment",
				board: this.props.board,
				row: this.props.row,
				content: this.props.t("row.terms.internal_comment", "Intern kommentar"),
				panelID: "comment",
			});
		}

		const columns = this.props.board?.columns;

		if (this.props.row) {
			for (let i = 0; i < columns.length; i++) {
				if (columns[i].type == "ticket") {
					const values = BoardHelper.getColumnValues(this.props.row, columns[i]);
					for (let l = 0; l < values.length; l++) {
						if (values[l].value.length > 0) {
							for (let x = 0; x < values[l].value.length; x++) {
								const ticket = BoardHelper.getTicket(values[l].value[x]);
								if (ticket) {
									tabs.push({
										id: "ticket_" + ticket.id,
										content: "#" + ticket.number,
										ticket,
										panelId: "ticket" + ticket.id,
									});
								}
							}
						}
					}
				}
			}
		}

		if (this.props.row) {
			for (let i = 0; i < columns.length; i++) {
				if (columns[i].type == "visitor") {
					const values = BoardHelper.getColumnValues(this.props.row, columns[i]);
					for (let l = 0; l < values.length; l++) {
						if (values[l].value.length > 0) {
							for (let x = 0; x < values[l].value.length; x++) {
								const visitor = BoardHelper.getVisitor(values[l].value[x]);
								if (visitor) {
									tabs.push({
										id: "visitor_" + visitor.id,
										content: this.props.t("row.terms.visit", "Besök") + " " + visitor.id,
										visitor,
										panelId: "ticket" + visitor.id,
									});
								}
							}
						}
					}
				}
				if (columns[i].type == "contact") {
					const values = BoardHelper.getColumnValues(this.props.row, columns[i]);
					for (let l = 0; l < values.length; l++) {
						if (values[l].value.length > 0) {
							for (let x = 0; x < values[l].value.length; x++) {
								const contact = BoardHelper.getContact(values[l].value[x]);
								if (contact) {
									if (contact.parent) {
										tabs.push({
											id: "contact_" + contact.id,
											content: contact.name,
											contact,
											panelId: "contact" + contact.id,
										});
										if (!tabs.find((tab) => tab.id == "contact_" + contact.parent.id)) {
											tabs.push({
												id: "contact_" + contact.parent.id,
												content: contact.parent.fullname,
												contact: contact.parent,
												panelId: "contact" + contact.parent.id,
											});
										}
									} else {
										tabs.push({
											id: "contact_" + contact.id,
											content: contact.fullname,
											contact,
											panelId: "contact" + contact.id,
										});
									}
								}
							}
						}
					}
				}
				if (columns[i].type == "upload") {
					tabs.push({
						id: "upload_" + columns[i].id,
						column: columns[i],
						content: columns[i].title,
						panelID: "upload_" + columns[i].id,
					});
				}
			}

			const addedBoardIds = [];

			for (let i = 0; i < this.state.comments.length; i++) {
				if (this.state.comments[i].board.id != this.props.board?.id && addedBoardIds.indexOf(this.state.comments[i].board.id) < 0) {
					addedBoardIds.push(this.state.comments[i].board.id);
					tabs.push({
						id: "board_" + this.state.comments[i].board.id,
						board: this.state.comments[i].board,
						row: this.state.comments[i].row,
						content: this.state.comments[i].board.title,
						panelID: "board_" + this.state.comments[i].board.id,
					});
				}
			}
		}

		if (this.props.row) {
			tabs.push({
				id: "activity",
				board: this.props.board,
				row: this.props.row,
				content: this.props.t("row.terms.log", "Logg"),
				panelID: "activity",
			});
		}

		return tabs;
	}

	getContent(tab) {
		if (tab?.id == "row") {
			return (
				<>
					<RowModalFormActions row={this.props.row} board={this.props.board} onClose={this.props.onClose} />
					<InnerWrapper>
						<RowModalForm
							options={this.props.options}
							onClose={this.props.onClose}
							row={this.props.row}
							ref={(f) => {
								// eslint-disable-next-line react/no-unused-class-component-methods
								this.form = f;
							}}
							onCreateRow={(row) => {
								if (this.props.onCreateRow) this.props.onCreateRow(row);
								this.props.onClose();
							}}
							contact={this.props.contact}
							contacts={this.props.contacts}
							loading={this.state.loadingComments}
							board={this.props.board}
							groups={this.props.groups}
							defaultValues={this.props.defaultValues}
							defaultOptions={this.props.defaultOptions}
							onComplete={this.props.onComplete}
							onUpdateRow={this.props.onUpdateRow}
							onUpdateValue={this.props.onUpdateValue}
						/>

						{this.props.row && (
							<RowCommentForm
								initialTabIndex={this.props.initialTabIndex}
								comments={this.state.comments
									.filter((comment) => tab?.board.id == comment.board.id)
									.map((comment) => ({
										id: comment.id,
										user: comment.user,
										name: comment.user?.name || "-",
										row: comment.row,
										board: comment.board,
										created_at: comment.created_at,
										content: comment.content,
										metadata: comment.metadata,
										mentions: comment.mentions,
										files: comment.files,
									}))}
								row={tab?.row}
								board={tab?.board}
								onCreate={(comment) => {
									this.state.comments.unshift(comment);
									this.setState({ comments: this.state.comments });
								}}
								onUpdate={(comment, index) => {
									this.state.comments[index] = comment;
									this.setState({ comments: this.state.comments });
								}}
								onRemove={(comment) =>
									this.setState({
										comments: this.state.comments.filter((com) => com.id !== comment.id),
									})
								}
							/>
						)}
					</InnerWrapper>
				</>
			);
		} else if (tab?.ticket) {
			const foundTicket = this.state.tickets.find((ticket) => tab?.ticket.id == ticket.id);
			let ticket = null;
			if (foundTicket) {
				ticket = foundTicket;
			} else {
				ticket = tab?.ticket;
			}
			if (!ticket || !ticket.contact || !ticket.events) this.fetchComments(this.props.board?.id, this.props.row?.id, true);

			return (
				<RowTicketForm
					row={this.props.row}
					loading={this.state.loadingComments}
					board={this.props.board}
					ticket={ticket}
					updateTicket={(ticket) => {
						if (!this.state.tickets.find((t) => t.id == ticket.id)) {
							this.state.tickets.push(ticket);
							this.setState({ tickets: this.state.tickets });
						} else {
							const otherTickets = this.state.tickets.filter((t) => t.id != ticket.id);
							const tickets = [].concat(otherTickets, [ticket]);
							this.setState({ tickets });
						}
					}}
				/>
			);
		} else if (tab?.visitor) {
			return (
				<RowVisitorForm key={tab?.visitor.id} row={this.props.row} session_id={tab?.visitor.id} board={this.props.board} contact={tab?.visitor} />
			);
		} else if (tab?.contact) {
			return (
				<RowContactForm
					external={this.props.external}
					row={this.props.row}
					loading={this.state.loadingComments}
					board={this.props.board}
					contact={tab?.contact}
				/>
			);
		} else if (tab?.column) {
			return <RowColumnForm row={this.props.row} column={tab?.column} board={this.props.board} history={this.props.history} />;
		} else if (tab?.id == "activity") {
			return <RowActivityLog row={this.props.row} loading={this.state.loadingActivity} board={this.props.board} events={this.state.activities} />;
		} else {
			return (
				<RowCommentForm
					initialTabIndex={this.props.initialTabIndex}
					comments={this.state.comments
						.filter((comment) => tab?.board.id == comment.board.id)
						.map((comment) => ({
							id: comment.id,
							user: comment.user,
							name: comment.user.name,
							row: comment.row,
							board: comment.board,
							created_at: comment.created_at,
							content: comment.content,
							metadata: comment.metadata,
							mentions: comment.mentions,
							files: comment.files,
						}))}
					row={tab?.row}
					board={tab?.board}
					onCreate={(comment) => {
						this.state.comments.unshift(comment);
						this.setState({ comments: this.state.comments });
					}}
					onUpdate={(comment, index) => {
						this.state.comments[index] = comment;
						this.setState({ comments: this.state.comments });
					}}
					onRemove={(comment) =>
						this.setState({
							comments: this.state.comments.filter((com) => com.id !== comment.id),
						})
					}
				/>
			);
		}
	}

	render() {
		const tabs = this.getTabs();
		const isCalendar =
			(store.getState().user.calendar_board && store.getState().user.calendar_board.id) == (this.props.board && this.props.board?.id);
		const showTabs = this.props.row && this.props.row.id;
		return (
			<div
				className={`row-sheet ${
					this.props.row && BoardHelper.isSupportBoard(this.props.row.board_id) && this.state.selectedTabIndex == 1 ? "support-ticket" : ""
				}`}
			>
				{!isCalendar ? (
					showTabs ? (
						<Tabs tabs={tabs} selected={this.state.selectedTabIndex} onSelect={(tabIndex) => this.setState({ selectedTabIndex: tabIndex })}>
							<div className="content-wrapper">{this.getContent(tabs[this.state.selectedTabIndex])}</div>
						</Tabs>
					) : (
						<div className="content-wrapper">{this.getContent(tabs[this.state.selectedTabIndex])}</div>
					)
				) : (
					this.getContent({ id: "row", board: this.props.board, row: this.props.row })
				)}
				{/* {tabs[this.state.selectedTabIndex].id == "row" && !this.props.row && !isCalendar && (
					<div style={{ padding: 15 }}>
						<Button
							loading={this.state.saving}
							onClick={() => {
								this.setState({ saving: true });
								try {
									this.form
										.saveForm()
										.then((row) => {
											if (this.props.onCreate) this.props.onCreate(row);
										})
										.catch((e) => this.setState({ saving: false }));
								} catch (error) {
									console.error("error:", error);
									this.setState({ saving: false });
								}
							}}
							primary
						>
							Spara
						</Button>
					</div>
				)} */}
			</div>
		);
	}
}
export default withTranslation(["row", "common"], { withRef: true })(RowSheetContent);

const InnerWrapper = styled.div`
	display: flex;
	gap: 1rem;
	/* padding: 0.6250rem; */
	flex-wrap: wrap;

	> * {
		flex: 1 1 450px;
	}
`;
