import React, { Component } from "react";
import moment from "moment";
import nl2br from "react-nl2br";
import { Banner, LegacyStack, DropZone, Button, Tooltip, ButtonGroup, Avatar, Icon, Text, TextField } from "@shopify/polaris";
import { SendMajor, EditMajor, AttachmentMajor } from "@shopify/polaris-icons";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import API from "../API";
import { toastr } from "./toastr.js";
import Attachment from "./Attachment.js";
import Colors from "../Colors.js";
import TicketStatus from "./TicketStatus.js";
import Tabs from "./Tabs";
import Modal from "src/js/components/modal";

class SideTicketModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			randomid: "rndticketinp" + Math.round(Math.random() * 1000),
			open: props.open,
			ticket: props.ticket || { events: [] },
			form: {
				internal: false,
				message: null,
				status: "pending",
				files: [],
				followers: [],
			},
			saving: false,
		};
	}

	addFile(file, event) {
		const data = event.target.result;
		const files = this.state.form.files;
		files.push({ name: file.name, size: file.size, data });
		this.state.form.files = files;
		this.setState({ form: this.state.form });
	}

	updateForm(field, value) {
		const formState = this.state.form;
		formState[field] = value;
		this.setState({ form: formState });
	}

	removeFile(index, file) {
		this.state.form.files.splice(index, 1);
		this.setState({ form: this.state.form });
	}

	updateFile(index, file) {
		this.state.form.fifles[index] = file;
		this.setState({ form: this.state.form });
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			this.setState({
				open: props.open,
				ticket: props.ticket || { events: [] },
				form: {
					internal: false,
					message: null,
					status: "pending",
					files: [],
					followers: [],
				},
			});
		}
	}

	getStatusMessage(status) {
		if (status == "closed") {
			return this.props.t("ticket.message_status.closed", "Avslutade ärendet");
		}
		if (status == "removed") {
			return this.props.t("ticket.message_status.removed", "Tog bort ärende");
		}
		if (status == "pending") {
			return this.props.t("ticket.message_status.pending", "Markerades som väntande");
		}
		if (status == "spam") {
			return this.props.t("ticket.message_status.spam", "Markerade som spam");
		}
		if (status == "open") {
			return this.props.t("ticket.message_status.open", "Ärendet öppnades");
		}
	}

	saveForm() {
		this.setState({ saving: true });

		API.post("/api/tickets/" + this.props.ticket.id + "/events.json", this.state.form, { params: {} })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					this.setState({ saving: false });
					return;
				}

				this.state.form.message = "";
				this.state.form.files = [];
				this.props.ticket.events.push(result.data.event);
				this.setState({ form: this.state.form, saving: false });
				if (result.data.event && result.data.event.have_email) toastr.success(this.props.t("ticket.responses.answer_sent", "Slet skickades"));
				this.props.onUpdate(result.data.ticket, result.data.event);
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	getStatusStatus(status) {
		if (status == "closed") {
			return "success";
		}
		if (status == "removed") {
			return "critical";
		}
		if (status == "spam") {
			return "critical";
		}
		return "default";
	}

	render() {
		let buttonTitle;
		let actionText;
		let tabs = [];
		let haveEmail;

		if (this.state.form.status == "open") {
			buttonTitle = this.props.t("ticket.actions.open", "Skicka och öppna");
		} else if (this.state.form.status == "pending") {
			buttonTitle = this.props.t("ticket.actions.pending", "Skicka och vänta");
		} else if (this.state.form.status == "closed") {
			buttonTitle = this.props.t("ticket.actions.closed", "Skicka och avsluta");
		} else if (this.state.form.status == "spam") {
			buttonTitle = this.props.t("ticket.actions.spam", "Markera som spam");
		} else if (this.state.form.status == "removed") {
			buttonTitle = this.props.t("ticket.actions.removed", "Ta bort");
		}

		if (this.state.ticket.channel == "chat") {
			haveEmail = true;
			if (this.state.form.internal) {
				actionText = this.props.t("ticket.text.inter_comment", "En intern kommentar skapas, inget skickas till kontakt");
			} else {
				actionText = this.props.t("ticket.text.sent_to_chat", "Ditt slet kommer skickas till chatten");
			}
			tabs = [
				{
					id: "visible",
					disabled: true,
					content: (
						<div>
							<Icon source={SendMajor} />
							{this.props.t("ticket.tabs.visible", "Meddelande till kund")}
						</div>
					),
					panelID: "visible",
				},
				{
					id: "internal",
					content: (
						<div>
							<Icon source={EditMajor} />
							{this.props.t("ticket.tabs.internal", "Intern kommentar")}
						</div>
					),
					panelID: "internal",
				},
			];
		} else {
			haveEmail = false;
			if (this.state.ticket.contact && this.state.ticket.contact.email) {
				haveEmail = true;
				if (this.state.form.internal) {
					actionText = this.props.t("ticket.text.internal", "En intern kommentar skapas, inget skickas till kontakt");
				} else {
					actionText = this.props.t("ticket.text.not_internal", "Ett mail kommer att skickas till") + ": " + this.state.ticket.contact.email;
				}
				tabs = [
					{
						id: "visible",
						disabled: true,
						content: (
							<div>
								<Icon source={SendMajor} />
								{this.props.t("ticket.tabs.visible", "Meddelande till kund")}
							</div>
						),
						panelID: "visible",
					},
					{
						id: "internal",
						content: (
							<div>
								<Icon source={EditMajor} />
								{this.props.t("ticket.tabs.internal", "Intern kommentar")}
							</div>
						),
						panelID: "internal",
					},
				];
			} else {
				actionText = this.props.t("ticket.text.no_email_only_internal", "Ingen e-post finns, endast intern kommentar kan skapas till denna kontakt");
				tabs = [
					{
						id: "internal",
						content: (
							<div>
								<Icon source={EditMajor} />
								{this.props.t("ticket.tabs.internal", "Intern kommentar")}
							</div>
						),
						panelID: "internal",
					},
				];
			}
		}

		return (
			<Modal
				open={this.state.open}
				onClose={this.props.onClose}
				title={"#" + this.state.ticket.number + " - " + this.state.ticket.title}
				secondaryActions={[
					{
						content: this.props.t("common.actions.close", "Stäng"),
						onAction: this.props.onClose,
					},
				]}
			>
				{this.state.ticket.contact ? (
					<Modal.Section>
						<Text variation="subdued">{this.state.ticket.contact.fullname}</Text>
					</Modal.Section>
				) : null}

				{this.state.ticket.events
					.sort((b, a) => (a.id > b.id ? -1 : b.id > a.id ? 1 : 0))
					.map((event) => (
						<Modal.Section key={event.id}>
							<LegacyStack vertical>
								<LegacyStack wrap={false}>
									<div style={{ width: 40 }}>
										<Avatar
											source={
												event.user
													? event.user.email
														? event.user.avatar
														: null
													: event.contact && event.contact.email
													? event.contact.avatar
													: null
											}
											customer
											name={event.user ? event.user.name : event.contact ? event.contact.name : null}
										/>
									</div>
									<LegacyStack.Item fill>
										<div>
											<LegacyStack>
												<Text variant="headingMd" as="h2">
													{event.user ? event.user.name : event.contact ? event.contact.name : null}
												</Text>
												<div
													style={{
														textAlign: "right",
														position: "relative",
														top: 4,
													}}
												>
													<Text variation="subdued">{moment(event.created_at).locale("sv").calendar()}</Text>
												</div>
											</LegacyStack>
										</div>
										<div style={{ marginTop: 15 }}>
											{event.status ? <Banner status={this.getStatusStatus(event.status)}>{this.getStatusMessage(event.status)}</Banner> : null}
											{event.internal ? null : <div>{nl2br(event.message)}</div>}
										</div>
										{Array.isArray(event.files) && event.files.map((file, key) => <Attachment fontSize="0.85em" key={key} file={file} />)}
										{!event.have_email && !event.internal && this.state.ticket.channel == "email" && (
											<span style={{ color: Colors.red }}>{this.props.t("ticket.text.no_email_sent", "Inget mail har skickats")}</span>
										)}
									</LegacyStack.Item>
								</LegacyStack>
								{event.internal ? (
									<div className="internal-comment">
										<LegacyStack wrap={false}>
											<div style={{ width: 40 }} className="icon-wrapper">
												<Icon source={EditMajor} />
											</div>
											<LegacyStack.Item fill>
												<div style={{ marginBottom: 10 }}>
													<strong> {this.props.t("ticket.tabs.internal", "Intern kommentar")}</strong>
												</div>
												{nl2br(event.message)}
												<div style={{ marginTop: 15 }}>
													{event.status ? <Banner status={this.getStatusStatus(event.status)}>{this.getStatusMessage(event.status)}</Banner> : null}
													{event.internal ? null : <div>{nl2br(event.message)}</div>}
												</div>
												{Array.isArray(event.files) && event.files.map((file, key) => <Attachment fontSize="0.85em" key={key} file={file} />)}
											</LegacyStack.Item>
										</LegacyStack>
									</div>
								) : null}
							</LegacyStack>
						</Modal.Section>
					))}

				<Modal.Section>
					<div className="ticket">
						<Tabs
							tabs={tabs}
							fitted
							selected={this.state.form.internal ? (!haveEmail ? 0 : 1) : 0}
							onSelect={(tabIndex) => {
								if (this.props.ticket.contact && this.props.ticket.contact.email) {
									this.state.form.internal = !!tabIndex;
								} else {
									this.state.form.internal = true;
								}
								this.setState({ form: this.state.form });
							}}
						>
							<div>
								<div className="textfield-wrapper">
									<TextFieldWrapper id={this.state.randomid}>
										<TextField
											placeholder={
												this.state.form.internal
													? this.props.t("ticket.text.write_internal_comment", "Skriv intern kommentar här")
													: this.props.t("ticket.text.write_message_comment", "Skriv ditt meddelande här...")
											}
											value={this.state.form.message}
											multiline
											suffix={
												<AttachButtonWrapper>
													<Tooltip content={this.props.t("ticket.actions.upload", "Ladda upp fil")}>
														<Button
															plain
															size="slim"
															onClick={() => {
																this.setState({ openFileDialog: true });
															}}
														>
															<Icon color="skyDark" source={AttachmentMajor} />
														</Button>
													</Tooltip>
												</AttachButtonWrapper>
											}
											onChange={this.updateForm.bind(this, "message")}
										/>
									</TextFieldWrapper>
									<div style={{ paddingTop: 5 }}>
										{Array.isArray(this.state.form.files) &&
											this.state.form.files.map((file, key) => (
												<Attachment
													editable
													onRemove={this.removeFile.bind(this, key)}
													onChange={this.updateFile.bind(this, key)}
													key={key}
													file={file}
													fontSize="0.85em"
												/>
											))}
									</div>
								</div>
								<div style={{ width: 50, height: 50, display: "none" }}>
									<DropZone
										openFileDialog={this.state.openFileDialog}
										onFileDialogClose={() => {
											this.setState({ openFileDialog: false });
										}}
										onDrop={(files, acceptedFiles, rejectedFiles) => {
											for (let i = 0; i < acceptedFiles.length; i++) {
												const reader = new FileReader();
												reader.onload = this.addFile.bind(this, acceptedFiles[i]);
												reader.readAsDataURL(acceptedFiles[i]);
											}
										}}
									>
										<DropZone.FileUpload
											actionTitle={this.props.t("common.upload.actions.choose", "Välj fil")}
											actionHint={this.props.t("common.upload.actions.helptext", "Eller dra och släpp en fil")}
										/>
									</DropZone>
								</div>

								<div style={{ paddingTop: 20 }}>
									<LegacyStack wrap={false}>
										<LegacyStack.Item fill>
											<div className="ticket-action">{actionText}</div>
										</LegacyStack.Item>
										{!this.state.updated_ticket ? (
											<ButtonGroup segmented>
												<Button
													outline={this.state.form.status == "pending"}
													primary={this.state.form.status == "closed"}
													destructive={!!(this.state.form.status == "removed" || this.state.form.status == "spam")}
													loading={this.state.saving}
													disabled={
														!this.props.ticket ||
														(!this.state.form.message &&
															(!this.state.form.files || !this.state.form.files.length) &&
															this.state.form.status == this.props.ticket.status)
													}
													onClick={this.saveForm.bind(this)}
												>
													{buttonTitle}
												</Button>
												{!this.state.saving ? (
													<TicketStatus
														status={this.state.form.status}
														onChange={(status) => {
															this.state.form.status = status;
															this.setState({ form: this.state.form });
														}}
													/>
												) : null}
											</ButtonGroup>
										) : null}
										{this.state.updated_ticket ? (
											<Button onClick={this.updateTicket.bind(this)} primary>
												{this.props.t("ticket.responses.updated", "Ärendet har uppdaterats")}!
											</Button>
										) : null}
									</LegacyStack>
								</div>
							</div>
						</Tabs>
					</div>
				</Modal.Section>
			</Modal>
		);
	}
}

const AttachButtonWrapper = styled.span`
	.Polaris-Icon {
		width: 0.9375rem;
		height: 0.9375rem;
	}
`;

const TextFieldWrapper = styled.span`
	.Polaris-TextField__Suffix {
		margin-right: 0.625rem;
	}

	.Polaris-TextField {
		align-items: flex-start;
	}
`;

export default withTranslation(["ticket", "common", "upload"], { withRef: true })(SideTicketModal);
