import React, { Component } from "react";
import { PageActions, Layout, LegacyCard, ChoiceList, TextField } from "@shopify/polaris";
import { connect } from "react-redux";
import styled from "styled-components";
import debounce from "lodash/debounce";
import { withTranslation } from "react-i18next";
import Page from "src/js/components/page";
import API from "../../API";
import { store } from "../../store";
import SearchField from "../../components/search_field.js";
import { toastr } from "../../components/toastr.js";
import AdAccountSelector from "../../components/AdAccountSelector.js";
import AdCampaignSelector from "../../components/AdCampaignSelector.js";
import AdStatusSelector from "../../components/AdStatusSelector.js";
import AdDesigner from "./AdDesigner";
import AdAccountSelectorModal from "./AdAccountSelectorModal";
import AdPreview from "./AdPreview";

class AdCreate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			saving: false,
			form: {
				title: "",
				type: "search",
				max_cpc: 5,
				daily_budget: 50,
				status: "ENABLED",
				keyword: "",
				url: "",
				audiences: [],
				metafields: {},
				ad_campaign: null,
				// ad_account: store.getState().ad_accounts && store.getState().ad_accounts.length ? store.getState().ad_accounts[0] : null,
				ad_account: null,
			},
			invalidFormFields: {},
			invalidMetaFields: {},
			adAccountSelectorModalOpen: true,
		};
		if (this.props.location.state && this.props.location.state.stacks && this.props.location.state.stacks.length) {
			const currentStack = this.props.location.state.stacks[this.props.location.state.stacks.length - 1];
			if (currentStack.item) {
				this.state.form = currentStack.item;
			}
		}
		this.getYoutubeVideo = debounce(this.getYoutubeVideoDetails.bind(this), 2000, { maxWait: 5000, leading: false, trailing: true });
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (!this.state.form.ad_account) {
			this.state.form.ad_account = store.getState().ad_accounts.length ? store.getState().ad_accounts[0] : null;
			this.setState({ form: this.state.form });
		}
		if (!this.state.form.audiences) {
			this.state.form.audiences = [];
		}
	}

	getYoutubeVideoDetails(id) {
		API.get(`/api/youtube/video/${id}.json?channelInfo=true`)
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					this.state.form.metafields.profile_image_url = "";
					this.setState({ form: this.state.form, youtubeVideoDetails: null });

					return;
				}

				try {
					if (
						result.data.video &&
						result.data.video.channel &&
						result.data.video.channel.items[0] &&
						result.data.video.channel.items[0].snippet &&
						result.data.video.channel.items[0].snippet.thumbnails
					) {
						this.state.form.metafields.profile_image_url = result.data.video.channel.items[0].snippet.thumbnails.default.url;
						this.setState({ form: this.state.form, youtubeVideoDetails: result.data.video });
					}
				} catch (error) {
					console.error("error:", error);
				}
			})
			.catch((error) => {
				toastr.error(error);
				this.state.form.metafields.profile_image_url = "";
				this.setState({ form: this.state.form, youtubeVideoDetails: null });
			});
	}

	updateForm(field, value) {
		const formState = this.state.form;
		formState[field] = value;
		this.state.invalidFormFields[field] = "";

		this.setState({ form: formState, invalidFields: this.state.invalidFields });
	}

	saveForm() {
		if (!this.checkValidRequiredFields()) {
			toastr.warning(this.props.t("common.errors.fields_required", "Vänligen fyll i alla obligatoriska fält"));
			this.setState({
				invalidFormFields: this.checkValidRequiredFields({ returnFalseFields: true }).form.reduce((acc, i) => {
					acc[i] = this.props.t("common.errors.field_required", "Vänligen fyll i");
					return acc;
				}, {}),
				invalidMetaFields: this.checkValidRequiredFields({ returnFalseFields: true }).metafields.reduce((acc, i) => {
					acc[i] = this.props.t("common.errors.field_required", "Vänligen fyll i");
					return acc;
				}, {}),
			});
			return;
		}
		this.setState({ saving: true });

		API.post("/api/ans/" + this.state.form.ad_account.id + "/ads.json", this.state.form)
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				toastr.success(this.props.t("ads.response.created", "Skapade annons"));
				this.props.history.replace("/admin/ads/" + result.data.ad.id);
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	checkValidRequiredFields({ returnFalseFields } = {}) {
		try {
			const formFields = (() => ["type", "ad_account", "title", "ad_campaign", "max_cpc", "daily_budget", "audiences", "url", "metafields"])();
			const validFormFields = this.state.form && formFields.every((i) => this.state.form[i]);

			const metaFields = (() => {
				if (this.state.form.ad_campaign && this.state.form.ad_campaign.type == "search" && this.state.form.ad_account.type === "adwords") {
					return ["headline1", "headline2", "description1"];
				}
				if (this.state.form.ad_campaign && this.state.form.ad_campaign.type == "display" && this.state.form.ad_account.type === "adwords") {
					return ["image", "square_image", "headline", "long_headline", "description", "business_name", "call_to_action"];
				}
				if (this.state.form.ad_campaign && this.state.form.ad_campaign.type == "video" && this.state.form.ad_account.type === "adwords") {
					return ["youtube_video_id", "headline", "long_headline", "description", "business_name", "call_to_action"];
				}

				if (this.state.form.ad_campaign && this.state.form.ad_campaign.type == "display" && this.state.form.ad_account.type === "facebook") {
					return ["image", "primary_text", "title", "description"];
				}
				return [];
			})();
			const validMetaFields = this.state.form.metafields && metaFields.every((i) => this.state.form.metafields[i]);

			if (returnFalseFields)
				return { form: formFields.filter((i) => !this.state.form[i]), metafields: metaFields.filter((i) => !this.state.form.metafields[i]) };

			return validFormFields && validMetaFields;
		} catch (error) {
			console.error("error:", error);
			if (returnFalseFields) return {};
			return false;
		}
	}

	updateMetafield(key, value) {
		if (key === "youtube_video_id") {
			this.getYoutubeVideo(value);
		}
		this.state.form.metafields[key] = value;
		this.state.invalidMetaFields[key] = "";

		this.setState({ form: this.state.form, invalidMetaFields: this.state.invalidMetaFields });
	}

	handleCloseAdSelector() {
		this.setState({ adAccountSelectorModalOpen: false });
	}

	render() {
		const typeOptions = [];
		// if (this.state.form.ad_account) {
		// 	return <Spinner />;
		// }
		if (this.state.form.ad_account && this.state.form.ad_campaign) {
			if (this.state.form.ad_account.type == "adwords") {
				if (this.state.form.ad_campaign.type == "search") {
					typeOptions.push({ label: this.props.t("ad_campaign.type.search", "Sökannons"), value: "search" });
				} else if (this.state.form.ad_campaign.type == "display") {
					typeOptions.push({ label: this.props.t("ad_campaign.type.remarketing", "Remarketing (besökare)"), value: "remarketing" });
					typeOptions.push({ label: this.props.t("ad_campaign.type.custom_audience", "Egna målgrupper"), value: "custom_audience" });
				} else if (this.state.form.ad_campaign.type == "video") {
					typeOptions.push({ label: this.props.t("ad_campaign.type.remarketing", "Remarketing (besökare)"), value: "remarketing" });
					typeOptions.push({ label: this.props.t("ad_campaign.type.custom_audience", "Egna målgrupper"), value: "custom_audience" });
				}
			} else if (this.state.form.ad_account.type == "facebook") {
				typeOptions.push({ label: this.props.t("ad_campaign.type.remarketing", "Remarketing (besökare)"), value: "remarketing" });
				typeOptions.push({ label: this.props.t("ad_campaign.type.custom_audience", "Egna målgrupper"), value: "custom_audience" });
			}
		}

		if (!this.state.form.type || !typeOptions.find((i) => i.value === this.state.form.type)) {
			this.state.form.type = typeOptions && typeOptions[0] && typeOptions[0].value;
		}

		const primaryAction = {
			content: this.props.t("common.actions.create", "Skapa"),
			disabled: !this.state.form.title || !this.state.form.ad_campaign || !this.state.form.ad_account,
			loading: this.state.saving,
			onAction: this.saveForm.bind(this),
		};

		return (
			<Page
				title={this.props.t("ads.actions.new", "Ny annons")}
				backAction={{ content: this.props.t("common.actions.back", "Tillbaka"), onAction: () => this.props.history.goBack() }}
				primaryAction={primaryAction}
			>
				<Wrapper>
					<Layout>
						<Layout.Section>
							<H1>{this.props.t("ads.title", "Annons")}</H1>
							<LegacyCard
							// actions={[
							// 	{
							// 		content: (
							// 			<AdStatusSelector
							// 				label="Status"
							// 				onChange={(value) => {
							// 					this.state.form.status = value;
							// 					this.setState({ form: this.state.form });
							// 				}}
							// 				status={this.state.form.status}
							// 			/>
							// 		),
							// 	},
							// ]}
							>
								<LegacyCard.Section>
									<Header>
										{/* <p>Annonskonto: {`${this.state.form.ad_account.type || ""} - ${this.state.form.ad_account.title || ""}`}</p> */}
										<div style={{ display: "flex" }}>
											<span>{this.props.t("ad_accounts.title", "Annonskonto")}: </span>{" "}
											<AdAccountSelector
												required
												ad_account={this.state.form.ad_account}
												onChange={(value) => {
													this.state.form.ad_account = value;
													this.state.form.ad_campaign = null;
													this.state.form.type = "search";
													if (this.state.form.ad_account && this.state.form.ad_account.type == "facebook") {
														this.state.form.type = "retargeting";
													}
													this.setState({ form: this.state.form });
												}}
												error={this.state.invalidFormFields.ad_account}
											/>
										</div>
										<AdStatusSelector
											label={this.props.t("common.terms.status", "Status")}
											onChange={(value) => {
												this.state.form.status = value;
												this.setState({ form: this.state.form });
											}}
											status={this.state.form.status}
										/>
									</Header>
								</LegacyCard.Section>
								<FormWrapper>
									<LegacyCard.Section>
										<H2>Uppgifter</H2>
										<TextField
											label={this.props.t("ads.fields.title", "Annonsnamn")}
											value={this.state.form.title}
											onChange={this.updateForm.bind(this, "title")}
											error={this.state.invalidFormFields.title}
										/>
									</LegacyCard.Section>

									<FormWrapper style={{ maxWidth: "50%", minWidth: "300px" }}>
										<LegacyCard.Section>
											<H2>{this.props.t("ads.fields.campaign_type_selector.label", "Vilken typ av kampanj vill du göra?")}</H2>
											<AdCampaignSelector
												required
												ad_account={this.state.form.ad_account}
												campaign={this.state.form.ad_campaign}
												onChange={this.updateForm.bind(this, "ad_campaign")}
												extraCreateNewButton
												error={this.state.invalidFormFields.ad_campaign}
											/>
										</LegacyCard.Section>
										<LegacyCard.Section>
											<TextField
												label={this.props.t("ads.fields.url", "Välj URL")}
												value={this.state.form.url}
												onChange={this.updateForm.bind(this, "url")}
												error={this.state.invalidFormFields.url}
											/>
										</LegacyCard.Section>
										<LegacyCard.Section>
											<H2>Kostnad</H2>
											<TextField
												label={this.props.t("ads.fields.cpc", "Max kostnad per klick (CPC)")}
												type="currency"
												suffix="kr"
												value={this.state.form.max_cpc + ""}
												onChange={this.updateForm.bind(this, "max_cpc")}
												error={this.state.invalidFormFields.max_cpc}
											/>
											{this.state.form.ad_account && this.state.form.ad_account.type == "facebook" ? (
												<TextField
													label={this.props.t("ads.fields.daily_budget", "Daglig budget")}
													// helpText="Hur mycket får annonsen kosta per dag"
													type="currency"
													suffix="kr"
													value={this.state.form.daily_budget + ""}
													onChange={this.updateForm.bind(this, "daily_budget")}
													error={this.state.invalidFormFields.daily_budget}
												/>
											) : null}
										</LegacyCard.Section>
									</FormWrapper>
								</FormWrapper>
							</LegacyCard>
							{this.state.form.ad_campaign && (
								<LegacyCard title={this.props.t("ads.campaign.title", "Typ av annons")}>
									{this.state.form.ad_campaign ? (
										<LegacyCard.Section>
											<ChoiceList
												// title="Typ av annons"
												choices={typeOptions}
												selected={[this.state.form.type]}
												onChange={(val) => {
													this.updateForm("type", val[0]);
												}}
												error={this.state.invalidFormFields.type}
											/>
										</LegacyCard.Section>
									) : null}
									{this.state.form.type == "remarketing" ? (
										<LegacyCard.Section>
											<H2>{this.props.t("ads.fields.remarketing.title", "Remarketing")}</H2>
											<TextField
												label={this.props.t("ads.fields.remarketing.label", "Visa annons för besökare till URL:er som innehåller")}
												value={this.state.form.metafields.visit_url}
												onChange={this.updateMetafield.bind(this, "visit_url")}
												error={this.state.invalidMetaFields.visit_url}
											/>
										</LegacyCard.Section>
									) : null}
									{this.state.form.type == "custom_audience" ? (
										<React.Fragment>
											<LegacyCard.Section>
												<H2>{this.props.t("ads.fields.audience.title", "Målgrupper")}</H2>
												<SearchField
													placeholder={this.props.t("ads.fields.audience.placeholder", "Sök efter målgrupper att lägga till")}
													resourceName={{
														singular: this.props.t("ads.fields.audience.singular", "målgrupp"),
														plural: this.props.t("ads.fields.audience.plural", "målgrupper"),
													}}
													resource="audiences.json"
													label_handle="title"
													resource_handle="audiences"
													renderLabel={(o) => o.title + " (" + o.count + ")"}
													onSelect={(aud) => {
														this.state.form.audiences.push(aud);
														this.setState({ form: this.state.form });
													}}
												/>
											</LegacyCard.Section>
											{this.state.form.audiences.map((aud, key) => (
												<LegacyCard.Section key={key}>
													{aud.title} ({aud.count})
												</LegacyCard.Section>
											))}
										</React.Fragment>
									) : null}
								</LegacyCard>
							)}

							{this.state.form.ad_campaign ? (
								<LegacyCard>
									<LegacyCard.Section>
										<AdDesigner
											url={this.state.form.url}
											keyword={this.state.form.keyword}
											onUpdateKeyword={this.updateForm.bind(this, "keyword")}
											type={this.state.form.ad_campaign.type}
											ad_account={this.state.form.ad_account}
											metafields={this.state.form.metafields}
											onUpdateMetafields={this.updateMetafield.bind(this)}
											invalidMetaFields={this.state.invalidMetaFields}
											// onUpdateMetafields={(metafields) => {
											// 	this.state.form.metafields = metafields;
											// 	this.setState({ form: this.state.form });
											// }}
										/>
									</LegacyCard.Section>
								</LegacyCard>
							) : null}
						</Layout.Section>
						{this.state.form.ad_campaign && (
							<Layout.Section secondary>
								<H1>{this.props.t("common.term.preview", "Förhandsgranskning")}</H1>
								<AdPreview
									url={this.state.form.url}
									type={this.state.form.ad_campaign.type}
									ad_account={this.state.form.ad_account}
									metafields={this.state.form.metafields}
									youtubeVideoDetails={this.state.youtubeVideoDetails}
								/>
							</Layout.Section>
						)}
					</Layout>
				</Wrapper>
				<PageActions primaryAction={primaryAction} />
				<AdAccountSelectorModal
					history={this.props.history}
					open={this.state.adAccountSelectorModalOpen}
					onClose={this.handleCloseAdSelector.bind(this)}
					adAccount={this.state.form.ad_account}
					onChange={(value, close = true) => {
						this.state.form.ad_account = value;
						this.state.form.ad_campaign = null;
						this.state.form.type = "search";
						if (this.state.form.ad_account && this.state.form.ad_account.type == "facebook") {
							this.state.form.type = "retargeting";
						}
						this.setState({ form: this.state.form, adAccountSelectorModalOpen: !close });
					}}
				/>
			</Page>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	ad_accounts: state.ad_accounts,
});

export default connect(mapStateToProps)(withTranslation(["ads", "common"], { withRef: true })(AdCreate));

const H1 = styled.h1`
	font-size: 1.75rem;
	line-height: 2.125rem;
	font-weight: 700;
	color: var(--textColor);
	margin-bottom: 1.25rem;
`;

export const H2 = styled.h2`
	font-size: 1rem;
	line-height: 1.25rem;
	font-weight: 700;
	color: var(--textColor);
	/* margin-top: 1.2500rem;
	margin-bottom: 0.9375rem; */
`;

const Wrapper = styled.div`
	/* .Polaris-Label__Text {
		font-size: 1.0000rem;
		line-height: 1.2500rem;
		font-weight: 700;
		color: var(--textColor);
	} */

	.Polaris-Card__Section {
		gap: 1.25rem;
		display: flex;
		flex-direction: column;
	}
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1.25rem;
`;

const FormWrapper = styled.div`
	display: flex;
	flex-direction: column;
	/* gap: 1.2500rem; */
`;
