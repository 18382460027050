import React, { Component } from "react";
import { TopBar, ActionList, LegacyCard, Text } from "@shopify/polaris";
import axios from "axios";
import {
	ExchangeMajor,
	ProfileMajor,
	ListMajor,
	CustomersMajor,
	CircleAlertMajor,
	JobsMajor,
	StoreMajor,
	GrammarMajor,
	AddCodeMajor,
	CircleTickMajor,
	LogOutMinor,
	EmailMajor,
} from "@shopify/polaris-icons";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import API, { setPromise } from "../API";
import { store } from "../store";
import { toastr } from "./toastr";
import Notifications from "./Notifications.js";
import { getIconByString } from "../views/Workspaces/components/IconSelector.js";
// import ThemeToggle from "../views/Dashboard/ThemeToggle";
import { CalendarIcon, QuestionMark } from "../icons";
import Colors from "../Colors";
import NavigationHeaderButton from "./NavigationHeaderButton";
import { getRefreshToken } from "../Utilities/Utilities";
import { ProgressJobTopBarButton } from "src/js/components/ProgressJob";
import { getDomainWithTLD } from "../API/utils";
import MyAcitivtyButtonLink from "./MyAcitivtyButtonLink";

class MyTopBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			timeout: null,
			searchActive: false,
			cancelToken: null,
			searchResults: [],
			roaringResults: [],
			search: "",
			user: store.getState().user,
		};
	}

	createCancelToken(c) {
		this.setState({ cancelToken: c });
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.user.avatar && this.state.user.avatar !== props.user.avatar) {
			this.setState({ user: props.user });
		}
	}

	updateSearch(value) {
		if (!value) {
			this.setState({ search: value, searchActive: false, searchResults: [] });
		}
		clearTimeout(this.state.timeout);
		if (value) {
			this.setState({ search: value, timeout: setTimeout(this.doSearch.bind(this, value), 200) });
		}
	}

	doSearch(value) {
		store.dispatch({ type: "IS_LOADING", loading: true });
		this.cancelRequest();
		const CancelToken = axios.CancelToken;
		API.get("/api/search.json", {
			cancelToken: new CancelToken(this.createCancelToken.bind(this)),
			params: { q: value },
		})
			.then((result) => {
				store.dispatch({ type: "IS_LOADING", loading: false });
				this.setState({ searchActive: true, searchResults: result.data.results, roaringResults: result.data.roaringResults });
			})
			.catch((error) => {
				if (axios.isCancel(error)) {
					// eslint-disable-next-line no-console
					console.debug("Request canceled");
				} else {
					toastr.error(error);
				}
			});
	}

	cancelRequest() {
		if (this.state.cancelToken) {
			this.state.cancelToken();
			this.setState({ cancelToken: null });
		}
	}

	gotoLink(url) {
		this.props.history.push(url);
		this.setState({ searchActive: false, search: "" });
	}

	render() {
		const searchFieldMarkup = (
			<TopBar.SearchField
				onChange={this.updateSearch.bind(this)}
				value={this.state.search}
				placeholder={this.props.t("frame.search.placeholder", "Sök affärer, kontakter, bolag, ärenden")}
			/>
		);

		const accountOptions = store.getState().accounts.map((acc) => ({
			icon: ExchangeMajor,
			content: <StyledAccountOption selected={store.getState().account?.company === acc.company}>{acc.company}</StyledAccountOption>,
			onAction: () => {
				setPromise(null);

				const url = `https://${acc.handle}.${getDomainWithTLD(window.location.origin)}/admin/?refresh_token=${getRefreshToken()}`;

				window.location.href = url;
			},
		}));

		const userMenuActions = [
			{
				items: [
					{
						content: <Text variation="subdued">{store.getState().user.email}</Text>,
						icon: EmailMajor,
						// onAction: () => {
						// 	this.props.history.push("/admin/me");
						// },
					},
				],
			},
		];

		if (accountOptions.length > 1) {
			userMenuActions.push({
				items: accountOptions,
			});
		}

		userMenuActions.push({
			items: [
				{
					content: this.props.t("common.navigation.usermenu.items.my_pages", "Mina sidor"),
					icon: ProfileMajor,
					onAction: () => {
						this.props.history.push("/admin/me");
					},
				},
			],
		});

		userMenuActions.push({
			items: [
				{
					content: this.props.t("frame.user.log_out", "Logga ut"),
					icon: LogOutMinor,
					onAction: () => {
						window.location.href = "/admin/authentication/login";
					},
				},
			],
		});

		userMenuActions.push({
			items: [
				{
					content: <StyledAccountId>{this.props.t("frame.user.account", "Konto: {{id}}", { id: store.getState().account.id })}</StyledAccountId>,
					// icon: LogOutMinor,
					onAction: () => {},
				},
			],
		});

		const userMenuMarkup = (
			<TopBar.UserMenu
				actions={userMenuActions}
				name={store.getState().user.name}
				avatar={store.getState().user.avatar}
				// detail={store.getState().user.email}
				initials={store.getState().user.initials}
				open={this.state.userMenuOpen}
				onToggle={(state) => {
					this.setState({ userMenuOpen: state });
				}}
			/>
		);

		const searchResultsMarkup = (
			<LegacyCard>
				{this.state.searchResults.length ? (
					<ActionList
						items={this.state.searchResults.map((item, key) => {
							let icon = ListMajor;
							if (item.type == "person") {
								icon = CustomersMajor;
							} else if (item.type == "company") {
								icon = JobsMajor;
							} else if (item.type == "board") {
								icon = GrammarMajor;
								icon = getIconByString(item.icon);
							} else if (item.type == "row") {
								icon = CircleTickMajor;
								icon = getIconByString(item.icon);
							} else if (item.type == "group") {
								icon = AddCodeMajor;
								icon = getIconByString(item.icon);
							} else if (item.type == "bolag") {
								icon = StoreMajor;
							} else if (item.type == "deal") {
								icon = ListMajor;
							} else if (item.type == "project") {
								icon = GrammarMajor;
							} else if (item.type == "ticket") {
								icon = CircleAlertMajor;
							}

							return {
								icon,
								content: (
									<div>
										{item.title}
										<div>
											<Text variation="subdued">{item.parent_title}</Text>
										</div>
									</div>
								),
								onAction: this.gotoLink.bind(this, item.url),
							};
						})}
					/>
				) : (
					<div style={{ padding: 20 }}>{this.props.t("common.navigation.errors.no_results_found", "Inga resultat")}</div>
				)}

				{this.state.roaringResults && !!this.state.roaringResults.length && (
					<React.Fragment>
						<div className="bolags-search-devider">
							<div className="line" />
							<span>{this.props.t("common.navigation.terms.bolag_search", "Bolagssök")}</span>
							<div className="line" />
						</div>
						<ActionList
							items={this.state.roaringResults.map((item, key) => ({
								icon: JobsMajor,
								content: (
									<div>
										{item.title}
										<div>
											<Text variation="subdued">{item.parent_title}</Text>
										</div>
									</div>
								),
								onAction: this.gotoLink.bind(this, item.url),
							}))}
						/>
					</React.Fragment>
				)}
			</LegacyCard>
		);

		return (
			<TopBar
				showNavigationToggle
				userMenu={userMenuMarkup}
				searchResultsVisible={this.state.searchActive}
				searchField={searchFieldMarkup}
				searchResults={searchResultsMarkup}
				secondaryMenu={
					<React.Fragment>
						{/* {
							//! TEMP for testing
							([24, 145, 1514].includes(store.getState().account.id) || [2, 419, 3].includes(store.getState().user.id)) && <ThemeToggle />
						} */}

						<MyAcitivtyButtonLink />
						<ProgressJobTopBarButton />

						<span
							className="dashboard_mine"
							data-intro={this.props.t("common.intro.dashboard_mine.text", "Här ligger nu hjälpcentret")}
							data-title={this.props.t("common.intro.dashboard_mine.title", "Hjälp center")}
							data-step={1}
							id="adminHelpLink"
						>
							<NavigationHeaderButton
								to="/admin/help"
								tooltip={this.props.t("common.terms.support_desk", "Hjälpcenter")}
								icon={
									<QuestionMark
										height="20"
										width="20"
										color={this.props.location && this.props.location.pathname?.includes("/admin/help") ? Colors.accent : "var(--textColor)"}
									/>
								}
							/>
						</span>

						{store.getState().user.calendar_board && (
							<NavigationHeaderButton
								to={`/admin/workspaces/none/boards/${store.getState().user.calendar_board.id}`}
								tooltip={this.props.t("commob.terms.calendar", "Kalender")}
								className={
									this.props.location &&
									this.props.location.pathname?.includes(`/admin/workspaces/none/boards/${store.getState().user.calendar_board.id}`) &&
									"active"
								}
								icon={
									<CalendarIcon
										color={
											this.props.location &&
											this.props.location.pathname?.includes(`/admin/workspaces/none/boards/${store.getState().user.calendar_board.id}`)
												? Colors.accent
												: "var(--textColor)"
										}
									/>
								}
							/>
						)}

						<Notifications history={this.props.history} />
					</React.Fragment>
				}
				onSearchResultsDismiss={() => {
					this.setState({ searchActive: !this.state.searchActive, search: "" });
				}}
				onNavigationToggle={this.props.onNavigationToggle}
			/>
		);
	}
}

const StyledAccountOption = styled.span`
	color: ${(props) => (props.selected ? "#ffffff" : "#ffffffb2")};
	font-weight: ${(props) => (props.selected ? "bold" : "normal")};
`;

const StyledAccountId = styled.div`
	text-align: center;
	opacity: 0.5;
	font-size: 12px;
`;

const mapStateToProps = (state, ownProps) => ({
	user: state.user,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(
	withTranslation(["common"], {
		withRef: true,
	})(MyTopBar)
);
