/* eslint-disable quotes */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-classes-per-file */
import React, { Component } from "react";
import moment from "moment";
import { Icon, InlineError, TextField } from "@shopify/polaris";
import DatePicker, { registerLocale } from "react-datepicker";
import sv from "date-fns/locale/sv";
import { isValid } from "date-fns";
import MyPopover from "../Popover";
import { CalendarIcon } from "../../icons";

import "react-datepicker/dist/react-datepicker.css";
import DatepickerHelper from "../DatepickerHelper";
import TimeCheckbox from "../TimeCheckbox";

registerLocale("sv", sv);

/**
 * @param {Function} onChange - returns object {start:.., end:...} if range, otherwise returns date string.
 * @param {Boolean} [optionalTime=false] - show time checkbox
 * @param {Boolean} [range=false] - show range
 * @param {Boolean} [disabled=false] - disabled
 * @param {Object} value - value as an object {start: ... end: ....}
 */
class NewDatePicker extends Component {
	constructor(props) {
		super(props);
		// this.format = this.props.time ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD";
		this.state = {
			start: this.getValue(props.value, "start"),
			end: this.getValue(props.value, "end"),
			open: false,
		};
	}

	getValue(value, field) {
		if (!value) return moment().format("YYYY-MM-DD") + (this.props.optionalTime ? " 08:00" : "");

		if (value && typeof value === "object" && field in value) {
			return value[field] || null;
		}

		return (typeof value === "string" && value) || null;
	}

	UNSAFE_componentWillReceiveProps(props) {
		if ("open" in props) {
			this.setState({ open: props.open, start: this.getValue(props.value, "start"), end: this.getValue(props.value, "end") });
		}

		this.setState({ start: this.getValue(props.value, "start"), end: this.getValue(props.value, "end") });
	}

	setDate(date) {
		if (date && typeof date === "object" && "start" in date) {
			this.setState(
				{
					start: date.start,
					end: date.end,
				},
				() =>
					this.props.onTimeToggle
						? this.props.onTimeToggle({ start: date.start, end: date.end })
						: this.props.onChange(this.props.range ? { start: this.state.start, end: this.state.end } : this.state.start)
			);
		} else {
			this.setState(
				{
					start: date,
					end: date,
				},
				() =>
					this.props.onTimeToggle
						? this.props.onTimeToggle({ start: date, end: date })
						: this.props.onChange(this.props.range ? { start: this.state.start, end: this.state.end } : this.state.end)
			);
		}
	}

	onChange(field, value) {
		const date =
			moment(value).isValid() && isValid(new Date(value))
				? moment(value).format(DatepickerHelper.haveTime(this.state.start) ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD")
				: value && value.replace("T", " ");
		this.state[field] = date;

		if (!this.props.range) {
			this.state.end = this.state.start;
		}

		this.setState(
			(c) => Object.assign({}, this.state),
			() => {
				if (
					this.props.onChange &&
					moment(value).isValid() &&
					new Date(value).getTime() > 0 &&
					(typeof this.props.value === "string"
						? !moment(this.props.value).isSame(moment(this.state.start))
						: !moment(this.props.value?.start).isSame(this.state.start) || !moment(this.props.value?.end).isSame(this.state.end))
				) {
					this.props.onChange(this.props.range ? { start: this.state.start, end: this.state.end } : this.state.start);
				}
			}
		);
	}

	onOpen(e) {
		this.setState({ open: true });
	}

	onClose() {
		this.setState({ open: false });
		if (this.props.onClose) {
			if (moment(this.state.start).isValid() && new Date(this.state.start).getTime() > 0) {
				this.props.onClose(this.props.range ? { start: this.state.start, end: this.state.end } : this.state.start);
			}
		}
	}

	render() {
		const className = `new_datepicker${this.props.fullWidth ? " fullWidth" : ""}${this.props.expanded ? " expanded" : ""} ${
			this.props.className || ""
		} ${DatepickerHelper.haveTime(this.state.start) ? " time" : ""}${this.props.inheritActivator ? "inheritActivator" : ""}`;

		const fieldOptions = {
			start: this.state.start,
			end: this.state.end,
			onChangeStart: this.onChange.bind(this, "start"),
			onChangeEnd: this.onChange.bind(this, "end"),
			disabled: this.props.disabled,
			onClose: this.onClose.bind(this),
			// expanded: this.props.expanded,
		};

		const optionalTime = this.props.optionalTime;

		// if (!this.props.activator && !this.props.range) {
		// 	return (
		// 		<div className={`inheritActivator ${className}`}>
		// 			<Field
		// 				{...fieldOptions}
		// 				value={this.state.start}
		// 				onChange={this.onChange.bind(this, "start")}
		// 				onClose={this.onClose.bind(this)}
		// 				optionalTime={this.props.optionalTime}
		// 				setDate={this.setDate.bind(this)}
		// 				onlyCalendar={this.props.inheritActivator}
		// 				// expanded={!!this.props.activator || this.props.expanded}
		// 			/>
		// 		</div>
		// 	);
		// }
		const content = (() => {
			if (this.props.range) {
				return (
					<div>
						<Calendar
							range={this.props.range}
							start={this.state.start}
							end={this.state.end}
							onChangeStart={(date) => {
								this.onChange("start", `${moment(date).format("YYYY-MM-DD")} ${DatepickerHelper.getTime(this.state.start) || ""}`.trim());
							}}
							onChangeEnd={(date) => {
								this.onChange("end", `${moment(date).format("YYYY-MM-DD")} ${DatepickerHelper.getTime(this.state.end) || ""}`.trim());
							}}
							disabled={this.props.disabled}
						/>
						<div style={{ display: "flex", gap: "1.2500rem" }}>
							<Field {...fieldOptions} value={this.state.start} onChange={this.onChange.bind(this, "start")} />
							<Field {...fieldOptions} value={this.state.end} onChange={this.onChange.bind(this, "end")} />
						</div>
						{optionalTime && (
							<TimeCheckbox
								value={{ start: this.state.start, end: this.state.end }}
								onChange={({ start, end }) => {
									this.setState(
										(c) => Object.assign({}, c, { start, end }),
										() => {
											this.props.onChange(this.props.range ? { start: this.state.start, end: this.state.end } : this.state.start);
										}
									);
								}}
							/>
						)}

						{this.props.range && moment(this.state.start).isAfter(moment(this.state.end || null)) && (
							<InlineError message="Ogiltigt datumintervall" fieldID="invalidate" />
						)}
					</div>
				);
			}

			// return <Calendar range={this.props.range} start={this.state.start} end={this.state.end} onChange={this.onChange.bind(this)} />;
			return (
				<Field
					{...fieldOptions}
					expanded={this.props.expanded}
					onlyCalendar={this.props.inheritActivator}
					range={this.props.range}
					value={this.state.start}
					optionalTime={optionalTime}
					onChange={this.onChange.bind(this, "start")}
					setDate={this.setDate.bind(this)}
					minDate={this.props.minDate}
					minTime={this.props.minTime}
				/>
			);
		})();
		if (this.props.noPopup || this.props.justContent || !this.props.popup) {
			return (
				<div className={className} style={this.props.style || {}}>
					{content}
				</div>
			);
		}
		if (this.props.activator) {
			return (
				<MyPopover
					fixed
					fluidContent
					fullHeight
					active={this.state.open}
					onClose={this.onClose.bind(this)}
					activator={React.cloneElement(this.props.activator, {
						...(this.props.activator?.props || {}),
						onClick: this.onOpen.bind(this),
						style: {
							...(this.props.activator?.props?.style || {}),
							cursor: "pointer",
						},
					})}
				>
					<div className={className} style={this.props.style || {}}>
						{content}
					</div>
				</MyPopover>
			);
		}
		return (
			<div className={className} style={this.props.style || {}}>
				{content}
			</div>
		);
	}
}
export default NewDatePicker;
NewDatePicker.defaultProps = { optionalTime: true };

class Field extends Component {
	constructor(props) {
		super(props);
		this.state = { time: DatepickerHelper.getTime(props.value) || "00:00", date: DatepickerHelper.getDate(props.value) };
		this.timeRef = React.createRef();
		this.dateRef = React.createRef();
		this.onKeyDownDate = this.onHandleDown.bind(this, "date");
		this.onKeyDownTime = this.onHandleDown.bind(this, "time");
	}

	UNSAFE_componentWillReceiveProps(props) {
		const timeInput = this.timeRef.current && this.timeRef.current.querySelector("input");
		const dateInput = this.dateRef.current && this.dateRef.current.querySelector("input");

		if (document.activeElement !== timeInput && document.activeElement !== dateInput)
			this.setState({ time: DatepickerHelper.getTime(props.value), date: DatepickerHelper.getDate(props.value) });
	}

	componentDidMount() {
		this.setState({ time: DatepickerHelper.getTime(this.props.value), date: DatepickerHelper.getDate(this.props.value) });

		const timeInput = this.timeRef.current && this.timeRef.current.querySelector("input");
		const dateInput = this.dateRef.current && this.dateRef.current.querySelector("input");
		if (timeInput) {
			timeInput.addEventListener("keydown", this.onKeyDownTime);
		}
		if (dateInput) {
			dateInput.addEventListener("keydown", this.onKeyDownDate);
		}
	}

	componentWillUnmount() {
		const timeInput = this.timeRef.current && this.timeRef.current.querySelector("input");
		const dateInput = this.dateRef.current && this.dateRef.current.querySelector("input");

		if (timeInput) {
			timeInput.removeEventListener("keydown", this.onKeyDownTime);
		}
		if (dateInput) {
			dateInput.removeEventListener("keydown", this.onKeyDownDate);
		}
	}

	onHandleDown(type, e) {
		if (e.key === "Enter") {
			e.preventDefault();
			this.setTimeAndTime();
			if (e.target && e.target.blur) e.target.blur();

			if (type === "date") {
				this.onClose();

				const timeInput = this.timeRef.current && this.timeRef.current.querySelector("input");
				if (timeInput) {
					timeInput.focus();
					timeInput.select();
				}
			} else {
				this.onTimeClose();
			}
		}
	}

	onBlur() {
		this.setTimeAndTime();
	}

	onOpen(e) {
		this.setState({ open: true, timeOpen: false });
	}

	onToggle(e) {
		e?.stopPropagation();
		e?.preventDefault();
		this.setState((c) => ({ open: !c.open, timeOpen: false }));
	}

	onClose() {
		this.props.onClose?.();
		this.setState({ open: false });
	}

	onTimeOpen(e) {
		this.setState({ timeOpen: true, open: false });
	}

	onTimeClose() {
		this.setState({ timeOpen: false });
	}

	setTimeAndTime(
		{ close, ...props } = {
			time: this.state.time,
			date: this.state.date,
			close: false,
		}
	) {
		const time = props.time || this.state.time;
		const date = props.date || this.state.date;

		const t =
			time &&
			(time.length > 4
				? time.replace(":", "").replace(",", "").replace(".", "").slice(0, 4)
				: time.replace(":", "").replace(",", "").replace(".", "").padStart(4, "0"));

		const d = moment(date).isValid() ? moment(date).format("YYYY-MM-DD") : "0000-00-00";

		this.setState({ time: t && `${Math.min(23, parseInt(t.slice(0, 2)))}:${Math.min(59, parseInt(t.slice(2, 4)))}`, date: d }, () => {
			this.props.onChange(`${this.state.date} ${this.state.time || ""}`.trim());

			if (close) {
				this.onClose();
				this.onTimeClose();
			}
		});
	}

	onChangeTimeState(time) {
		this.setState({ time });
	}

	onChangeDateState(date) {
		this.setState({ date });
	}

	onFocus(type, e) {
		if (type === "time") {
			this.onTimeOpen();
		} else {
			this.onOpen();
		}

		this.selectText(e);
	}

	selectText(e) {
		if (e.target) {
			if (e.preventDefault) e.preventDefault();
			if (e.stopPropagation) e.stopPropagation();
			e.target.focus();
			e.target.select();
			setTimeout(() => e.target.select(), 0);
		}
	}

	render() {
		const textfieldOptions = {
			labe: "",
			type: "date",
			max: "9999-12-31",
			disabled: this.props.disabled,
			onBlur: this.onBlur.bind(this),
		};

		const timeContent = (
			<div className={`new_datepicker__popup_content ${DatepickerHelper.haveTime(this.props.value) ? " time" : ""}`}>
				<Calendar
					start={`${this.state.date} ${this.state.time || ""}`.trim()}
					onChange={(date) => {
						this.setTimeAndTime({ time: moment(date).format("HH:mm"), close: true });
					}}
					onChangeStart={(date) => {
						this.props.onChangeStart(date);
					}}
					onChangeEnd={(date) => {
						this.props.onChangeEnd(date);
					}}
					showTimeSelect
					showTimeSelectOnly
					disabled={this.props.disabled}
					timeIntervals={30}
					timeCaption={null}
					minTime={this.props.minTime}
				/>
			</div>
		);

		const timeTrigger = (
			<MyPopover
				fixed
				fluidContent
				fullHeight
				active={this.state.timeOpen}
				onClose={this.onTimeClose.bind(this)}
				activator={
					<span className="no_date_suffix time_input">
						<span ref={this.timeRef}>
							<TextField
								{...textfieldOptions}
								onFocus={this.onFocus.bind(this, "time")}
								onClick={this.selectText.bind(this)}
								max="23:59"
								type="text"
								value={this.state.time}
								onChange={this.onChangeTimeState.bind(this)}
							/>
						</span>
					</span>
				}
			>
				{timeContent}
			</MyPopover>
		);

		const content = (
			<div className={`new_datepicker__popup_content ${DatepickerHelper.haveTime(this.props.value) ? " time" : ""}`}>
				<Calendar
					minDate={this.props.minDate}
					start={this.state.date}
					onChange={(date) => {
						this.setTimeAndTime({ time: this.state.time, date: moment(date).format("YYYY-MM-DD"), close: true });
					}}
					onChangeStart={(date) => {
						this.props.onChangeStart(date);
					}}
					onChangeEnd={(date) => {
						this.props.onChangeEnd(date);
					}}
					disabled={this.props.disabled}
				/>
				<span ref={this.dateRef} className="no_date_suffix" style={{ display: "flex" }}>
					{this.props.expanded && !this.props.onlyCalendar && (
						<TextField
							{...textfieldOptions}
							onFocus={this.onFocus.bind(this, "date")}
							type="date"
							name="date"
							value={this.state.date}
							onChange={this.onChangeDateState.bind(this)}
						/>
					)}{" "}
					{DatepickerHelper.haveTime(this.props.value) && this.props.expanded && timeTrigger}
				</span>
			</div>
		);

		if (this.props.expanded)
			return (
				<div>
					{content}
					{this.props.optionalTime && (
						<TimeCheckbox
							value={this.props.value}
							onChange={(start) => {
								if (this.props.setDate) this.props.setDate(start);
							}}
						/>
					)}
				</div>
			);

		return (
			<div className="new_datepicker__field">
				<MyPopover
					fixed
					fluidContent
					fullHeight
					active={this.state.open}
					onClose={this.onClose.bind(this)}
					activator={
						<span ref={this.dateRef} className="no_date_suffix" style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
							<TextField
								{...textfieldOptions}
								onFocus={this.onFocus.bind(this, "date")}
								suffix={
									<span className="expand_button" onClick={this.onToggle.bind(this)}>
										<Icon source={CalendarIcon} color="var(--textColor)" />
									</span>
								}
								type="date"
								value={this.state.date}
								onChange={this.onChangeDateState.bind(this)}
							/>
							{DatepickerHelper.haveTime(this.props.value) && timeTrigger}
							{this.props.optionalTime && (
								<TimeCheckbox
									value={this.props.value}
									onChange={(start) => {
										if (this.props.setDate) this.props.setDate(start);
									}}
								/>
							)}
						</span>
					}
				>
					<div className="new_datepicker">{content}</div>
				</MyPopover>
			</div>
		);
	}
}

class Calendar extends Component {
	constructor(props) {
		super(props);
		this.state = { start: props.start, end: props.end };
		this.ref = React.createRef();
		this.openMonthDropdown = this.handleMonthDropdown.bind(this);
	}

	handleMonthDropdown() {
		this.ref.current?.querySelector(".react-datepicker__month-read-view")?.click?.();
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({ start: props.start, end: props.end });

		// if (props.start !== this.state.start && this.props.showTimeSelectOnly) {
		// 	const selectedTime = this.ref.current && this.ref.current.querySelector(".react-datepicker__time-list-item--selected");
		// 	if (selectedTime) selectedTime.scrollIntoView();
		// }
	}

	componentDidMount() {
		setTimeout(() => {
			this.ref.current
				?.querySelector(".react-datepicker__current-month.react-datepicker__current-month--hasMonthDropdown")
				?.addEventListener("click", this.openMonthDropdown);
		}, 0);
	}

	componentWillUnmount() {
		this.ref.current
			?.querySelector(".react-datepicker__current-month.react-datepicker__current-month--hasMonthDropdown")
			?.removeEventListener("click", this.openMonthDropdown);
	}

	onRangeChange(value) {
		const [start, end] = value;

		this.setState({ start, end });

		if (start && end) {
			this.props.onChangeStart(start);
			this.props.onChangeEnd(end);
		}
	}

	renderDayContents(day, date) {
		return (
			<div className="day-inner">
				<span>{day}</span>
			</div>
		);
	}

	render() {
		const { start, end } = this.state;

		const options = {
			inline: true,
			showMonthDropdown: true,
			calendarStartDay: 1,
			// highlightDates={highlightedEvents}
			// onMonthChange={this.handleDateChange.bind(this)}
			renderDayContents: this.renderDayContents.bind(this),
			locale: "sv",
			// filterDate={(date) => moment(moment(date).format("YYYY-MM-DD")).isSame(moment(moment(this.state.selectedDate).format("YYYY-MM-DD")), "month")}
			dayClassName: () => "calendar-day",
			dateFormat: "yyyy-MM-DD",
			showWeekNumbers: false,
			disabled: this.props.disabled,
			disabledKeyboardNavigation: true,
			showTimeSelect: this.props.showTimeSelect,
			showTimeSelectOnly: this.props.showTimeSelectOnly,
			timeIntervals: this.props.timeIntervals,
			timeCaption: null,
			injectTimes: this.props.showTimeSelectOnly && isValid(new Date(this.props.start)) ? [new Date(this.props.start)] : [],
		};

		const calendar = this.props.range ? (
			<DatePicker
				{...options}
				startDate={start && isValid(new Date(start)) ? new Date(start) : null}
				endDate={end && isValid(new Date(end)) ? new Date(end) : null}
				onChange={this.onRangeChange.bind(this)}
				// minDate={this.props.start && !this.props.end && new Date(moment(start).add(1, "days"))}
				selectsRange
			/>
		) : (
			<div className="new_datepicker__calendar">
				<DatePicker
					{...options}
					minDate={new Date(this.props.minDate)}
					minTime={new Date(this.props.minTime)}
					maxTime={this.props.minTime ? new Date(this.props.maxTime) : new Date().setUTCHours(23, 59, 59, 999)}
					selected={this.props.start && isValid(new Date(this.props.start)) ? new Date(this.props.start) : null}
					// onSelect={this.onSelect.bind(this, "start")}
					onChange={this.props.onChange}
					filterTime={(time) => {
						if (!this.props.minTime) return true;
						const minDate = new Date(this.props.minTime);
						const selectedDate = new Date(time);
						return selectedDate.getTime() > minDate.getTime();
					}}
					// todayButton={<div>today</div>}
				/>
			</div>
		);
		return (
			<span
				ref={this.ref}
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
				}}
			>
				{calendar}
			</span>
		);
	}
}
