import React, { Component } from "react";
import { PageActions, Layout, LegacyCard, FormLayout, TextField, ChoiceList } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import Page from "src/js/components/page";
import API from "../../API";
import { store } from "../../store";
import { toastr } from "../../components/toastr.js";
import ResourcePicker from "../../components/resource_picker.js";

class UserCreate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			saving: false,
			sections: [],
			form: {
				name: "",
				email: "",
				subscriptions: [],
				fields: [],
				phone: "",
				group: null,
				roles: ["ROLE_USER", "ROLE_CREATE_TAG"],
			},
		};
	}

	componentDidMount() {
		this.fetchSections();
	}

	fetchSections() {
		API.get("/api/sections.json")
			.then((result) => {
				this.setState({ sections: result.data.workspaces });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	updateForm(field, value) {
		if (field == "roles") {
			this.updateRoles(value);
			return;
		}
		const formState = this.state.form;
		formState[field] = value;

		this.setState({ form: formState });
	}

	saveForm() {
		this.setState({ saving: true });
		API.post("/api/users.json", this.state.form)
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				toastr.success(this.props.t("user.responses.created", "Skapade användare"));
				this.props.history.replace("/admin/users/" + result.data.user.id);
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	arrDiff(a1, a2) {
		const a = [];
		const diff = [];

		for (let i = 0; i < a1.length; i++) {
			a[a1[i]] = true;
		}

		for (let i = 0; i < a2.length; i++) {
			if (a[a2[i]]) {
				delete a[a2[i]];
			} else {
				a[a2[i]] = true;
			}
		}

		// eslint-disable-next-line no-restricted-syntax
		for (const k in a) {
			diff.push(k);
		}

		return diff;
	}

	updateRoles(newRoles) {
		const diff = this.arrDiff(newRoles, this.state.form.roles);
		const role = diff[0];

		if (this.state.form.roles.indexOf(role) >= 0) {
			// Remove all roles with same prefix
			const n = [];
			for (let i = 0; i < newRoles.length; i++) {
				if (newRoles[i].substr(0, role.length) != role) {
					n.push(newRoles[i]);
				}
			}
			newRoles = n;
		}

		this.state.form.roles = newRoles;
		this.setState({ form: this.state.form });
	}

	getChildren(options, isSelected) {
		return (
			isSelected && <ChoiceList onChange={this.updateForm.bind(this, "roles")} choices={options} selected={this.state.form.roles} allowMultiple />
		);
	}

	render() {
		const roleOptions = [
			{
				value: "ROLE_MARKETING",
				label: this.props.t("user.roles.marketing", "Marknadsföring"),
				renderChildren: (isSelected) =>
					isSelected && (
						<ChoiceList
							onChange={this.updateForm.bind(this, "roles")}
							choices={[
								{ value: "ROLE_MARKETING_EMAIL_CAMPAIGNS", label: this.props.t("user.roles.marketing_email", "E-postkampanjer") },
								{ value: "ROLE_MARKETING_ADS", label: this.props.t("user.roles.marketing_ads", "Online annonsering") },
							]}
							selected={this.state.form.roles}
							allowMultiple
						/>
					),
			},
		];

		for (let i = 0; i < this.state.sections.length; i++) {
			const section = this.state.sections[i];
			if (section.type == "shareable") {
				const boards = section.boards.filter((v) => v.type == "shareable");
				const obj = { value: "ROLE_WORKSPACE_" + section.id, label: section.title };
				if (boards.length > 0) {
					let options = [];
					if (section.title == "Servicedesk") {
						options = [{ value: "ROLE_SUPPORT_RECEIVE", label: this.props.t("user.roles.support_receive", "Ta emot mail för nya ärenden") }];
					} else {
						options = [];
					}
					for (let s = 0; s < boards.length; s++) {
						options.push({ value: "ROLE_BOARD_" + boards[s].id, label: boards[s].title });
					}
					obj.renderChildren = this.getChildren.bind(this, options);
				}
				roleOptions.push(obj);
			}
		}

		roleOptions.push({ value: "ROLE_ADMIN", label: this.props.t("user.roles.admin", "Administratör") });

		for (let i = 0; i < store.getState().account.integrations.length; i++) {
			const integration = store.getState().account.integrations[i];
			roleOptions.push({
				value: "ROLE_INTEGRATION_" + integration.id,
				label: `${this.props.t("user.role.integration.label", "Integration")}: ${integration.title}`,
			});
		}

		return (
			<Page
				title={this.props.t("user.new.title", "Ny användare")}
				backAction={{ content: this.props.t("common.actions.back", "Tillbaka"), onAction: () => this.props.history.goBack() }}
				primaryAction={{
					content: this.props.t("common.actions.create", "Skapa"),
					disabled: !this.state.form.email,
					loading: this.state.saving,
					onAction: this.saveForm.bind(this),
				}}
			>
				<Layout>
					<Layout.AnnotatedSection
						title={this.props.t("user.sections.details.title", "Användaruppgifter")}
						description={this.props.t("user.sections.details.description", "Fyll i användaruppgifter")}
					>
						<LegacyCard title={this.props.t("user.sections.details.card.title", "Uppgifter")}>
							<LegacyCard.Section>
								<FormLayout>
									<TextField
										label={this.props.t("user.fields.name.label", "För- och efternamn")}
										value={this.state.form.name}
										onChange={this.updateForm.bind(this, "name")}
									/>
									<TextField
										label={this.props.t("user.fields.email.label", "E-mail")}
										value={this.state.form.email}
										onChange={this.updateForm.bind(this, "email")}
									/>
									<TextField
										label={this.props.t("user.fields.phone.label", "Mobilnummer")}
										value={this.state.form.phone}
										onChange={this.updateForm.bind(this, "phone")}
									/>
								</FormLayout>
							</LegacyCard.Section>
						</LegacyCard>
					</Layout.AnnotatedSection>

					<Layout.AnnotatedSection
						title={this.props.t("user.sections.roles.title", "Användarrättigheter")}
						description={this.props.t("user.sections.roles.description", "Välj rättigheter åt användaren")}
					>
						<LegacyCard>
							<LegacyCard.Section>
								<ChoiceList onChange={this.updateForm.bind(this, "roles")} choices={roleOptions} selected={this.state.form.roles} allowMultiple />
							</LegacyCard.Section>
						</LegacyCard>
					</Layout.AnnotatedSection>

					{store.getState().groups?.length ? (
						<Layout.AnnotatedSection
							title={this.props.t("user.sections.office.title", "Kontor")}
							description={this.props.t("user.sections.office.description", "Välj grupp som användaren tillhör")}
						>
							<LegacyCard>
								<LegacyCard.Section title={this.props.t("user.sections.office.card.title", "Grupp")}>
									<ResourcePicker
										caption={this.props.t("user.fields.groups.label", "Ingen grupp")}
										resource="groups"
										resourceName={{ singular: this.props.t("groups.singular", "grupp"), plural: this.props.t("groups.plural", "grupper") }}
										label_handle="name"
										item={this.state.form.group}
										clearOption={this.props.t("user.fields.groups.clear_option", "Ingen grupp")}
										onChange={(group) => {
											this.state.form.group = group;
											this.setState({ form: this.state.form });
										}}
									/>
								</LegacyCard.Section>
							</LegacyCard>
						</Layout.AnnotatedSection>
					) : null}
				</Layout>
				<PageActions
					primaryAction={{
						content: this.props.t("common.actions.create", "Skapa"),
						disabled: !this.state.form.email,
						loading: this.state.saving,
						onAction: this.saveForm.bind(this),
					}}
				/>
			</Page>
		);
	}
}

export default withTranslation(["user", "common"], { withRef: true })(UserCreate);
