import React, { Component } from "react";
import {  TextField, FormLayout } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import API from "../../../API";
import { toastr } from "../../../components/toastr.js";
import { store } from "../../../store/index.js";
import Modal from "src/js/components/modal";

class RenameWorkspaceModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			open: props.open,
			title: this.props.workspace.title,
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			this.setState({
				open: props.open,
				title: this.props.workspace.title,
			});
		}
	}

	saveForm() {
		this.setState({ saving: true });

		API.put("/api/workspaces/" + this.props.workspace.id + ".json", { title: this.state.title })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					store.dispatch({ type: "UPDATE_WORKSPACE", workspace: { ...this.props.workspace } });
					this.setState({ saving: false });
					return;
				}
				store.dispatch({ type: "UPDATE_WORKSPACE", workspace: result.data.workspace });
				toastr.success(this.props.t("workspace.folders.responses.renamed", "Döpte om mapp"));
				this.props.onComplete();
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	render() {
		return (
			<Modal
				open={this.props.open}
				onClose={this.props.onClose}
				title={this.props.t("workspace.folders.action.rename", "Döp om mapp")}
				primaryAction={{ content: this.props.t("common.actions.save", "Spara"), loading: this.state.saving, onAction: this.saveForm.bind(this) }}
				secondaryActions={[
					{
						content: this.props.t("common.actions.close", "Stäng"),
						onAction: this.props.onClose,
					},
				]}
			>
				<Modal.Section>
					<FormLayout>
						<TextField
							label={this.props.t("workspace.folders.fields.new_name.label", "Nytt namn")}
							value={this.state.title}
							onChange={(v) => {
								this.setState({ title: v });
							}}
						/>
					</FormLayout>
				</Modal.Section>
			</Modal>
		);
	}
}
export default withTranslation(["workspace", "common"], { withRef: true })(RenameWorkspaceModal);
