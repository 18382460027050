/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
import { Button, Tooltip } from "@shopify/polaris";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import Modal from "../modal";
import Popover from "../Popover";
import { withRouter } from "react-router-dom";

const ActionButton = withTranslation(["common"])(
	withRouter(({ item = {}, t, history, ...props }: any) => {
		const [isLoading, setIsLoading] = useState(false);
		const [open, setOpen] = useState(false);
		const confirm = {
			showButtons: true,
			sectioned: true,
			...(item.confirmation || item.confirm || {}),
			modal: item.modal,
		};

		const showConfirm = item.confirmation || item.confirm || item.modal;

		const handleAction = async () => {
			setIsLoading(true);
			try {
				if (item.url) {
					if (item.url?.startsWith("http") || item.url?.startsWith("www")) {
						window.open(item.url, "_blank");
					} else {
						history.push(item.url);
					}
				} else {
					await item.onAction?.();
				}
			} catch (error) {
				console.error("error:", error);
			}
			setIsLoading(false);
			setOpen(false);
		};

		const handlePreAction = () => {
			if (showConfirm) {
				setOpen(true);
			} else {
				handleAction();
			}
		};

		const button = (
			<Button
				removeUnderline
				plain={"plain" in item ? item.plain : "plain" in props ? props.plain : true}
				key={item.content}
				icon={item.icon}
				onClick={handlePreAction}
				loading={item.loading || isLoading}
				disabled={item.disabled}
				destructive={item.destructive}
			>
				{item.content}
			</Button>
		);

		const activator = item.tooltip ? <Tooltip content={item.tooltip}>{button}</Tooltip> : button;

		if (!showConfirm) return activator;
		const text = confirm?.content === undefined ? `${item.content}?` : confirm?.content || confirm?.text || confirm?.message;

		const onClose = () => {
			setOpen(false);
			confirm?.onClose?.();
		};

		return (
			<>
				{confirm.modal ? (
					<>
						{activator}
						{React.cloneElement(confirm.modal, {
							open,
							onClose,
							secondaryActions: [
								{
									content: t("common.actions.cancel", "Avbryt"),
									onAction: onClose,
								},
							],
							t,
						})}
					</>
				) : confirm.type === "popup" ? (
					<Popover active={open} onClose={onClose} activator={activator} fixed sectioned={confirm.sectioned}>
						{text}
						{confirm.showButtons && (
							<div style={{ marginTop: "0.6250rem", display: "flex", justifyContent: "space-between" }}>
								<Button onClick={onClose}>{confirm?.cancel || t("common.actions.cancel", "Avbryt")}</Button>

								<Button
									destructive={item.destructive}
									primary={item.primary || !item.destructive}
									onClick={handleAction}
									loading={item.loading || isLoading}
									disabled={confirm.disabled}
								>
									{confirm?.confirm || item.content}
								</Button>
							</div>
						)}
					</Popover>
				) : (
					<>
						{activator}
						<Modal
							open={open}
							onClose={onClose}
							title={confirm?.title || item.content}
							primaryAction={
								!confirm.showButtons
									? undefined
									: {
											...item,
											content: confirm?.confirm || item.content,
											disabled: confirm.disabled,
											onAction: handleAction,
											loading: item.loading || isLoading,
									  }
							}
							secondaryActions={[
								{
									content: confirm?.cancel || t("common.actions.cancel", "Avbryt"),
									onAction: onClose,
								},
							]}
							sectioned={confirm.sectioned}
						>
							{text}
						</Modal>
					</>
				)}
			</>
		);
	})
);

export default ActionButton;
