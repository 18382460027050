import React, { Component } from "react";
import { Filters, Button, Select } from "@shopify/polaris";
import { FilterMajor } from "@shopify/polaris-icons";
import moment from "moment";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import BringModalToFront from "src/js/components/BringModalToFront";
import BoardFiltersText from "./BoardFiltersText";
import BoardFiltersNumber from "./BoardFiltersNumber";
import BoardFiltersDate from "./BoardFiltersDate";
import BoardFiltersOptions from "./BoardFiltersOptions";
import MemberAvatar from "./MemberAvatar";
import BoardHelper from "../BoardHelper";
import { store } from "../../../store";
import BoardFiltersParentTags from "./BoardFiltersParentTags";
import styled from "styled-components";

class BoardFilters extends Component {
	constructor(props) {
		super(props);
		this.state = { selectedBoardIndex: 0 };
		this.state.filters = this.decorateFilters(props.filters);
		this.open = null;
		this.triggerRefreshId = String(Math.random()).replace(".", "");
		this.visibilityId = `visibility-check-${this.triggerRefreshId}-`;
		this.visibilityElement = <div className={this.visibilityId} style={{ display: "none" }} />;
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({ filters: this.decorateFilters(props.filters) });
	}

	checkIfClosedAndApplyFilters() {
		const visible = document.querySelector(`.${this.visibilityId}`);
		if (!visible && this.open) {
			this.onApplyFilters();
		}
		this.open = Boolean(visible);
	}

	componentDidMount() {
		this.interval = setInterval(this.checkIfClosedAndApplyFilters.bind(this), 150);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	getCurrentBoard() {
		let board = null;
		if (this.props.boards.length) {
			if (this.state.selectedBoardIndex <= this.props.boards.length - 1) {
				board = this.props.boards[this.state.selectedBoardIndex];
			} else {
				board = this.props.boards[0];
			}
		}
		return board;
	}

	clearFilters() {
		this.props.onChangeFilters([]);
	}

	removeFilter(index) {
		this.props.filters.splice(index, 1);
		this.props.onChangeFilters(this.props.filters);
	}

	onChangeFilters(filtersToBeApplied) {
		this.setState({ filtersToBeApplied });
	}

	onApplyFilters(filtersP = this.state.filtersToBeApplied) {
		const filters = this.decorateFilters(filtersP);
		this.setState({ filters });
		if (this.timeout) {
			clearTimeout(this.timeout);
			this.timeout = null;
		}

		this.timeout = setTimeout(
			() => {
				this.props.onChangeFilters(this.cleanFilters(this.state.filters));
			},
			navigator.platform.toUpperCase().indexOf("MAC") >= 0 ? 300 : 1000
		);
	}

	cleanFilters(filters) {
		return filters.map((filter) => {
			const ob = Object.assign({}, filter);
			delete ob.onRemove;
			delete ob.label;
			return ob;
		});
	}

	decorateFilters(filters) {
		if (!filters || !filters.length) return [];
		return filters.map((filter, index) => {
			const ob = Object.assign({}, filter);
			if (ob.board_id) {
				ob.key = ob.column_id + "-" + ob.board_id;
			} else {
				ob.key = ob.column_id || ob.key;
			}
			ob.onRemove = this.removeFilter.bind(this, index);
			ob.label = this.disambiguateLabel(ob);
			return ob;
		});
	}

	disambiguateLabel(filter) {
		// const board = this.getCurrentBoard();
		let val = null;
		let label = "";
		if (!Array.isArray(filter.value)) {
			val = [filter.value];
		} else {
			val = filter.value;
		}

		const values = [];

		for (let x = 0; x < val.length; x++) {
			let value = val[x];
			if (filter.column_id == "person") {
				label = this.props.t("board.filters.person.label", "Person");

				const user = store.getState().users.find((user) => value + "" == user.id + "");
				if (!user) {
					const board = BoardHelper.getBoard(filter.board_id);
					if (board) {
						for (let i = 0; i < board.members?.length; i++) {
							if (board.members[i].user.id + "" == value + "") {
								value = board.members[i].user.name;
								break;
							}
						}
					}
				} else {
					value = user.name;
				}
			}
			if (filter.column_id == "parent_tagged") {
				label = this.props.t("board.filters.parent_tagged.label", "Företag taggad med");
			} else if (filter.column_id == "group") {
				label = this.props.t("board.filters.group.label", "Grupp");
				const group = this.props.groups.find((grp) => value + "" == grp.id + "");

				if (group) {
					value = group.title;
				}
			} else if (filter.column_id == "title") {
				label = this.props.t("board.filters.row_name.label", "Radens namn");
			} else {
				label = filter.column_id;
				const board = BoardHelper.getBoard(filter.board_id);
				let column = null;
				if (board) {
					for (let i = 0; i < board.columns.length; i++) {
						if (board.columns[i].id == filter.column_id) {
							column = BoardHelper.getColumn(board.columns[i]);
							if (value === null) {
								if (column.type == "contact") {
									value = this.props.t("board.filters.terms.none_exists", "Finns inte");
								} else if (column.type == "checkbox") {
									value = this.props.t("board.filters.terms.no", "Nej");
									// value = `<${this.props.t("board.filters.terms.none_exists", "inget värde")}>`;
								} else {
									value = `<${this.props.t("board.filters.terms.none_exists", "inget värde")}>`;
								}
							} else {
								if (column.type == "status") {
									const status = column.options.statuses.find((s) => s.id == value);
									value = status && status.label;
								} else if (column.type == "dropdown") {
									value = column.options.choices[value];
								} else if (column.type == "contact") {
									if (value) {
										value = this.props.t("board.filters.terms.exists", "Finns");
									} else {
										value = this.props.t("board.filters.terms.none_exists", "Finns inte");
									}
								} else if (column.type == "checkbox") {
									if (value) {
										value = this.props.t("board.filters.terms.yes", "Ja");
									}
									//  else if (value === null) {
									// 	value = `<${this.props.t("board.filters.terms.none_exists", "inget värde")}>`;
									// }
									else {
										value = this.props.t("board.filters.terms.no", "Nej");
									}
								} else if (column.type == "tags") {
									const tag = board.tags.find((t) => t.id == value);
									value = tag && tag.title;
								} else if (column.type == "category") {
									const category = board.categories.find((cat) => cat.id == value);
									value = category && category.title;
								}
							}
							label = board.columns[i].title;
							break;
						}
					}
				}
			}

			if (filter.operator == "between") {
				value = filter.value + (filter.value2 ? " - " + filter.value2 : "");
			} else if (filter.operator == "period") {
				const toDate = moment().format("YYYY-MM-DD");
				let fromDate = null;
				if (filter.value == "last_7_days") {
					fromDate = moment().add(-7, "days").format("YYYY-MM-DD");
				} else if (filter.value == "last_30_days") {
					fromDate = moment().add(-30, "days").format("YYYY-MM-DD");
				}
				value = fromDate + " - " + toDate;
			}
			values.push(value);
		}

		let optext = ": ";
		if (filter.operator) {
			if (filter.operator == "period") {
				optext = ` ${this.props.t("board.filters.terms.between", "mellan")} `;
			} else if (filter.operator == "equals") {
				optext = ` ${this.props.t("board.filters.terms.is", "är")} `;
			} else if (filter.operator == "contains") {
				optext = ` ${this.props.t("board.filters.terms.includes", "innehåller")} `;
			} else if (filter.operator == "between") {
				if (filter.value && !filter.value2) {
					optext = ` ${this.props.t("board.filters.terms.from", "från")} `;
				} else if (!filter.value && filter.value2) {
					optext = ` ${this.props.t("board.filters.terms.to", "till")} `;
				} else {
					optext = ` ${this.props.t("board.filters.terms.between", "mellan")} `;
				}
			}
		}

		return label + optext + values.join(", ");
	}

	updateSearch(v) {
		const foundIndex = this.state.filters.findIndex((filter) => filter.column_id == "search");
		if (foundIndex >= 0) {
			if (v) {
				this.state.filters[foundIndex].value = v;
			} else {
				this.state.filters.splice(foundIndex, 1);
			}
		} else if (v) {
			this.state.filters.push({
				column_id: "search",
				value: v,
			});
		}
		this.onApplyFilters(this.state.filters);
	}

	OnChangeFiltersAndApply(filters) {
		this.setState({ filtersToBeApplied: filters });
		this.onApplyFilters(filters);
	}

	render() {
		const board = this.getCurrentBoard();

		const filters = [];

		if (this.props.boards.length > 1) {
			filters.push({
				key: "board",
				label: this.props.t("board.terms.board", "Board"),
				filter: (
					<Select
						options={this.props.boards.map((board, index) => ({ value: index + "", label: board.title }))}
						value={this.state.selectedBoardIndex + ""}
						onChange={(v) => {
							this.state.selectedBoardIndex = parseInt(v);
							this.setState({ selectedBoardIndex: this.state.selectedBoardIndex });
						}}
					/>
				),
				shortcut: false,
			});
		}

		if (board) {
			const personColumn = board.columns.find((c) => c.type === "person");
			if (personColumn) {
				// users mentioned in the board
				const boardPeople = this.props.boardPeopleIds
					? new Set(this.props.boardPeopleIds)
					: (this.props.rows && new Set(this.props.rows.flatMap((r) => personColumn && r.column_values?.[personColumn.id]?.value))) || new Set();

				const options = store.getState().users.map((user) => ({
					label: user.name,
					prefix: (
						<div className="smallerAvatars" style={{ marginTop: -5 }}>
							<MemberAvatar user={user} />
						</div>
					),
					inactive: !user.enabled && !boardPeople.has(user.id),
					value: user.id * 1,
				}));
				for (let x = 0; x < board.members?.length; x++) {
					if (!store.getState().users.find((user) => board.members[x].user.id == user.id)) {
						options.push({
							label: board.members[x].user.name,
							prefix: (
								<div className="smallerAvatars" style={{ marginTop: -5 }}>
									<MemberAvatar size="small" user={board.members[x]} />
								</div>
							),
							inactive: !board.members[x].user.enabled,
							value: board.members[x].user.id * 1,
						});
					}
				}
				filters.push({
					hideClearButton: true,
					key: "person-" + board.id,
					label: this.props.t("board.filters.person.label", "Person"),
					filter: (
						<BoardFiltersOptions
							board={board}
							column_id="person"
							filters={this.state.filters}
							options={options.filter(({ value } = {}) => value && boardPeople.has(value))}
							onChangeFilters={this.OnChangeFiltersAndApply.bind(this)}
						/>
					),
					shortcut: true,
				});
			}
			const contactColumns = board.columns.filter((c) => c.type === "contact");
			if (contactColumns?.length) {
				filters.push({
					hideClearButton: true,
					key: "parent_tagged-" + board.id,
					label: this.props.t("board.filters.companies_tagged.label", "Företag taggad med"),
					filter: (
						<BoardFiltersParentTags
							column_id="parent_tagged"
							board={board}
							filters={this.state.filters}
							onChangeFilters={this.OnChangeFiltersAndApply.bind(this)}
						/>
					),
					shortcut: true,
				});
			}
			filters.push({
				hideClearButton: true,
				key: "group-" + board.id,
				label: this.props.t("board.filters.group.label", "Grupp"),
				filter: (
					<BoardFiltersOptions
						board={board}
						column_id="group"
						filters={this.state.filters}
						options={
							this.props.groups &&
							this.props.groups.map((group) => ({
								label: group.title,
								prefix: (
									<div
										style={{
											borderRadius: 10,
											height: 20,
											width: 20,
											backgroundColor: group.color,
										}}
									/>
								),
								value: group.id,
							}))
						}
						onChangeFilters={this.onChangeFilters.bind(this)}
					/>
				),
				shortcut: false,
			});

			filters.push({
				hideClearButton: true,
				key: "title-" + board.id,
				label: this.props.t("board.filters.row_name.label", "Radens namn"),
				filter: <BoardFiltersText board={board} column_id="title" filters={this.state.filters} onChangeFilters={this.onChangeFilters.bind(this)} />,
				shortcut: false,
			});

			for (let i = 0; i < board.columns.length; i++) {
				const column = BoardHelper.getColumn(board.columns[i]);
				let filter = null;

				if (column.type == "number" || column.type == "board_number") {
					filter = (
						<BoardFiltersNumber
							board={board}
							column_id={board.columns[i].id}
							filters={this.state.filters}
							onChangeFilters={this.onChangeFilters.bind(this)}
						/>
					);
				}
				if (column.type == "status" && column.options && column.options.statuses) {
					filter = (
						<BoardFiltersOptions
							options={column.options.statuses
								.map((opt) => ({
									value: opt.id,
									prefix: (
										<div
											style={{
												borderRadius: 10,
												height: 20,
												width: 20,
												backgroundColor: opt.color,
											}}
										/>
									),
									label: opt.label,
								}))
								.concat([{ value: null, label: `<${this.props.t("board.filters.terms.none_exists", "inget värde")}>` }])}
							board={board}
							column_id={board.columns[i].id}
							filters={this.state.filters}
							onChangeFilters={this.onChangeFilters.bind(this)}
						/>
					);
				}
				if (column.type == "checkbox") {
					filter = (
						<BoardFiltersOptions
							options={[
								{
									value: 1,
									label: this.props.t("common.terms.yes", "Ja"),
								},
								{
									value: 0,
									label: this.props.t("common.terms.no", "Nej"),
								},
								// {
								// 	value: null,
								// 	label: this.props.t("common.terms.no_value", "Inget värde"),
								// },
							]}
							board={board}
							column_id={board.columns[i].id}
							filters={this.state.filters}
							onChangeFilters={this.onChangeFilters.bind(this)}
						/>
					);
				}
				if (column.type == "contact") {
					filter = (
						<BoardFiltersOptions
							options={[
								{
									value: 1,
									label: this.props.t("calendar.filters.terms.exists", "Finns"),
								},
								{
									value: null,
									label: this.props.t("calendar.filters.terms.none_exists", "Finns inte"),
								},
							]}
							board={board}
							column_id={board.columns[i].id}
							filters={this.state.filters}
							onChangeFilters={this.onChangeFilters.bind(this)}
						/>
					);
				}
				if (column.type == "tags" && board.tags) {
					filter = (
						<BoardFiltersOptions
							options={board.tags.map((tag) => ({
								value: tag.id,
								prefix: (
									<div
										style={{
											borderRadius: 10,
											height: 20,
											width: 20,
											backgroundColor: tag.color,
										}}
									/>
								),
								label: tag.title,
							}))}
							board={board}
							column_id={board.columns[i].id}
							filters={this.state.filters}
							onChangeFilters={this.onChangeFilters.bind(this)}
						/>
					);
				}
				if (column.type == "dropdown" && column.options && column.options.choices) {
					filter = (
						<BoardFiltersOptions
							options={column.options.choices.map((opt, index) => ({
								value: index,
								label: opt,
								inactive: column.options.removed_choices?.includes(index),
							}))}
							board={board}
							column_id={board.columns[i].id}
							filters={this.state.filters}
							onChangeFilters={this.onChangeFilters.bind(this)}
						/>
					);
				}
				if (column.type == "badges") {
					filter = (
						<BoardFiltersOptions
							options={column.options.choices.map((title) => ({ value: title, label: title }))}
							board={board}
							column_id={board.columns[i].id}
							filters={this.state.filters}
							onChangeFilters={this.onChangeFilters.bind(this)}
						/>
					);
				}
				if (column.type == "category" && board.categories) {
					filter = (
						<BoardFiltersOptions
							options={board.categories.map((cat, index) => ({ value: cat.id, label: cat.title }))}
							board={board}
							column_id={board.columns[i].id}
							filters={this.state.filters}
							onChangeFilters={this.onChangeFilters.bind(this)}
						/>
					);
				}
				if (column.type == "text") {
					filter = (
						<BoardFiltersText
							board={board}
							column_id={board.columns[i].id}
							filters={this.state.filters}
							onChangeFilters={this.onChangeFilters.bind(this)}
						/>
					);
				}
				if (column.type == "datetime" || column.type == "timeline" || column.type == "created_at" || column.type == "updated_at") {
					filter = (
						<BoardFiltersDate
							board={board}
							column_id={board.columns[i].id}
							filters={this.state.filters}
							onChangeFilters={this.onChangeFilters.bind(this)}
						/>
					);
				}

				if (filter) {
					filters.push({
						hideClearButton: true,
						key: board.columns[i].id + "-" + board.id,
						label: board.columns[i].title,
						filter,
						shortcut: this.props.pinnedFilters?.includes(board.columns[i].id),
					});
				}
			}

			filters.push({
				key: this.visibilityId,
				label: <BringModalToFront className={this.visibilityId} />,
				filter: null,
			});
		}

		const foundIndex = this.state.filters.findIndex((filter) => filter.column_id == "search");

		const filtersWithVisibiltiyCheck = filters.map((filter) => {
			return {
				...filter,
				filter: (
					<>
						{filter.filter}
						{this.visibilityElement}
					</>
				),
			};
		});

		return (
			<Wrapper className="filtercont">
				<span className="boardfilter" style={{ display: this.props.icon ? "none" : "initial" }} id="FILTER">
					<Filters
						queryPlaceholder={this.props.placeholder || this.props.t("board.filters.search", "Sök")}
						queryValue={foundIndex >= 0 ? this.state.filters[foundIndex].value : ""}
						filters={filtersWithVisibiltiyCheck}
						resourceName={{ singular: "board", plural: "board" }}
						appliedFilters={this.state.filters.filter((filter) => filter.column_id != "search")}
						onQueryChange={this.updateSearch.bind(this)}
						onQueryClear={this.updateSearch.bind(this, "")}
						onClearAll={this.clearFilters.bind(this)}
					>
						{this.props.additionalAction}
					</Filters>
				</span>
				{this.props.icon ? (
					<Button
						icon={FilterMajor}
						onClick={(e) => {
							$(e.nativeEvent.target).closest(".filtercont").find(".boardfilter .Polaris-Filters-ConnectedFilterControl__Item button").click();
						}}
						plain
					/>
				) : null}
			</Wrapper>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(BoardFilters);

const Wrapper = styled.div`
	.Polaris-Filters__AddFilter {
		background: var(--tertiary);

		svg {
			height: 20px;
			width: 20px;

			path {
				fill: var(--accent);
			}
		}
	}
`;
