/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import axios from "axios";
import { ProgressBar } from "@shopify/polaris";
import { store } from "./store";

import { toastr } from "./components/toastr";
import "./redirectToMobileStore";
import API, { fetchAndSetTokens } from "./API";
import App from "./app";
import SplashLoadingScreen from "./SplashLoadingScreen.tsx";
import { getCookie, getLocalStorage, getSessionStorage } from "./Utilities";
import "../css/app.scss";
import { decodeTokenUser, getSubdomain } from "./API/utils";
import getInitialState from "./store/getInitialState";
import { getRefreshToken } from "./Utilities/Utilities";
import moment from "moment";

const FADE_OUT_DURATION = 1000;
class PreApp extends Component {
	constructor(props) {
		super(props);
		this.state = { loading: true, showLoading: true, progress: 0 };
	}

	async getTokens() {
		const urlParams = new URLSearchParams(window.location.search);
		const token = store.getState().token || getCookie("JWTTOKEN");
		const refreshToken = urlParams.get("refresh_token") || getRefreshToken();

		console.debug("refreshToken:", refreshToken);
		console.debug('getSessionStorage("refresh_token"):', getSessionStorage("refresh_token"));
		console.debug('getLocalStorage("refresh_token"):', getLocalStorage("refresh_token"));
		console.debug("getRefreshToken():", getRefreshToken());

		if (!token && !refreshToken) {
			this.props.history.replace(encodeURI("/admin/authentication/login"));
			throw new Error("ignore");
		}

		if (!urlParams.get("token")) {
			const res = await this.handleRefreshToken(refreshToken, token);
			return res;
		}

		return { token, refreshToken };
	}

	async handleRefreshToken(refreshToken, token) {
		if (!refreshToken) {
			console.error("No refresh token available");
			throw new Error("Invalid login session");
		}

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const parentRoute = window.location?.pathname?.split("/")[1];
		const handle = process.env.NODE_ENV === "development" ? "dev" : getSubdomain(window.location.href);
		// const decodedToken = decodeTokenUser(token);

		if (handle !== "dev") {
			if (!handle || (parentRoute && parentRoute === "guest")) return;
			// if (decodedToken?.account?.handle === handle) return;
		}

		const result = await fetchAndSetTokens(refreshToken, { history: this.props.history, redirect: false });
		if (!result) throw new Error("ignore");

		const newToken = result.data.token;
		const newRefreshToken = result.data.refresh_token || refreshToken;
		const newDecodedToken = decodeTokenUser(newToken);

		if (newDecodedToken?.account?.handle !== handle && handle !== "dev") {
			throw new Error(`Handles don't match: ${newDecodedToken?.account?.handle} !== ${handle}`);
		}

		store.dispatch({ type: "SET_TOKEN", token: newToken });
		store.dispatch({ type: "SET_REFRESH_TOKEN", refresh_token: newRefreshToken });
		return { token: newToken, refreshToken };
	}

	async handleTokenRefreshToken() {
		const { token, refreshToken } = await this.getTokens();

		const initialState = getInitialState(token);

		store.dispatch({ type: "SET_USER", user: initialState.user });
		store.dispatch({ type: "SET_ACCOUNT", account: initialState.account });
		store.dispatch({ type: "SET_USERS", users: initialState.users });
		store.dispatch({ type: "SET_ACCOUNTS", accounts: initialState.accounts });
		store.dispatch({ type: "SET_TOKEN", token: initialState.token });
		store.dispatch({ type: "SET_REFRESH_TOKEN", refresh_token: initialState.refresh_token });
		store.dispatch({ type: "SET_BOARDS", boards: initialState.boards });

		return {
			token,
			refreshToken,
		};
	}

	componentDidMount() {
		(async () => {
			try {
				this.setState({ progress: 10 });
				await this.handleTokenRefreshToken();
				this.setState({ progress: 25 });
				this.fetchInitialStates();
			} catch (e) {
				console.error("Preapp Error", e);

				if (e?.message !== "ignore") {
					const msg = e?.message || "Invalid login session";
					this.props.history.replace(encodeURI(`/admin/authentication/login?error_code=401&error_msg=${msg}`));
				}
			}
		})();
	}

	componentWillUnmount() {
		clearTimeout(this.fadeOutTimer);
	}

	async getAction({ url, callback = () => {} } = {}) {
		const result = await API.get("/api" + url).then((result) => {
			if (result.data.error) {
				toastr.error(result.data.error);
				return;
			}
			callback(result);
			this.setState((c) => ({ progress: c.progress + 10 }));
			return result.data;
		});

		return result;
	}

	async fetchInitialStates() {
		if (store.getState().account?.company) {
			const titleSuffix =
				window.location.href.includes("acordo.se") || window.location.href.includes("nollettnollnoll.se") ? "- Acordo" : "- Sellfinity";

			document.title = `${store.getState().account.company} ${titleSuffix}`;
		}

		this.fetchRequiredInitialStates().then(() => {
			if (store.getState().user?.locale) {
				this.props.i18n.changeLanguage(store.getState().user.locale, () => {
					// eslint-disable-next-line no-console
					console.debug(`Preapp Changed to language "${store.getState().user.locale}"`);
				});
			}
		});
		this.fetchNonRequiredInitialStates();
	}

	async fetchRequiredInitialStates() {
		await Promise.all([
			this.getAction({
				url: "/account.json",
				callback: (result) => {
					if (result.data) {
						if (result.data?.company) {
							const titleSuffix =
								window.location.href.includes("acordo.se") || window.location.href.includes("nollettnollnoll.se") ? "- Acordo" : "- Sellfinity";
							document.title = `${store.getState().account.company} ${titleSuffix}`;
						}

						store.dispatch({ type: "SET_ACCOUNT", account: result.data });
					}
				},
			}),
			// Used for fetching a user's email signature (to big to store in JWT)
			this.getAction({
				url: "/me.json",
				callback: (result) => {
					if (result.data.user) {
						store.dispatch({ type: "SET_USER", user: { ...store.getState().user, ...result.data.user } });
					}
				},
			}),
			store.getState()?.account?.todo_board_id &&
				this.getAction({
					url: "/boards/" + store.getState().account.todo_board_id + ".json",
					callback: (result) => {
						store.dispatch({ type: "SET_ACCOUNT", account: { ...store.getState().account, todo_board: result.data.board } });
						store.dispatch({ type: "UPDATE_BOARD", board: result.data.board });
					},
				}),
			store.getState()?.account?.invoice_board_id &&
				this.getAction({
					url: "/boards/" + store.getState().account.invoice_board_id + ".json",
					callback: (result) => {
						store.dispatch({ type: "SET_ACCOUNT", account: { ...store.getState().account, invoice_board: result.data.board } });
						store.dispatch({ type: "UPDATE_BOARD", board: result.data.board });
					},
				}),
			store.getState()?.account?.offer_board_id &&
				this.getAction({
					url: "/boards/" + store.getState().account.offer_board_id + ".json",
					callback: (result) => {
						store.dispatch({ type: "SET_ACCOUNT", account: { ...store.getState().account, offer_board: result.data.board } });
						store.dispatch({ type: "UPDATE_BOARD", board: result.data.board });
					},
				}),
			store.getState()?.account?.order_board_id &&
				this.getAction({
					url: "/boards/" + store.getState().account.order_board_id + ".json",
					callback: (result) => {
						store.dispatch({ type: "SET_ACCOUNT", account: { ...store.getState().account, order_board: result.data.board } });
						store.dispatch({ type: "UPDATE_BOARD", board: result.data.board });
					},
				}),
			store.getState()?.account?.contract_board_id &&
				this.getAction({
					url: "/boards/" + store.getState().account.contract_board_id + ".json",
					callback: (result) => {
						store.dispatch({ type: "SET_ACCOUNT", account: { ...store.getState().account, contract_board: result.data.board } });
						store.dispatch({ type: "UPDATE_BOARD", board: result.data.board });
					},
				}),
			this.getAction({
				url: "/boards/" + store.getState().user.calendar_board_id + ".json",
				callback: (result) => {
					store.dispatch({ type: "SET_USER", user: Object.assign({}, store.getState().user, { calendar_board: result.data.board }) });

					store.dispatch({ type: "UPDATE_BOARD", board: result.data.board });
				},
			}),
			this.getAction({
				url: "/boards/" + store.getState().user.todo_board_id + ".json",
				callback: (result) => {
					store.dispatch({ type: "SET_USER", user: Object.assign({}, store.getState().user, { todo_board: result.data.board }) });

					store.dispatch({ type: "UPDATE_BOARD", board: result.data.board });
				},
			}),
			this.getAction({
				url: "/boards/" + store.getState().account.sales_board_id + ".json",
				callback: (result) => {
					store.dispatch({ type: "UPDATE_BOARD", board: result.data.board });
				},
			}),
		])
			.then(() => {
				this.setState({ loading: false, progress: 100 });
				this.fadeOutTimer = setTimeout(() => {
					this.setState({ showLoading: false });
				}, FADE_OUT_DURATION);
			})
			.catch((error) => {
				if (!axios.isCancel(error) && error?.code !== "ECONNABORTED") {
					console.error("error:", error);
				}

				const { code, message } = error?.response?.data || {};
				this.props.history.replace(encodeURI(`/admin/authentication/login?error_code=${code}&error_msg=${message}`));
			});
	}

	async fetchNonRequiredInitialStates() {
		await Promise.all([
			this.getAction({
				url: "/users.json",
				callback: (result) => result.data.users && store.dispatch({ type: "SET_USERS", users: result.data.users }),
			}),
			this.getAction({
				url: "/accounts.json",
				callback: (result) => result.data.accounts && store.dispatch({ type: "SET_ACCOUNTS", accounts: result.data.accounts }),
			}),
			this.getAction({
				url: "/dashboard_favorites.json",
				callback: (result) =>
					result.data.favorite_dashboards && store.dispatch({ type: "SET_FAVORITE_DASHBOARDS", dashboards: result.data.favorite_dashboards }),
			}),
			this.getAction({
				url: "/board_favorites.json",
				callback: (result) => result.data.favorite_boards && store.dispatch({ type: "SET_FAVORITE_BOARDS", boards: result.data.favorite_boards }),
			}),
			this.getAction({
				url: "/groups.json",
				callback: (result) => result.data.groups && store.dispatch({ type: "SET_GROUPS", groups: result.data.groups }),
			}),
			this.getAction({
				url:
					"/targets.json?" +
					(() => {
						const params = new URLSearchParams({
							date: moment().format("YYYY-MM-DD"),
							disabled: 0,
						});
						const permissions = ["read_permission", "read_all_permission"];
						permissions.forEach((permission) => params.append("permissions[]", permission));

						return params.toString();
					})(),

				callback: (result) => result.data.targets && store.dispatch({ type: "SET_TARGETS", targets: result.data.targets }),
			}),
			this.getAction({
				url: "/account_shortcuts.json",
				callback: (result) => result.data.shortcuts && store.dispatch({ type: "SET_SHORTCUTS", shortcuts: result.data.shortcuts }),
			}),
			this.getAction({
				url: "/saved_searches.json",
				callback: (result) =>
					result.data.saved_searches && store.dispatch({ type: "SET_SAVED_SEARCHES", saved_searches: result.data.saved_searches }),
			}),
			this.getAction({
				url: "/accounts_ad.json",
				callback: (result) => result.data.ad_accounts && store.dispatch({ type: "SET_AD_ACCOUNTS", ad_accounts: result.data.ad_accounts }),
			}),
			this.getAction({
				url: "/contact_metafields.json",
				callback: (result) =>
					result.data.contact_metafields && store.dispatch({ type: "SET_CONTACT_METAFIELDS", contact_metafields: result.data.contact_metafields }),
			}),
			this.getAction({
				url: "/contact_activities.json",
				callback: (result) =>
					result.data.contact_activities && store.dispatch({ type: "SET_CONTACT_ACTIVITIES", contact_activities: result.data.contact_activities }),
			}),
			this.getAction({
				url: "/fields.json",
				callback: (result) => result.data.fields && store.dispatch({ type: "SET_FIELDS", fields: result.data.fields }),
			}),
			this.getAction({
				url: "/currency_rates.json",
				callback: (result) =>
					result.data.currency_rates && store.dispatch({ type: "SET_CURRENCY_RATES", currency_rates: result.data.currency_rates }),
			}),

			// tas bort senare
			this.getAction({
				url: "/stages.json",
				callback: (result) => result.data.stages && store.dispatch({ type: "SET_STAGES", stages: result.data.stages }),
			}),
			this.getAction({
				url: "/deals_types.json",
				callback: (result) => result.data.deal_types && store.dispatch({ type: "SET_DEAL_TYPES", deal_types: result.data.deal_types }),
			}),
			this.getAction({
				url: "/goals.json?user_id=" + store.getState().user.id,
				callback: (result) => result.data.goals && store.dispatch({ type: "SET_GOALS", goals: result.data.goals }),
			}),
			this.getAction({
				url: "/custom_fields.json",
				callback: (result) => result.data.custom_fields && store.dispatch({ type: "SET_CUSTOM_FIELDS", custom_fields: result.data.custom_fields }),
			}),
			this.getAction({
				url: "/categories.json",
				callback: (result) =>
					result.data.categories && store.dispatch({ type: "SET_ARTICLE_CATEGORIES", article_categories: result.data.categories }),
			}),
		])
			.then(() => {})
			.catch((error) => {
				if (!axios.isCancel(error) && error?.code !== "ECONNABORTED") {
					console.error("error:", error);
				}
				// const { code, message } = error?.response?.data || {};
				// window.location.href = encodeURI(`/admin/authentication/login?error_code=${code}&error_msg=${message}`);
			});
	}

	render() {
		return (
			<>
				{this.state.showLoading && (
					<SplashLoadingScreen show={this.state.loading} fadeOutDuration={FADE_OUT_DURATION}>
						<div style={{ width: 300 }}>
							<ProgressBar size="medium" progress={this.state.progress} />
						</div>
					</SplashLoadingScreen>
				)}
				{!this.state.loading && <App {...this.props} />}
			</>
		);
	}
}

export default withTranslation(["common"], { withRef: true })(PreApp);
