/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { Component } from "react";
import {
	Page,
	PageActions,
	Banner,
	Layout,
	LegacyCard,
	Tag,
	FormLayout,
	TextField,
	Text,
	ResourceList,
	Checkbox,
	ChoiceList,
	Spinner,
} from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { connect } from "react-redux";
import uniq from "lodash/uniq";
import { fetchAndSetTokens } from "src/js/API/API";
import API from "../../API";
import { store } from "../../store";

import { toastr } from "../../components/toastr.js";
import SkeletonProvider from "../../components/skeleton_provider.js";
import Goal from "../../components/Goal.js";
import FieldSelector from "../../components/FieldSelector.js";
import GoalModal from "../../components/GoalModal.js";
import UserGoalModal from "../../components/UserGoalModal";
import IndexTable from "src/js/components/IndexTable";
import Modal from "src/js/components/modal";

class UserEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// role: "",
			currentGoal: {},
			// showRemoveModal: false,
			loadingGoals: true,
			id: props.match.params.id,
			loading: true,
			saving: false,
			goals: [],
			form: null,
			sections: [],
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.match.params.id != this.state.id) {
			this.fetchItem(props.match.params.id);
			this.fetchGoals(props.match.params.id);
		}
	}

	componentDidMount() {
		this.fetchSections();
		this.fetchGoals(this.state.id);
		this.fetchItem(this.state.id);
	}

	fetchItem(id) {
		this.setState({ id, loading: true });
		API.get("/api/users/" + id + ".json")
			.then((result) => {
				this.setState({ loading: false, saving: false, form: result.data.user });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	fetchSections() {
		API.get("/api/sections.json")
			.then((result) => {
				this.setState({ sections: result.data.workspaces });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	arrDiff(a1, a2) {
		const a = {};

		for (let i = 0; i < a1.length; i++) {
			a[a1[i]] = true;
		}

		for (let i = 0; i < a2.length; i++) {
			if (a[a2[i]]) {
				delete a[a2[i]];
			} else {
				a[a2[i]] = true;
			}
		}

		return Object.keys(a);
	}

	updateRoles(newRoles) {
		const diff = this.arrDiff(newRoles, this.state.form?.roles);
		const role = diff[0];

		if (this.state.form?.roles.indexOf(role) >= 0) {
			// Remove all roles with same prefix
			const n = [];
			for (let i = 0; i < newRoles.length; i++) {
				if (newRoles[i].substr(0, role.length) != role) {
					n.push(newRoles[i]);
				}
			}
			newRoles = n;
		}

		this.state.form.roles = newRoles;
		this.setState({ form: this.state.form });
	}

	updateForm(field, value) {
		if (field == "roles") {
			this.updateRoles(value);
			return;
		}
		const formState = this.state.form;
		formState[field] = value;

		this.setState({ form: formState });
	}

	saveForm() {
		this.state.form.roles = uniq(this.state.form.roles);

		const data = Object.assign({}, this.state.form);
		this.setState({ saving: true });
		API.put("/api/users/" + this.state.id + ".json", data)
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.setState({ form: result.data.user });
				store.dispatch({ type: "UPDATE_USER", user: result.data.user });

				if (result.data.user.id == store.getState().user.id) {
					const user = Object.assign({}, store.getState().user);
					user.roles = result.data.user.roles;
					store.dispatch({ type: "SET_USER", user });

					API.get("/api/workspaces.json", { params: {} })
						.then((result) => {
							if (result.data.error) {
								toastr.error(result.data.error);
								this.setState({ loading: false });
								return;
							}

							store.dispatch({ type: "SET_WORKSPACES", workspaces: result.data.workspaces || null });
						})
						.catch((error) => {});

					fetchAndSetTokens().catch((error) => console.error(error));
				}
				toastr.success(this.props.t("user.responses.updated", "Uppdaterade användare"));
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	addUser(id, userId) {
		this.state[`addingUserToGoal_${id}`] = true;
		this.setState({});

		API.post(`/api/goals/${id}/users/${userId}.json`)
			.then((result) => {
				if (result.data.error) {
					console.error("error:", result.data.error);
					toastr.error(result.data.error);
					return;
				}
				this.setState((c) => ({ goalsIndexModalIsOpen: false, goals: [...(c.goals || []), result.data.goal] }));
				toastr.success(this.props.t("goals.responses.user_added", "Användare tillagd på målsättning"));
			})
			.catch((error) => {
				console.error("error:", error);
				toastr.error(error);
			})
			.finally(() => {
				this.state[`addingUserToGoal_${id}`] = false;
				this.setState({});
			});
	}

	renderItem(item) {
		return (
			<StyledGoalItem
				className={`goal-list-item${String(this.state.selectedGoalId) === String(item.id) ? " active" : ""}`}
				onClick={this.addUser.bind(this, item.id, this.state.id)}
			>
				<div className="goal_title">
					<h3>{item.title}</h3>
					{this.state[`addingUserToGoal_${item.id}`] && <Spinner size="small" />}
					{/* <Spinner size="small" /> */}
				</div>
			</StyledGoalItem>
		);
	}

	openGoalsIndexModal(item) {
		this.setState({ goalsIndexModalIsOpen: true });
	}

	renderGoal(item) {
		return (
			<Goal
				onClick={() => {
					this.setState({ userGoalModalIsOpen: true, editingGoal: item });
				}}
				history={this.props.history}
				goal={item}
				user={this.state.form}
				user_ids={[this.state.form?.id]}
			/>
		);
	}

	fetchGoals(id = this.state.id, silent) {
		const params = {
			user_id: id,
		};
		if (!silent) this.setState({ loadingGoals: true });
		API.get("/api/users/" + id + "/goals.json", { params })
			.then((result) => {
				this.setState({ loadingGoals: false, goals: result.data.goals });
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ loadingGoals: false });
			});
	}

	addField(field) {
		for (let i = 0; i < this.state.form?.fields.length; i++) {
			if (this.state.form?.fields[i].id == field.id) {
				return;
			}
		}
		this.state.form.fields.push(field);
		this.setState({ form: this.state.form });
	}

	removeField(index) {
		this.state.form?.fields.splice(index, 1);
		this.setState({ form: this.state.form });
	}

	getChildren(options, isSelected) {
		return (
			isSelected && (
				<ChoiceList onChange={this.updateForm.bind(this, "roles")} choices={options} selected={this.state.form?.roles || []} allowMultiple />
			)
		);
	}

	render() {
		if (this.state.loading) {
			return <SkeletonProvider />;
		}

		const roleOptions = [
			{
				value: "ROLE_CONTACTS",
				disabled: true,
				label: this.props.t("user.roles.contacts", "Företag/kontakter"),
				renderChildren: (isSelected) =>
					isSelected && (
						<ChoiceList
							onChange={this.updateForm.bind(this, "roles")}
							choices={[{ value: "ROLE_CREATE_TAG", label: this.props.t("user.roles.create_tag", "Skapa nya taggar") }]}
							selected={this.state.form?.roles || []}
							allowMultiple
						/>
					),
			},
			{
				value: "ROLE_MARKETING",
				label: this.props.t("user.roles.marketing", "Marknadsföring"),
				renderChildren: (isSelected) =>
					isSelected && (
						<ChoiceList
							onChange={this.updateForm.bind(this, "roles")}
							choices={[
								{ value: "ROLE_MARKETING_EMAIL_CAMPAIGNS", label: this.props.t("user.roles.marketing_email", "E-postkampanjer") },
								// { value: "ROLE_MARKETING_ADS", label: this.props.t("user.roles.marketing_ads", "Online annonsering") },
							]}
							selected={this.state.form?.roles || []}
							allowMultiple
						/>
					),
			},
			{
				value: "ROLE_BOLAGS_IMPORT",
				label: this.props.t("user.roles.bolags_import", "Bolagsimport"),
			},
			{
				value: "ROLE_ORDER_ADMIN",
				label: this.props.t("user.roles.order_admin", "Kan se alla ordrar"),
			},
		];

		for (let i = 0; i < this.state.sections.length; i++) {
			const section = this.state.sections[i];
			if (section.type == "shareable") {
				const boards = section.boards.filter((v) => v.type == "shareable");
				const publicBoards = section.boards.filter((v) => v.type == "public");
				const obj = { value: "ROLE_WORKSPACE_" + section.id, label: section.title };
				if (boards.length || publicBoards.length) {
					let options = [];
					if (section.title == "Servicedesk") {
						options = [{ value: "ROLE_SUPPORT_RECEIVE", label: this.props.t("user.roles.support_receive", "Ta emot mail för nya ärenden") }];
					} else {
						options = [];
					}
					for (let s = 0; s < boards.length; s++) {
						options.push({ value: "ROLE_BOARD_" + boards[s].id, label: boards[s].title });
					}
					obj.renderChildren = this.getChildren.bind(this, options);
				}
				roleOptions.push(obj);
			}
		}

		roleOptions.push({ value: "ROLE_ADMIN", label: this.props.t("user.roles.admin", "Administratör") });

		store.getState().account.integrations.forEach((integration) => {
			roleOptions.push({
				value: "ROLE_INTEGRATION_" + integration.id,
				label: `${this.props.t("user.role.integration.label", "Integration")}: ${integration.title}`,
			});
		});

		return (
			<Page
				title={this.state.form?.name}
				backAction={{ content: this.props.t("common.actions.back", "Tillbaka"), onAction: () => this.props.history.goBack() }}
				primaryAction={{
					content: this.props.t("common.actions.update", "Uppdatera"),
					disabled: !this.state.form?.email,
					loading: this.state.saving,
					onAction: this.saveForm.bind(this),
				}}
				secondaryActions={[
					{
						content: this.props.t("user.actions.show_activity", "Visa aktivitet"),
						onAction: () => {
							this.props.history.push("/admin/users_activity/user/" + this.state.id);
						},
					},
				]}
			>
				<Layout>
					<Layout.AnnotatedSection
						title={this.props.t("user.sections.details.title", "Användaruppgifter")}
						description={this.props.t("user.sections.details.description", "Fyll i användaruppgifter")}
					>
						<LegacyCard title={this.props.t("user.sections.details.card.title", "Uppgifter")}>
							{this.state.form?.id && !this.state.form?.enabled && (
								<Banner status="warning" title={this.props.t("user.errors.disabled.title", "Användarkonto avstängt")}>
									<p>{this.props.t("user.errors.disabled.description", "Användaren kan inte logga in, aktivera konto för att tillåta inloggning")}</p>
								</Banner>
							)}
							<LegacyCard.Section>
								<FormLayout>
									<Checkbox
										label={this.props.t("user.fields.enabled.label", "Avstängt för inloggning")}
										checked={!this.state.form?.enabled}
										onChange={(v) => {
											this.updateForm("enabled", !v);
										}}
									/>
									<TextField
										label={this.props.t("user.fields.name.label", "För- och efternamn")}
										value={this.state.form?.name}
										onChange={this.updateForm.bind(this, "name")}
									/>
									<TextField
										label={this.props.t("user.fields.email.label", "E-mail")}
										value={this.state.form?.email}
										onChange={this.updateForm.bind(this, "email")}
									/>
									<TextField
										label={this.props.t("user.fields.phone.label", "Mobilnummer")}
										value={this.state.form?.phone}
										onChange={this.updateForm.bind(this, "phone")}
									/>
									{/* <TextField
										multiline
										label={this.props.t("user.fields.email_signature.label", "E-mail signatur")}
										value={this.state.form?.email_signature}
										onChange={this.updateForm.bind(this, "email_signature")}
									/> */}
								</FormLayout>
							</LegacyCard.Section>
						</LegacyCard>
					</Layout.AnnotatedSection>

					<Layout.AnnotatedSection
						title={this.props.t("user.sections.roles.title", "Användarrättigheter")}
						description={this.props.t("user.sections.roles.description", "Välj rättigheter åt användaren")}
					>
						<LegacyCard>
							<LegacyCard.Section>
								<ChoiceList
									onChange={this.updateForm.bind(this, "roles")}
									choices={roleOptions}
									selected={["ROLE_CONTACTS"].concat(this.state.form?.roles || [])}
									allowMultiple
								/>
							</LegacyCard.Section>
						</LegacyCard>
					</Layout.AnnotatedSection>

					<Layout.AnnotatedSection
						title={this.props.t("user.sections.goals.title", "Målsättningar")}
						description={this.props.t("user.section.goals.description", "Målsättningar för användaren")}
					>
						<LegacyCard
							title={this.props.t("user.sections.goals.card.title", "Målsättningar")}
							actions={[
								{
									content: this.props.t("goals.actions.connect", "Koppla målsättning"),
									onAction: this.openGoalsIndexModal.bind(this),
								},
							]}
						>
							<ResourceList
								resourceName={{ singular: this.props.t("goals.singular", "målsättning"), plural: this.props.t("goals.plural", "målsättningar") }}
								items={this.state.goals}
								loading={this.state.loadingGoals}
								renderItem={this.renderGoal.bind(this)}
							/>
							{!this.state.loadingGoals && this.state.goals.length < 1 ? (
								<LegacyCard.Section>
									<Text variation="subdued">{this.props.t("user.sections.goals.empty.title", "Inga målsättningar har skapats för användaren")}</Text>
								</LegacyCard.Section>
							) : null}
						</LegacyCard>
					</Layout.AnnotatedSection>

					{store.getState().groups?.length ? (
						<Layout.AnnotatedSection
							title={this.props.t("user.sections.office_department1.title", "Avdelningar")}
							description={this.props.t("user.sections.office_department.description1", "Välj avdelning som användaren tillhör")}
						>
							<LegacyCard>
								<LegacyCard.Section title={this.props.t("user.sections.office_department.department.title", "Avdelningar")}>
									<FormLayout>
										{this.state.form?.fields.map((field, index) => (
											<Tag key={index} onRemove={this.removeField.bind(this, index)}>
												{field.title}
											</Tag>
										))}
										<FieldSelector
											required
											empty={this.props.t("user.sections.fields.department.empty", "Välj avdelning")}
											label={this.props.t("user.sections.fields.department.label", "Lägg till avdelning")}
											field={null}
											onChange={this.addField.bind(this)}
										/>
									</FormLayout>
								</LegacyCard.Section>
							</LegacyCard>
						</Layout.AnnotatedSection>
					) : null}
				</Layout>
				<PageActions
					primaryAction={{
						content: this.props.t("common.actions.update", "Uppdatera"),
						disabled: !this.state.form?.email,
						loading: this.state.saving,
						onAction: this.saveForm.bind(this),
					}}
				/>
				<GoalModal
					open={this.state.goalModalOpen}
					goal={this.state.currentGoal}
					onClose={() => {
						this.setState({ goalModalOpen: false });
					}}
					onCreate={(source) => {
						this.setState({ goalModalOpen: false });
						this.fetchGoals(this.state.id);
					}}
				/>

				<Modal
					open={this.state.goalsIndexModalIsOpen}
					onClose={() => {
						this.setState({ goalsIndexModalIsOpen: false });
					}}
					sectioned
				>
					<IndexTable
						itemPadding={10}
						urlParamsEnabled={false}
						limit={10}
						history={this.props.history}
						savedSearchHandle="goals"
						resourceUrl="/api/goals.json"
						resourceHandle="goals"
						resourceName={{
							singular: this.props.t("goals.singular", "målsättning"),
							plural: this.props.t("goals.plural", "målsättningar"),
						}}
						columns={[{ label: "", handle: "", render: this.renderItem.bind(this) }]}
					/>
				</Modal>

				<UserGoalModal
					open={this.state.userGoalModalIsOpen}
					goal={this.state.editingGoal}
					user={this.state.form}
					onCreate={(goal) => {
						if (goal) {
							const goalIndex = this.state.goals.findIndex((g) => g.id === goal.id);
							this.state.goals.splice(goalIndex, 1, goal);
							this.setState({ goals: this.state.goals });
							this.fetchGoals(this.state.id, true);
						}
						this.setState({ editingGoal: null, userGoalModalIsOpen: false });
					}}
					onClose={() => {
						this.setState({ editingGoal: null, userGoalModalIsOpen: false });
					}}
				/>
			</Page>
		);
	}
}

const mapStateToProps = (state) => ({
	users: state.users,
});

export default connect(mapStateToProps)(withTranslation(["user", "common"], { withRef: true })(UserEdit));

const StyledGoalItem = styled.div`
	padding: 0;
`;
