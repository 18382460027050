import moment from "moment";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import Modal from "src/js/components/modal/Modal";
import ResourcePicker from "src/js/components/resource_picker";
import { store } from "src/js/store";

const CopyModal = ({ t, open, onClose, secondaryActions, history, invoice, contract, order, offer }: any) => {
	const [contact, setContact] = useState<ContactType | null>(null);
	useEffect(() => {
		if (!open) {
			setContact(null);
		}
	}, [open]);

	const data = (() => {
		if (invoice) {
			return {
				title: t("fortnox.copy.invoice.title", "Kopiera faktura"),
				resource: "invoices",
			};
		}

		if (order) {
			return {
				title: t("fortnox.copy.order.title", "Kopiera order"),
				resource: "orders",
			};
		}

		if (offer) {
			return {
				title: t("fortnox.copy.offer.title", "Kopiera offert"),
				resource: "offers",
			};
		}

		if (contract) {
			return {
				title: t("fortnox.copy.contract.title", "Kopiera avtal"),
				resource: "contracts",
			};
		}

		return {};
	})();

	const handleContactChange = (contact) => {
		setContact(contact);
	};

	const getData = () => {
		const data = { ...(invoice || order || offer || contract) };

		const removeKeys = [
			"DocumentNumber",
			"Cancelled",
			"Booked",
			"CustomerName",
			"CustomerNumber",
			"ExternalInvoiceReference1",
			"ExternalInvoiceReference2",
			"InvoiceReference",
			"OfferReference",
			"OrderReference",
			"NotCompleted",
			"Total",
			"TotalVAT",
			"Sent",

			"Address1",
			"Address2",
			"ZipCode",
			"City",
			"Country",
			"Phone1",
		];

		const newData = removeKeys.reduce(
			(acc, key) => {
				if (key in acc) delete acc[key];
				return acc;
			},
			{ ...data }
		);

		const rowsKey = (() => {
			if (invoice || contract) return "InvoiceRows";
			if (order) return "OrderRows";
			if (offer) return "OfferRows";
		})() as "InvoiceRows" | "OrderRows" | "OfferRows";

		const removeRowKeys = [
			"ContributionPercent",
			"ContributionValue",
			"CostCenter",
			"HouseWork",
			"HouseWorkHoursToReport",
			"HouseWorkType",
			"RowId",
			"Total",
			"TotalExcludingVAT",
		];

		newData[rowsKey] = newData[rowsKey].map((row) => {
			return removeRowKeys.reduce(
				(acc, key) => {
					if (removeRowKeys.includes(key)) delete acc[key];
					return acc;
				},
				{ ...row }
			);
		});

		newData.EmailInformation = {
			EmailAddressFrom: store.getState().user.email,
			EmailAddressTo: contact?.email || "",
			EmailAddressCC: "",
			EmailAddressBCC: "",
			EmailSubject: "",
			EmailBody: "",
		};

		if (invoice) {
			newData.InvoiceDate = moment().format("YYYY-MM-DD");
			newData.DueDate = moment().add(30, "days").format("YYYY-MM-DD");
		} else if (order) {
			newData.OrderDate = moment().format("YYYY-MM-DD");
		} else if (offer) {
			newData.OfferDate = moment().format("YYYY-MM-DD");
			newData.ExpireDate = moment().add(30, "days").format("YYYY-MM-DD");
		} else if (contract) {
			newData.ContractDate = moment().format("YYYY-MM-DD");
			newData.PeriodStart = moment().add(1, "months").startOf("month").format("YYYY-MM-DD");
			newData.PeriodEnd = moment(moment().add(1, "months").startOf("month").format("YYYY-MM-DD"))
				.add(1, "months")
				.startOf("month")
				.format("YYYY-MM-DD");
			newData.Active = true;
		}

		return newData;
	};

	const handleCopy = () => {
		if (!contact) return null;

		history.push(`/admin/fortnox/contacts/${contact?.id}/${data.resource}/create`, {
			contact,
			data: getData(),
		});
	};

	const primaryAction = {
		content: t("contact.copy.title", "Kopiera"),
		disabled: !contact,
		onAction: handleCopy,
	};
	return (
		<Modal open={!!open} onClose={onClose} title={data.title} primaryAction={primaryAction} secondaryActions={secondaryActions} sectioned>
			<ResourcePicker
				label={t("contact.company.choose", "Välj kontakt")}
				caption={t("contact.company.choose_caption", "Välj kontakt")}
				resource={"contacts.json"}
				resource_handle="contacts"
				item={contact}
				fullWidth
				id_handle="id"
				label_handle="fullname"
				resourceName={{
					singular: t("contact.person.singular", "kontaktperson"),
					plural: t("contact.person.plural", "kontaktpersoner"),
				}}
				allowCreate
				onChange={handleContactChange}
			/>
		</Modal>
	);
};
export default withTranslation(["fortnox", "contact", "common"])(withRouter(CopyModal));
