/* eslint-disable camelcase */
import React, { useMemo, useState } from "react";
import { getLocalStorage, setLocalStorage } from "src/js/Utilities";
import styled from "styled-components";
import ContactDashboard from "./ContactDashboard";
import { withTranslation } from "react-i18next";
import Select from "src/js/components/select/Select";
import ContactEconomyDashboard from "./economy/ContactEconomyDashboard";
import ContactInvoiceAndActivitiesDashboard from "./ContactInvoiceAndActivitiesDashboard";
import { store } from "src/js/store";
import checkForScopes from "../../Fortnox/Utilities/checkForScopes";
import ContactEconomyDashboardWithoutOfferOrder from "./economy/ContactEconomyDashboardWithoutOfferOrder";

const ContactDashboardContainer = ({ setTab, updateForm, contact, history, setCounts, t }) => {
	const [selectedDashboardId, setSelectedDashboardId] = useState(getLocalStorage("selectedDashboards")?.contacts || "default");

	const dashboards = useMemo(
		() =>
			[
				{
					id: "default",
					name: t("dashboards.default.title", "Standard"),
					component: <ContactDashboard setTab={setTab} updateForm={updateForm} contact={contact} history={history} setCounts={setCounts} />,
				},
				{
					id: "invoice_activities",
					name: t("dashboards.invoice_activities.title", "Fakturor & aktiviteter"),
					component: (
						<ContactInvoiceAndActivitiesDashboard setTab={setTab} updateForm={updateForm} contact={contact} history={history} setCounts={setCounts} />
					),
					enabled: store.getState().account.invoice_board?.id && checkForScopes(["invoice"]),
				},
				{
					id: "economy",
					name: t("dashboards.economy.title", "Ekonomi"),
					component: store.getState().account.order_board?.id ? (
						<ContactEconomyDashboard contact={contact} history={history} />
					) : (
						<ContactEconomyDashboardWithoutOfferOrder contact={contact} history={history} />
					),
					enabled: checkForScopes(["invoice", "order", "offer"]) && store.getState().account.invoice_board?.id,
				},
			]?.filter((d) => !("enabled" in d) || d.enabled),

		[contact, history, setCounts, setTab, updateForm, t]
	);

	const selectedDashboard = dashboards.find((dashboard) => dashboard.id === selectedDashboardId) || dashboards[0];

	const handleDashboardChange = (value) => {
		setSelectedDashboardId(value);
		const selectedDashboards = getLocalStorage("selectedDashboards") || {};

		setLocalStorage("selectedDashboards", { ...selectedDashboards, contacts: value });
	};

	return (
		<Wrapper>
			{dashboards?.length > 1 && (
				<SelectWrapper>
					<SelectWrapperLabel>{t("dashboard.actions.choose", "Välj dashboard")}</SelectWrapperLabel>

					<Select
						value={selectedDashboardId}
						onChange={handleDashboardChange}
						options={dashboards.map((dashboard) => ({ label: dashboard.name, value: dashboard.id }))}
					/>
				</SelectWrapper>
			)}
			<DashboardWrapper key={selectedDashboardId}>{selectedDashboard?.component || "Coming soon"}</DashboardWrapper>
		</Wrapper>
	);
};
export default withTranslation(["contact", "dashboard", "common"])(ContactDashboardContainer);

const Wrapper = styled.div``;
const SelectWrapper = styled.div`
	align-items: center;
	display: flex;
	margin-bottom: 0.9375rem;
	margin-top: -0.9375rem;

	&&&&& {
		.Polaris-Select {
			width: max-content;

			.Polaris-Select__Content {
				font-weight: 500;
			}

			.Polaris-Select__SelectedOption {
				padding-right: 0.1875rem;
			}

			.Polaris-Select__Icon svg {
				fill: var(--textColor);
			}

			.Polaris-Select__Backdrop,
			.Polaris-Select__Backdrop::after,
			.Polaris-Select__Content {
				background: transparent;
				border: none;
				box-shadow: none;
			}
		}
	}
`;
const SelectWrapperLabel = styled.span`
	font-size: 13px;
	font-weight: 500;
	line-height: 15px;
	color: var(--textColor2);
`;

const DashboardWrapper = styled.div``;
