import { ActionList, Icon, Tooltip } from "@shopify/polaris";
import { DeleteMajor, DragHandleMinor, ResetMinor, SearchMajor } from "@shopify/polaris-icons";
import moment from "moment";
import React, { useCallback, useEffect, useMemo } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import SelectSearchField from "src/js/components/SelectSearchField";
import TextField from "src/js/components/TextField";
import MyActionList from "src/js/views/Workspaces/components/MyActionList";
import styled from "styled-components";
import getRowSum from "../Utilities/getRowSum";
import Button from "src/js/components/Button";
import CustomFieldValueComponent from "../../CustomFieldValueComponent";
import API from "src/js/API";
import { toastr } from "src/js/components/toastr";
import Select from "src/js/components/select/Select";
import { useEventListener } from "src/js/hooks/UseEventListener";
import { formatRowToRead, formatRowToWrite } from "../Utilities/formatRow";
import { numberFormatter } from "src/js/Utilities";
import Spinner from "src/js/components/Spinner";
import DropDown from "src/js/components/dropdown";
import getOrderRowTB from "../Utilities/getOrderRowTB";
import { store } from "src/js/store";

export const changeArticleOnRow = (row, article): OrderRowType => {
	if (!article) {
		return {
			...(row || {}),
			article: null,
			article_pricelist: null,
			description: "",
			quantity: 0,
			unit: 0,
			price: 0,
			tb1: 0,
			tb2: 0,
			discount: "",
			custom_fields: [],
		};
	}

	const articlePricelist = article?.filtered_article_pricelists?.[0];

	const customFieldsWhenPlacingOrder = Array.isArray(article?.all_custom_fields_to_show_when_placing_order)
		? article.all_custom_fields_to_show_when_placing_order
		: Object.values(article?.all_custom_fields_to_show_when_placing_order || {});
	const item = {
		...(row || {}),
		//Cleaining up the custom fields when changing article. And setting the default values.
		custom_fields:
			customFieldsWhenPlacingOrder?.map((field) => {
				return {
					value: field.default_value,
					custom_field: field,
					custom_field_id: field.id,
				};
			}) || [],
		article,
		article_pricelist_price_in_cents: articlePricelist?.price_in_cents || 0,
		article_pricelist_tb1_in_cents: articlePricelist?.tb1_in_cents || 0,
		article_pricelist_tb2_in_cents: articlePricelist?.tb2_in_cents || 0,

		description: article?.title || "",
		quantity: article?.quantity_default_value || 1,

		unit: article?.unit || "",
		article_pricelist: articlePricelist,
		price_in_cents: articlePricelist?.price_in_cents || 0,
		tb1_in_cents: articlePricelist?.tb1_in_cents || 0,
		tb2_in_cents: articlePricelist?.tb2_in_cents || 0,
		price: articlePricelist?.price || 0,
		tb1: articlePricelist?.tb1 || 0,
		tb2: articlePricelist?.tb2 || 0,
		vat_in_percentage: article?.vat_in_percentage || 0,
	} as OrderRowType;

	return item;
};

type OrderRowProps = {
	columns: { header: string; handle: OrderRowTypeMutableKeys; style?: React.CSSProperties; suffix?: string }[];
	onChange: (orderRow: OrderRowType | null) => void;
	index?: number;
	order?: OrderType;
	row: OrderRowType | null;
	innerRef?: React.Ref<HTMLDivElement>;
	disabled?: boolean;
} & WithTranslation;

const OrderRow = ({ columns, row, onChange, index, t, innerRef, order, disabled, ...rest }: OrderRowProps) => {
	const [form, setForm] = React.useState<OrderRowType | null>(row);
	const [isLoadingArticle, setIsLoadingArticle] = React.useState(false);
	const ref = React.useRef<HTMLDivElement>(null);
	useEventListener("keydown", (e) => {
		if (e.key === "Enter") {
			e.preventDefault();
			e.stopPropagation();
			if (form && index !== undefined && [...(ref?.current?.querySelectorAll("input") || [])]?.some((el) => el === document.activeElement)) {
				onChange(formatRowToRead({ ...form }));
				(document.activeElement as any)?.blur?.();
			}
		}
	});

	useEffect(() => {
		setForm(row);
	}, [row]);

	const setArticle = useCallback(
		async (art) => {
			const article = await (async () => {
				if (!art) return null;

				try {
					const res = await API.get(`/api/articles/${art.id}.json`, {
						params: {
							contact_id: order?.customer_fields?.customer?.id || null,
						},
					});

					return res.data.article;
				} catch (e) {
					console.error("Error", e);
					return null;
				}
			})();

			onChange(changeArticleOnRow(form, article));
		},
		[onChange, form, order]
	);

	const handleChangeCustomFieldValue = useCallback(
		(index: number, customField) => (value: string) => {
			setForm((form) => {
				const item = {
					custom_fields: [],
					...(form || {}),
				} as OrderRowType;

				if (index === -1) {
					item.custom_fields.push({
						custom_field_id: customField.id,
						custom_field: customField,
						value,
					});
				} else {
					item.custom_fields[index].value = value;
				}

				return item;
			});
		},
		[]
	);

	const handleChange = useCallback(
		(field: OrderRowTypeMutableKeys) => (value: string | boolean | ArticlePricelistType | ArticleType | null | number) => {
			if (field === "article") {
				setArticle(value as any);

				if ((value as ArticleType)?.id) {
					setIsLoadingArticle(true);

					API.get(`/api/articles/${(value as ArticleType)?.id}.json`)
						.then((res) => {
							if (res.data.article) {
								const article = res.data.article;
								if (article) setArticle(article);
							}
						})
						.catch((err) => {
							toastr.error(err);
						})
						.finally(() => {
							setIsLoadingArticle(false);
						});
				}
				return;
			}

			setForm((form) => {
				const item = {
					...(form || {
						article_pricelist: null,
						price_in_cents: 0,
						tb1_in_cents: 0,
						tb2_in_cents: 0,
						price: 0,
						tb1: 0,
						tb2: 0,
						quantity: 0,
					}),
				} as OrderRowType;

				if (field === "article_pricelist") {
					const articlePriceList = value as ArticlePricelistType;
					item.price_in_cents = articlePriceList.price_in_cents;
					item.tb1_in_cents = articlePriceList.tb1_in_cents;
					item.tb2_in_cents = articlePriceList.tb2_in_cents;

					item.article_pricelist_price_in_cents = articlePriceList.price_in_cents;
					item.article_pricelist_tb1_in_cents = articlePriceList.tb1_in_cents;
					item.article_pricelist_tb2_in_cents = articlePriceList.tb2_in_cents;

					item.price = articlePriceList.price;
					item.tb1 = articlePriceList.tb1;
					item.tb2 = articlePriceList.tb2;
				}

				if (field === "price") {
					item.price_in_cents = (Number(value) || 0) * 100;
					item.price = value as number;
				} else if (field === "tb1") {
					item.tb1_in_cents = (Number(value) || 0) * 100;
					item.tb1 = value as number;
				} else if (field === "tb2") {
					item.tb2_in_cents = (Number(value) || 0) * 100;
					item.tb2 = value as number;
				} else if (field === "vat_in_percentage") {
					item.vat_in_percentage = Number(value) || 0;
				} else {
					item[field] = value as never;
				}

				return formatRowToWrite(item);
			});
		},
		[setArticle]
	);

	const handleFormatting = useCallback(() => {
		if (!form) return;
		onChange(formatRowToRead(form));
	}, [form, onChange]);

	const renderdColumns = useMemo(() => {
		return columns.map((column) => {
			if (column.handle === "article") {
				return (
					<div key={column.handle}>
						<SelectSearchField
							prefix={isLoadingArticle ? <Spinner size={"small"} /> : <Icon source={SearchMajor} />}
							// error={pricelist && !pricelist[column.handle] && "123"}
							onClear={() => handleChange("article")(null)}
							disabled={disabled}
							resource="articles.json"
							params={{
								contact_id: order?.customer_fields?.customer?.id || null,
								placing_order: true,
							}}
							onSelect={handleChange("article")}
							idHandle="id"
							labelHandle="title"
							resourceHandle="articles"
							resourceName={{ plural: t("articles.plural", "artiklar"), singular: t("articles.singular", "artikel") }}
							value={form?.article}
							renderLabel={(item) => `${item?.title} - ${item?.description}`}
							renderValue={(item) => {
								return `${item?.title || ""}`;
							}}
							// suffix={
							// 	!disabled && (
							// 		<span style={{ cursor: "pointer" }}>
							// 			<Icon source={ChevronDownMinor} />
							// 		</span>
							// 	)
							// }
						/>
					</div>
				);
			}

			if (["description"].includes(column.handle)) {
				return (
					<div key={column.handle}>
						<TextField value={String(form?.[column.handle] || "")} onChange={handleChange(column.handle)} disabled={disabled} />
					</div>
				);
			}

			if (["unit"].includes(column.handle)) {
				return (
					<div key={column.handle}>
						<TextField
							value={String(form?.[column.handle] || "")}
							onChange={handleChange(column.handle)}
							disabled={!form?.article || form?.article?.disable_unit_when_placing_order || disabled}
						/>
					</div>
				);
			}

			if (["discount"].includes(column.handle)) {
				return (
					<div key={column.handle}>
						<TextField
							value={String(form?.[column.handle] || "")}
							onChange={handleChange(column.handle)}
							suffix={form?.discount_type === "PERCENT" ? "%" : ""}
							disabled={!form?.article || form?.article?.disable_discount_when_placing_order || disabled}
							prefix={
								!disabled && (
									<Tooltip content={t("orders.row.fields.discount_type.label", "Rabatt typ")}>
										<Button
											disabled={!form?.article || form?.article?.disable_discount_when_placing_order}
											plain
											icon={ResetMinor}
											onClick={() => {
												if (row) {
													handleChange("discount_type")(form?.discount_type === "PERCENT" ? "AMOUNT" : "PERCENT");
												}
											}}
										/>
									</Tooltip>
								)
							}
							// value={String(parseFloat(String(form?.[column.handle])) / 100)}
							// onChange={(v) => handleChange(column.handle)(Number(v) * 100)}
						/>
					</div>
				);
			}

			if (["article_pricelist"].includes(column.handle)) {
				const options = [
					...(form?.article?.filtered_article_pricelists?.map((articlePricelist) => ({
						label: articlePricelist.pricelist.title,
						value: String(articlePricelist.id),
					})) || []),
				];

				return (
					<div key={column.handle}>
						<Select
							options={options}
							onChange={(priceListId) =>
								handleChange(column.handle)(
									form?.article?.filtered_article_pricelists?.find(
										(articlkePricelist) => String(articlkePricelist.id) === priceListId
									) as ArticlePricelistType
								)
							}
							value={String(form?.[column.handle]?.id)}
							disabled={!form?.article?.filtered_article_pricelists?.length || disabled}
						/>
					</div>
				);
			}

			if (["quantity"].includes(column.handle)) {
				return (
					<div key={column.handle}>
						<TextField
							required={!!form?.article}
							value={String(form?.[column.handle] || "")}
							onChange={handleChange(column.handle)}
							suffix={column.suffix}
							disabled={!form?.article || form?.article?.disable_quantity_when_placing_order || disabled}
						/>
					</div>
				);
			}

			if (["price", "vat_in_percentage"].includes(column.handle)) {
				return (
					<div key={column.handle}>
						<TextField
							value={String(form?.[column.handle] || "")}
							onChange={handleChange(column.handle)}
							suffix={column.suffix}
							disabled={!form?.article || form?.article?.disable_price_when_placing_order || disabled}
						/>
					</div>
				);
			}

			if (["tb1"].includes(column.handle)) {
				return (
					<div key={column.handle}>
						<TextField
							value={
								store.getState().account?.account_order_settings?.can_not_see_tb1_when_placing_order
									? "-"
									: String(getOrderRowTB(form)?.[column.handle] || "")
							}
							onChange={handleChange(column.handle)}
							suffix={column.suffix}
							disabled={
								!form?.article ||
								form?.article?.disable_tb2_when_placing_order ||
								store.getState().account?.account_order_settings?.can_not_see_tb1_when_placing_order ||
								disabled
							}
						/>
					</div>
				);
			}

			if (["tb2"].includes(column.handle)) {
				return (
					<div key={column.handle}>
						<TextField
							value={
								store.getState().account?.account_order_settings?.can_not_see_tb2_when_placing_order
									? "-"
									: String(getOrderRowTB(form)?.[column.handle] || "")
							}
							onChange={handleChange(column.handle)}
							suffix={column.suffix}
							disabled={
								!form?.article ||
								form?.article?.disable_tb2_when_placing_order ||
								store.getState().account?.account_order_settings?.can_not_see_tb2_when_placing_order ||
								disabled
							}
						/>
					</div>
				);
			}

			if (["actions"].includes(column.handle)) {
				return (
					<div key={column.handle}>
						<MyActionList
							disabled={disabled}
							items={[
								{
									content: t("common.actions.remove", "Ta bort") as string,
									destructive: true,
									icon: DeleteMajor,
									onAction: () => index !== undefined && onChange(null),
								},
							]}
						/>
					</div>
				);
			}
			if (["updated_at"].includes(column.handle)) {
				return <div key={column.handle}>{form?.[column.handle] && moment(form?.[column.handle] as string).format("YYYY-MM-DD HH:mm")}</div>;
			}

			if (["sum"].includes(column.handle) && form) {
				return (
					<div key={column.handle} style={{ display: "flex", alignItems: "center" }}>
						{numberFormatter({
							value: (getRowSum(form)?.sumExclVat || 0) / 100,
							minimumFractionDigits: 2,
						})}
					</div>
				);
			}

			return <div key={column.handle}>{form?.[column.handle]}</div>;
		});
	}, [columns, row, form, handleChange, index, onChange, t, disabled, order?.customer_fields?.customer?.id, isLoadingArticle]);

	return (
		<OrderRowWrapper onBlur={handleFormatting} {...rest} ref={innerRef as any}>
			<OrderRowMainInnerWrapper ref={ref}>
				<OrderRowInnerWrapper>
					{renderdColumns}

					{form && (
						<DropDown
							key={form.id || index}
							activatorStyle={
								!row || disabled
									? {
											opacity: 0,
											pointerEvents: "none",
									  }
									: {}
							}
						>
							<ActionList
								items={[
									{
										content: t("common.actions.remove", "Ta bort") as string,
										destructive: true,
										icon: DeleteMajor,
										onAction: () => index !== undefined && onChange(null),
									},
								]}
							/>
						</DropDown>
					)}

					{form && (
						<div>
							<Icon source={DragHandleMinor} />
						</div>
					)}
				</OrderRowInnerWrapper>
				{!!form?.custom_fields?.length && (
					<OrderRowCostomFieldsWrapper>
						{form?.custom_fields
							?.map((orderCustomField, index) => (
								<CustomFieldValueComponent
									key={orderCustomField.id}
									customField={orderCustomField.custom_field}
									value={String(orderCustomField?.value || "")}
									onChange={handleChangeCustomFieldValue(index, orderCustomField.custom_field)}
									disabled={!!(orderCustomField.custom_field.can_not_edit_when_placing_order || disabled)}
								/>
							))
							.filter(Boolean)}
					</OrderRowCostomFieldsWrapper>
				)}
			</OrderRowMainInnerWrapper>
		</OrderRowWrapper>
	);
};
export default withTranslation(["orders", "common"])(OrderRow);

export const OrderRowWrapper = styled.div``;

export const OrderRowMainInnerWrapper = styled.div`
	display: grid;
	gap: 1rem;
`;
export const OrderRowInnerWrapper = styled.div`
	&&& {
		.Polaris-Select {
			width: 100%;
		}

		.Polaris-TextField {
			.Polaris-Button,
			.Polaris-Spinner {
				display: flex;
			}
		}
	}
`;
export const OrderRowCostomFieldsWrapper = styled.div`
	display: flex;
	gap: 1rem;
	overflow: auto;
	padding-bottom: 3rem;

	> div {
		&,
		.Polaris-Select {
			/* flex: 1; */
			min-width: 200px;
		}
	}
`;
