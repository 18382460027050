import React, { useState, useCallback, useMemo, useEffect, useRef } from "react";
import { Listbox, Combobox, Icon, Tag, Spinner } from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
import axios from "axios";
import debounce from "lodash/debounce";
import { StyledComboBoxWrapper, StyledComboBoxTagsWrapper } from "./styles.js";
import API from "../../API";
import { store } from "../../store/index.js";
import { toastr } from "../toastr.js";

const ComboboxFilter = ({ limit = 50, onSelect, autoFocus = false, ...props }: any) => {
	const deselectedOptions = useMemo(() => props.options || [], [props.options]);

	const [selectedOption, setSelectedOption] = useState<string | undefined | any>(props.value);
	const [inputValue, setInputValue] = useState("");
	const [loading, setLoading] = useState(false);
	const [canOpen, setCanOpen] = useState(false);
	const [options, setOptions] = useState(deselectedOptions);
	const ref: any = useRef();
	const controller = useRef(new AbortController());

	const handleSearch = debounce(
		(query = inputValue) => {
			controller.current.abort();
			controller.current = new AbortController();

			setLoading(true);
			const params = Object.assign({}, props.params, store.getState().authentication.defaultParams);
			params.q = query;
			params.offset = 0;
			params.limit = limit;

			API.get("/api/" + props.resource + (props.resource.indexOf(".json") >= 0 ? "" : ".json"), {
				signal: controller.current?.signal,
				params,
			})
				.then((result) => {
					const resourceHandle = props.resource_handle;

					setOptions(
						result.data?.[resourceHandle]?.map((opt: { [key: string]: any }) => ({
							label: opt[props.label_handle],
							value: props.id_handle ? opt[props.id_handle] : opt,
						}))
					);

					setCanOpen(true);
					setLoading(false);
					const input = ref.current?.querySelector("input");
					if (input) {
						input.blur();

						requestAnimationFrame(() => {
							input.focus();
						});
					}
				})
				.catch((error) => {
					if (axios.isCancel(error)) {
						// eslint-disable-next-line no-console
						console.debug("Request canceled");
					} else {
						toastr.error(error);
						setLoading(false);
					}
				});
		},
		250,
		{ maxWait: 2000 }
	);

	useEffect(() => {
		setOptions(deselectedOptions);
	}, [deselectedOptions]);

	useEffect(() => {
		setSelectedOption(props.value);
	}, [props.value]);

	useEffect(() => {
		const input = ref.current?.querySelector("input");
		const onCollapse = (transition: any) => {
			const expanded = JSON.parse(transition.target.getAttribute("aria-expanded"));

			if (
				transition.type === "transitionend" &&
				(transition.propertyName === "max-height" || transition.propertyName === "transform") &&
				!canOpen &&
				(expanded ?? true)
			) {
				setCanOpen(true);
				requestAnimationFrame(() => {
					input.blur();
					input.focus();
				});
			}
		};

		const collapsible =
			ref.current.closest(".Polaris-Filters__FilterTriggerContainer") ||
			ref.current.closest(".Polaris-PositionedOverlay.Polaris-Popover__PopoverOverlay");

		if (!props.value && collapsible) {
			collapsible?.addEventListener("transitionend", onCollapse);
		} else {
			setCanOpen(true);
			if (autoFocus) input.focus();
		}

		return () => {
			collapsible?.removeEventListener("transitionend", onCollapse);
			// setCanOpen(false);
			// input?.blur();
		};
	}, [canOpen, props.value, autoFocus]);

	useEffect(() => {
		setSelectedOption(props.value);
	}, [props.value]);

	const updateText = useCallback(
		(value: string) => {
			setCanOpen(true);
			setInputValue(value);
			// controller.current.abort();

			if (props.resource && !props.options?.length) {
				handleSearch(value);
				return;
			}

			if (value === "") {
				setOptions(deselectedOptions);
				return;
			}

			const filterRegex = new RegExp(value, "i");
			const resultOptions = deselectedOptions.filter((option: any) => String(option.label).match(filterRegex));
			setOptions(resultOptions);
		},
		[deselectedOptions, props.resource, props.options, handleSearch]
	);

	const updateSelection = useCallback(
		(selected: string | any) => {
			if (typeof selected === "string") {
				const matchedOption = options.find((option: any) => {
					return String(option.value) === String(selected);
				});

				setSelectedOption(selected);
				setInputValue((matchedOption && matchedOption.label) || "");
			} else {
				setInputValue("");
			}

			onSelect(selected);
		},
		[options, onSelect]
	);

	const optionsMarkup =
		options.length > 0
			? options.map((option: any) => {
					const { label, value } = option;

					return (
						<Listbox.Option
							key={`${value?.id || (typeof value === "string" || typeof value === "number" ? value : label)}`}
							value={value}
							selected={selectedOption === value}
							accessibilityLabel={label}
						>
							{label}
						</Listbox.Option>
					);
			  })
			: null;

	return (
		<StyledComboBoxWrapper ref={ref}>
			<Combobox
				activator={
					<Combobox.TextField
						prefix={loading ? <Spinner size="small" /> : <Icon key="search" source={SearchMinor} />}
						// suffix={loading && <Spinner size="small" />}
						onChange={updateText}
						label={props.label || props.resourceName?.plural}
						labelHidden
						value={inputValue}
						placeholder={props.placeholder || props.resourceName?.plural}
						autoComplete="off"
						autoFocus={autoFocus}
						// focused={false}
						onFocus={() => {
							if (props.resource && !canOpen) handleSearch();
							setCanOpen(true);
						}}
					/>
				}
			>
				{!!options?.length && canOpen ? (
					<Listbox enableKeyboardControl onSelect={updateSelection}>
						{optionsMarkup}
					</Listbox>
				) : null}
			</Combobox>
			<StyledComboBoxTagsWrapper>
				{props.value &&
					[props.value].flat()?.map((value, index) => {
						const option = options?.find((opt: any) => String(opt.value) === String(value));
						const label = option?.label || value?.title || value?.name || (typeof value !== "object" ? value : null);

						return (
							<Tag
								key={index}
								onRemove={() => {
									props.onRemove(index);
								}}
							>
								{label}
							</Tag>
						);
					})}
			</StyledComboBoxTagsWrapper>
		</StyledComboBoxWrapper>
	);
};

export default ComboboxFilter;
