import i18next from "i18next";
import { store } from "src/js/store";
import getUserOptions from "./user";
import { uniqBy } from "lodash";

const orderRowFields = [
	{ label: i18next.t("order.fields.order_rows.article_number.label", "Artikelnummer"), value: "article_number" },
	{ label: i18next.t("order.fields.order_rows.description.label", "Beskrivning"), value: "description" },
	{ label: i18next.t("order.fields.order_rows.quantity.label", "Kvantitet"), value: "quantity" },
	{ label: i18next.t("order.fields.order_rows.unit.label", "Enhet"), value: "unit" },

	{
		label: i18next.t("order.fields.order_rows.price_formatted.label", "A-pris"),

		value: "price_formatted",
	},
	{
		label: i18next.t("order.fields.order_rows.total_price_excluding_vat_formatted.label", "Totalt exkl moms"),

		value: "total_price_excluding_vat_formatted",
	},
	{
		label: i18next.t("order.fields.order_rows.tb1_formatted.label", "TB1"),

		value: "tb1_formatted",
	},
	{
		label: i18next.t("order.fields.order_rows.tb1_formatted.label", "TB2"),

		value: "tb1_formatted",
	},
	{
		label: i18next.t("order.fields.order_rows.total_vat_formatted.label", "Moms"),

		value: "total_vat_formatted",
	},
	{ label: i18next.t("order.fields.order_rows.vat_in_percentage_formatted.label", "Moms %"), value: "vat_in_percentage_formatted" },

	{
		label: i18next.t("order.fields.order_rows.discount.label", "Rabatt"),

		value: "discount_formatted",
	},

	...uniqBy(store.getState().custom_fields, "handle").map((field: any) => ({
		label: field.title,
		value: `custom_fields_by_handle.${field.handle}`,
	})),
];

const getOptions = () => {
	return {
		label: i18next.t("order.label", "Order"),
		value: "order",
		options: [
			{ label: i18next.t("order.fields.id.label", "Id"), value: "order.id" },
			{ label: i18next.t("order.fields.id.order_number", "Order Nr"), value: "order.order_number" },

			{
				label: i18next.t("order.fields.id.total_price_excluding_vat_formatted", "Totalt exkl moms"),
				value: "order.total_price_excluding_vat_formatted",
			},
			{
				label: i18next.t("order.fields.id.total_vat_formatted", "Total moms"),
				value: "order.total_vat_formatted",
			},
			{
				label: i18next.t("order.fields.id.total_price_including_vat_formatted", "Totalt inkl moms"),
				value: "order.total_price_including_vat_formatted",
			},

			{ label: i18next.t("order.fields.id.your_order_number", "Ert egna ordernr"), value: "order.your_order_number" },
			{ label: i18next.t("order.fields.id.your_reference", "Er reference"), value: "order.your_reference" },
			{ label: i18next.t("order.fields.id.our_reference", "Vår reference"), value: "order.our_reference" },
			{ label: i18next.t("order.fields.id.sent_date", "Skickat datum"), value: "order.sent_date" },
			{ label: i18next.t("order.fields.id.order_date", "Order datum"), value: "order.order_date" },
			{ label: i18next.t("order.fields.id.created_at", "Skapad datum"), value: "order.created_at" },

			{ label: i18next.t("order.fields.id.user", "Skapad av användaren"), value: "order.created_by_user", options: getUserOptions().options },

			{
				label: i18next.t("order.customer_fields.label", "Kundfält"),
				value: "order.customer_fields",
				options: [
					{
						label: i18next.t("order.fields.customer_fields.name.label", "Namn"),
						value: "order.customer_fields.name",
					},
					{
						label: i18next.t("order.fields.customer_fields.organisation_number.label", "Organisationsnummer"),
						value: "order.customer_fields.organisation_number",
					},
					{
						label: i18next.t("order.fields.customer_fields.address.label", "Levaransadress"),
						value: "order.customer_fields.address",
					},
					{
						label: i18next.t("order.fields.customer_fields.address2.label", "Levaransadress 2"),
						value: "order.customer_fields.address2",
					},
					{
						label: i18next.t("order.fields.customer_fields.city.label", "Stad"),
						value: "order.customer_fields.city",
					},
					{
						label: i18next.t("order.fields.customer_fields.postal_code.label", "Postnummer"),
						value: "order.customer_fields.postal_code",
					},
					{
						label: i18next.t("order.fields.customer_fields.country.label", "Land"),
						value: "order.customer_fields.country",
					},
					{
						label: i18next.t("order.fields.customer_fields.phone.label", "Telefonnummer"),
						value: "order.customer_fields.phone",
					},
					{
						label: i18next.t("order.fields.customer_fields.email.label", "E-post"),
						value: "order.customer_fields.email",
					},
				],
			},

			{
				label: i18next.t("order.delivery_fields.label", "Leveransfält"),
				value: "order.delivery_fields",
				options: [
					{
						label: i18next.t("order.fields.delivery_fields.name.label", "Namn"),
						value: "order.delivery_fields.name",
					},
					{
						label: i18next.t("order.fields.delivery_fields.address.label", "Levaransadress"),
						value: "order.delivery_fields.address",
					},
					{
						label: i18next.t("order.fields.delivery_fields.address2.label", "Levaransadress 2"),
						value: "order.delivery_fields.address2",
					},
					{
						label: i18next.t("order.fields.delivery_fields.city.label", "Stad"),
						value: "order.delivery_fields.city",
					},
					{
						label: i18next.t("order.fields.delivery_fields.postal_code.label", "Postnummer"),
						value: "order.delivery_fields.postal_code",
					},
					{
						label: i18next.t("order.fields.delivery_fields.country.label", "Land"),
						value: "order.delivery_fields.country",
					},
					{
						label: i18next.t("order.fields.delivery_fields.delivery_type.label", "Leveranssätt"),
						value: "order.delivery_fields.delivery_type",
					},
					{
						label: i18next.t("order.fields.delivery_fields.delivery_date.label", "Leveransdatum"),
						value: "order.delivery_fields.delivery_date",
					},
				],
			},

			{
				label: i18next.t("order.fields.order_rows.label", "Rader"),
				value: "order.order_rows",
				type: "array",
				options: orderRowFields,
			},
			{
				label: i18next.t("order.fields.order_rows_by_category.label", "Rader per kategori"),
				value: "order.order_rows_by_category",
				options: uniqBy(store.getState().article_categories, "handle").map((category: any) => ({
					label: category.title,
					value: `order.order_rows_by_category.${category.handle}.order_rows`,
					type: "array",
					options: orderRowFields,
				})),
			},
			{
				label: i18next.t("order.fields.order_statistics.label", "Order statistik"),
				value: "order.order_statistics",
				options: [
					{
						label: i18next.t("order.fields.order_statistics.categories_totals.label", "Kategorier totals"),
						value: "order.order_statistics.categories_totals",
						type: "array",
						options: [
							{
								label: i18next.t("order.fields.order_statistics.categories_totals.category.label", "Kategori"),
								value: "label",
							},
							{
								label: i18next.t("order.fields.order_statistics.categories_totals.total.label", "Total"),
								value: "value",
							},
						],
					},
					...uniqBy(store.getState().article_categories, "handle").map((field: any) => ({
						label: `${field.title} total`,
						value: `order.order_statistics.categories_by_handle.${field.handle}`,
					})),
				],
			},
		],
	};
};

export default getOptions;
