/* eslint-disable max-classes-per-file */
import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
	AnalyticsMajor,
	AppsMajor,
	ChecklistAlternateMajor,
	SettingsMajor,
	CustomerPlusMajor,
	EmailNewsletterMajor,
	DesktopMajor,
	ProfileMajor,
	CircleChevronRightMinor,
	LogOutMinor,
	ProductsMajor,
	CodeMajor,
	BalanceMajor,
} from "@shopify/polaris-icons";
import { Icon, Navigation } from "@shopify/polaris";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import WorkspaceNavigation from "./WorkspaceNavigation.js";
import { getIconByString } from "../views/Workspaces/components/IconSelector.js";
import { store } from "../store";
import IntersectionObserverElement from "./IntersectionObserverElement.js";
import MainMenuSection from "./MainMenuSection.js";
import DollarIcon from "../icons/DollarIcon.js";
import CogWheelIcon from "../icons/CogWheelIcon.js";
import AppsIcon from "../icons/AppsIcon.js";
import SupportIcon from "../icons/SupportIcon.js";
import MarketingIcon from "../icons/MarketingIcon.js";
import IntegrationsIcon2 from "../icons/IntegrationsIcon2.js";
import MainMenuSectionSearch from "./MainMenuSectionSearch";
import MainSectionFavorites from "./MainSectionFavorites.js";
import CompanyIcon from "../icons/CompanyIcon.js";
import IntegrationModal from "./IntegrationModal.js";
import WorkspaceModal from "./WorkspaceModal.js";
import BoardTemplateModal from "./BoardTemplateModal.js";
import BoardHelper from "../views/Workspaces/BoardHelper.js";
import "../redirectToMobileStore.js";
import LogoIcon from "../icons/LogoIcon.js";
import CallListIcon from "../icons/CallListIcon.js";
import ContactPeopleIcon from "../icons/ContactPeopleIcon.js";
import BoardMembers from "../views/Workspaces/components/BoardMembers.js";
import People3Icon from "../icons/People3Icon.js";
import TargetArrowIcon3 from "../icons/TargetArrowIcon3.js";

class MainMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.ref = React.createRef();
	}

	componentDidMount() {}

	closeDrawerAndDeselct() {
		document.querySelector("body").classList.remove("nav-active");
		document.querySelector("body").classList.remove("nav-selected");
		const elements = Array.from(document.querySelectorAll(".mainmenu-item"));
		elements.forEach((e) => e.classList.remove("active"));
	}

	addAnimating(id) {
		if (id) {
			document.querySelector(`#${id}`).classList.add("animating");

			clearTimeout(this.animatingTimer);
			this.animatingTimer = setTimeout(() => {
				document.querySelector(`#${id}`).classList.remove("animating");
			}, 250);
		} else {
			const activeEle = document.querySelector(".mainmenu-item.active");
			if (activeEle) activeEle.classList.add("animating");
			clearTimeout(this.animatingTimer);
			setTimeout(() => {
				const animatingEle = document.querySelector(".mainmenu-item.animating");
				if (animatingEle) animatingEle.classList.remove("animating");
			}, 250);
		}
	}

	onSelect({ id, url } = {}, workspace) {
		document.querySelector("#AppFrameNav").classList.remove("have_active");
		if (url && url === this.props.location.pathname) {
			this.closeDrawerAndDeselct();
			return null;
		}

		if (url) {
			this.addAnimating();
			this.closeDrawerAndDeselct();
			// this.props.history.push(url);

			// Open menu even if main button is a link
			if (!workspace || (workspace && workspace.title != "Servicedesk")) {
				return;
			}
		}

		// if (workspace && workspace.title == "Servicedesk" && workspace.boards.length == 1) {
		// 	this.props.history.push("/admin/workspaces/" + workspace.id + "/boards/" + workspace.boards[0].id);
		// }

		this.addAnimating();

		if (document.querySelector(`#${id}`).classList.contains("active") && document.querySelector("body").classList.contains("nav-active")) {
			cancelAnimationFrame(this.animationFrame);
			this.animationFrame = requestAnimationFrame(() => {
				document.querySelector("body").classList.remove("nav-active");
				document.querySelector("body").classList.remove("nav-selected");
				document.querySelector(`#${id}`).classList.remove("active");
			});
			return;
		}

		document.querySelector("body").classList.add("nav-active");
		document.querySelector("body").classList.add("nav-selected");
		const elements = Array.from(document.querySelectorAll(".mainmenu-item"));
		elements.forEach((e) => e.classList.remove("active"));

		cancelAnimationFrame(this.animationFrame);
		this.animationFrame = requestAnimationFrame(() => {
			document.querySelector(`#${id}`).classList.add("active");
			document.querySelector("#AppFrameNav").classList.add("have_active");
		});
	}

	toggleMenu(e) {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		this.addAnimating();
		document.querySelector("body").classList.toggle("nav-active");
	}

	openModal(name, parentWorkspace) {
		this.setState({ [`${name}_modal`]: true, parentWorkspace });
	}

	closeModal(name) {
		this.setState({ [`${name}_modal`]: false, parentWorkspace: null });
	}

	onUpdateWorkspace(workspace) {
		store.dispatch({ type: "UPDATE_WORKSPACE", workspace });
		BoardHelper.fetchWorkSpaces();
	}

	render() {
		const integrationsWithFullscreen = [];

		if (store.getState().account.integrations) {
			store.getState().account.integrations.forEach((integration) => {
				if (
					(integration.fullscreen_url || integration.type != "web") &&
					store.getState().user.roles.indexOf("ROLE_INTEGRATION_" + integration.id) >= 0
				) {
					integrationsWithFullscreen.push(integration);
				}
			});
		}

		const marketingItems = [];

		if (store.getState().user.roles.indexOf("ROLE_MARKETING_EMAIL_CAMPAIGNS") >= 0) {
			marketingItems.push({
				label: this.props.t("mainmenu.section.marketing.items.email_campaigns", "E-postkampanjer"),
				icon: EmailNewsletterMajor,
				url: "/admin/email_campaigns",
			});
			/*
			marketingItems.push({
				label: this.props.t("mainmenu.section.marketing.items.automations", "Automatiseringar"),
				icon: EmailNewsletterMajor,
				url: "/admin/automations",
			});
			marketingItems.push({
				label: this.props.t("mainmenu.section.marketing.items.conversions", "Konverteringar"),
				icon: EmailNewsletterMajor,
				url: "/admin/conversions",
			});
			*/
		}

		/*
		marketingItems.push({
			label: "E-postkontakter",
			icon: CustomersMajor,
			url: "/admin/email_contacts",
		});
		*/

		marketingItems.push({
			label: this.props.t("mainmenu.section.marketing.items.audiences", "Målgrupper"),
			icon: CustomerPlusMajor,
			url: "/admin/audiences",
		});

		if (store.getState().account.visitor_board_id) {
			marketingItems.push({
				label: this.props.t("mainmenu.section.marketing.items.visitors", "Besökare"),
				icon: DesktopMajor,
				url: "/admin/workspaces/none/boards/" + store.getState().account.visitor_board_id,
			});
		}

		/*
		if (store.getState().user.roles.indexOf("ROLE_MARKETING_ADS") >= 0) {
			marketingItems.push({
				label: this.props.t("mainmenu.section.marketing.items.ads", "Annonsering"),
				icon: SocialAdMajor,
				url: "/admin/ads",
			});
		}
		*/

		const integrationsLinks = integrationsWithFullscreen
			?.filter((integration) => {
				return integration.integration_id !== "fortnox";
			})
			.map((integration) => {
				if (integration.type != "web") {
					return {
						label: integration.title,
						icon: (() => {
							if (integration.title === "Scrive") return <img src="/assets/scrive.png" alt="scrive" />;
							if (integration.title?.toLowerCase().includes("fortnox")) return <img src="/assets/fortnox-logo.png" alt="Fortnox" />;
							return AppsMajor;
						})(),
						url: `/admin/integrations/${integration.id}/${Math.random()}`,
						activeUrl: `/admin/integrations/${integration.id}`,
						badge: integration.badge_count ? integration.badge_count + "" : null,
					};
				}
				return {
					label: integration.title,
					icon: (() => {
						if (integration.title === "Scrive") return <img src="/assets/scrive.png" alt="scrive" />;
						if (integration.title?.toLowerCase().includes("fortnox")) return <img src="/assets/fortnox-logo.png" alt="Fortnox" />;
						return AppsMajor;
					})(),
					url: `/admin/integrations/${integration.id}/${Math.random()}`,
					activeUrl: `/admin/integrations/${integration.id}`,
					badge: integration.badge_count ? integration.badge_count + "" : null,
				};
			});

		const integrationsItems = [
			{
				icon: <img src="/assets/scrive.png" alt="scrive" />,
				label: "Scrive",
				url: "/admin/scrive/documents",
				closeOnClick: true,
			},
			{
				icon: <img src="/assets/fortnox-logo.png" alt="Fortnox" />,
				label: "Fortnox",
				url: "/admin/fortnox/settings",
				closeOnClick: true,
				enabled: !!store.getState().account.fortnox_integration,
			},
			...integrationsLinks,
		].filter((i) => !("enabled" in i) || i.enabled);

		const forsaljning = store.getState().workspaces && store.getState().workspaces.find((i) => i.title === "Försäljning");

		const forsaljningItems =
			forsaljning &&
			forsaljning.boards.map((b) => ({
				label: b.title,
				icon: getIconByString(b.icon),
				url: `/admin/workspaces/${b.workspace_id || forsaljning.id}/boards/${b.id}`,
			}));
		if (forsaljning && forsaljning.dashboards) {
			forsaljning.dashboards.forEach((d) => {
				forsaljningItems.unshift({
					label: d.title,
					icon: getIconByString(d.icon),
					url: `/admin/dashboard/${d.id}`,
				});
			});
		}

		const adminItems = [
			{
				id: "users",
				label: this.props.t("mainmenu.section.admin.items.users", "Användare", { count: 0 }),
				icon: ProfileMajor,
				url: "/admin/users?enabled=1",
				closeOnClick: true,
			},
			{
				id: "groups",
				label: this.props.t("mainmenu.section.admin.items.groups", "Grupper", { count: 0 }),
				icon: People3Icon,
				url: "/admin/groups",
				closeOnClick: true,
			},
			{
				id: "licenses",
				label: this.props.t("mainmenu.section.admin.items.licenses", "Licenser"),
				icon: BalanceMajor,
				url: "/admin/licenses",
				closeOnClick: true,
			},
			// {
			// 	label: "Grupper",
			// 	icon: TeamMajor,
			// 	url: "/admin/groups",
			// },
			{
				id: "targets",
				label: this.props.t("mainmenu.section.admin.items.tagets.title", "Målsättningar"),
				icon: <TargetArrowIcon3 />,
				url: "/admin/targets",
				enabled: store.getState().user.roles.includes("ROLE_ADMIN"),
			},
			{
				id: "admin-goals",
				label: this.props.t("mainmenu.section.admin.items.goals_old", "Målsättningar (utgående)"),
				icon: AnalyticsMajor,
				url: "/admin/goals",
				closeOnClick: true,
			},
			{
				id: "pdfs",
				label: this.props.t("mainmenu.section.admin.items.", "PDF Mallar"),
				icon: ChecklistAlternateMajor,
				url: "/admin/pdf_templates",
				closeOnClick: true,
			},
			{
				id: "tags",
				label: this.props.t("mainmenu.section.admin.items.tags", "Taggar"),
				icon: ProductsMajor,
				url: "/admin/tags",
				closeOnClick: true,
			},

			{
				id: "articles",
				label: this.props.t("mainmenu.section.admin.items.article_register", "Artikelregister"),
				icon: ProductsMajor,
				url: "/admin/articles/settings",
				closeOnClick: true,
				//! TEMP FOR TESTING
				enabled: [1514, 24, 1566].includes(store.getState().account?.id) || [2, 3, 419].includes(store.getState().user?.id),
			},

			{
				id: "user_activity",
				label: this.props.t("mainmenu.section.admin.items.overview", "Användare aktivitet"),
				icon: SettingsMajor,
				url: "/admin/users_activity",
				closeOnClick: true,
				enabled: store.getState().user?.roles?.some((role) => ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"].includes(role)),
			},
			{
				id: "api_user_tokens",
				label: this.props.t("mainmenu.section.api_user_tokens.items.settings", "Användar API nycklar"),
				icon: SettingsMajor,
				url: "/admin/api_user_tokens",
				closeOnClick: true,
				enabled: store.getState().account.api_enabled && store.getState().user.roles.includes("ROLE_ADMIN"),
			},
			{
				id: "settings",
				label: this.props.t("mainmenu.section.admin.items.settings", "Inställningar"),
				icon: SettingsMajor,
				url: "/admin/settings",
				closeOnClick: true,
			},
			{
				id: "logout",
				label: this.props.t("mainmenu.section.admin.items.logout", "Logga ut"),
				icon: LogOutMinor,
				onClick: () => {
					window.top.location.href = "/admin/authentication/login";
				},
			},
		].filter((i) => !("enabled" in i) || i.enabled);

		if (["419", "2", "418"].includes(String(store.getState().user.id))) {
			adminItems.push({
				id: "components",
				label: "Components overview",
				icon: CodeMajor,
				url: "/admin/components",
				closeOnClick: true,
			});
		}

		const sections = [
			{
				id: "logo",
				icon: <LogoIcon />,
				url: "/admin/dashboard/mine",
				className: "logo",
			},
			{
				id: "companies",
				title: this.props.t("mainmenu.section.companies.title", "Företag"),
				icon: <CompanyIcon />,
				url: "/admin/contacts/companies",
			},
			{
				id: "contacts",
				title: this.props.t("mainmenu.section.people.title", "Kontakter"),
				icon: <ContactPeopleIcon />,
				url: "/admin/contacts/people",
			},
			// {
			// 	title: "Favoriter",
			// 	icon: <FavoriteBannerIcon />,
			// 	items: favoriteBoardItems,
			// },
			{
				id: "deals",
				title: this.props.t("mainmenu.section.sales.title", "Affärer"),
				icon: <DollarIcon />,
				url: `/admin/workspaces/${"none"}/boards/${store.getState().account.sales_board_id}`,
				// children: (() => {
				// 	const workspace = store.getState().workspaces.find((w) => w.title === "Försäljning");
				// 	if (!workspace) return null;
				// 	return (
				// 		<MainMenuWorkspaceNavigation
				// 			depth={-1}
				// 			id={`${workspace.handle}-workspace`}
				// 			location={this.props.location}
				// 			// onCreate={this.fetch.bind(this, true)}
				// 			key={workspace.handle}
				// 			workspace={workspace}
				// 			history={this.props.history}
				// 			showSearch
				// 			actions={[
				// 				{
				// 					content: "Ny board",
				// 					onAction: this.openModal.bind(this, "board", workspace),
				// 				},
				// 				{
				// 					content: "Ny mapp",
				// 					onAction: this.openModal.bind(this, "workspace", workspace),
				// 				},
				// 			]}
				// 		/>
				// 	);
				// })(),
				enabled: (() =>
					store.getState().account.sales_board_id &&
					store.getState().workspaces &&
					store.getState().workspaces.find((w) => w.title === "Försäljning"))(),
			},
			{
				id: "call_lists",
				title: this.props.t("mainmenu.section.calllists.title", "Ringlistor"),
				icon: <CallListIcon />,
				url: "/admin/call_lists",
				enabled: (() =>
					store.getState().account.sales_board_id &&
					store.getState().workspaces &&
					store.getState().workspaces.find((w) => w.title === "Försäljning"))(),
			},
			{
				id: "marketing",
				title: this.props.t("mainmenu.section.marketing.title", "Marknadsföring"),
				icon: <MarketingIcon />,
				items: marketingItems,
				enabled: store.getState().user.roles.includes("ROLE_MARKETING"),
				secondaryItemsStyle: { marginTop: "-0.4688rem" },
			},

			{
				id: "support",
				title: this.props.t("mainmenu.section.support.title", "Servicedesk"),
				icon: <SupportIcon />,
				workspace: store.getState().workspaces && store.getState().workspaces.find((w) => w.title === "Servicedesk"),
				// items: kundtjanstItems,
				url: (() => {
					// if there are only 1 board, link directly to the board instead of opening the menu
					const workspace = store.getState().workspaces && store.getState().workspaces.find((w) => w.title === "Servicedesk");
					if (workspace && workspace.title == "Servicedesk" && workspace.boards.length == 1) {
						return "/admin/workspaces/" + workspace.id + "/boards/" + workspace.boards[0].id;
					}
				})(),
				children: (() => {
					const workspace = store.getState().workspaces && store.getState().workspaces.find((w) => w.title === "Servicedesk");
					if (!workspace) return null;

					return (
						<MainMenuWorkspaceNavigation
							depth={-1}
							id={`${workspace.handle}-workspace`}
							location={this.props.location}
							// onCreate={this.fetch.bind(this, true)}
							key={workspace.handle}
							workspace={workspace}
							history={this.props.history}
							showSearch
							actions={[
								{
									content: this.props.t("mainmenu.section.support.actions.new_queue", "Ny kö"),
									onAction: this.openModal.bind(this, "support"),
								},
							]}
						/>
					);
				})(),

				enabled:
					store.getState().workspaces && store.getState().workspaces.find((w) => w.title === "Servicedesk")
						? store
								.getState()
								.user.roles.includes(
									"ROLE_WORKSPACE_" + (store.getState().workspaces && store.getState().workspaces.find((w) => w.title === "Servicedesk").id)
								)
						: store.getState().user.roles.includes("ROLE_SUPPORT"),
			},
			{
				id: "workspaces",
				title: this.props.t("mainmenu.section.workspaces.title", "Workspaces"),
				icon: <AppsIcon />,
				children: (
					<WorkspaceNavigation
						location={this.props.location}
						history={this.props.history}
						actions={[
							{
								content: this.props.t("mainmenu.section.admin.actions.new_projekt_folder", "Nytt projekt"),
								onAction: this.openModal.bind(this, "workspace"),
							},
						]}
					/>
				),
			},
			{
				id: "targets",
				title: this.props.t("mainmenu.section.targets.title", "Målsättningar"),
				icon: <TargetArrowIcon3 />,
				url: "/admin/dashboard/targets",
				enabled: store.getState().user.roles.includes("ROLE_ADMIN"),
			},
			{
				id: "integrations",
				title: this.props.t("mainmenu.section.integrations.title", "Integrationer"),
				icon: <IntegrationsIcon2 />,
				items: integrationsItems,
				actions: [
					{
						content: this.props.t("mainmenu.section.admin.actions.new", "Ny integration"),
						onAction: this.openModal.bind(this, "integration"),
					},
				],
				secondaryItemsStyle: { marginTop: "-0.4688rem" },
				children: !integrationsItems.length && (
					<p>{this.props.t("mainmenu.integrations.empty", "Kontakta Sellfinity för assistans med att koppla igång dina integrationer")}</p>
				),

				// enabled: !!integrationsItems.length,
			},
		].filter((i) => i && (i.component || !("enabled" in i) || i.enabled));

		const bottomSections = [
			{
				title: this.props.t("mainmenu.section.admin.title", "Admin"),
				icon: <CogWheelIcon />,
				items: adminItems?.filter((i) => !("enabled" in i) || i.enabled),
				enabled: !!store.getState().user.roles.includes("ROLE_ADMIN"),
			},
		];

		const navigationMarkup = (
			<Navigation location={this.props.location.pathname}>
				<div ref={this.ref} className="navigation-sidebar">
					<div className="menu-items noscroll">
						<div>
							{/* <div className={`mainmenu-item ${this.props.location.pathname.indexOf("admin/dashboard/mine") >= 0 ? "url-active" : ""}`}>
								<Link
									className="mainmenu-item-trigger"
									to="/admin/dashboard/mine"
									onClick={this.onSelect.bind(this, { url: "/admin/dashboard/mine" })}
								>
									<LogoIcon />
									<img className="navigation-sidebar__logo" src="/assets/sellfinity/sellfinity-main-menu-c.png" />
								</Link>
							</div> */}
							{sections.map((section, key) => {
								const id = section.id || section.title;
								const className = `${section.url && this.props.location.pathname.indexOf(section.url) >= 0 ? "url-active" : ""} ${section.className}`;

								return (
									<MainMenuSection
										key={id}
										id={id}
										actions={section.actions}
										title={section.title}
										url={section.url}
										icon={section.icon}
										items={section.items}
										className={className}
										history={this.props.history}
										location={this.props.location}
										onClick={this.onSelect.bind(this, { id, url: section.url }, section.workspace)}
										toggleMenu={this.toggleMenu.bind(this)}
									>
										{section.children}
									</MainMenuSection>
								);
							})}
						</div>
						{bottomSections
							.filter((section) => !("enabled" in section) || section.enabled)
							.map((section, key) => {
								// if (section.component) return section.component;
								const id = section.id || section.title;
								return (
									<MainMenuSection
										key={id}
										id={id}
										actions={section.actions}
										title={section.title}
										url={section.url}
										icon={section.icon}
										items={section.items}
										history={this.props.history}
										location={this.props.location}
										onClick={this.onSelect.bind(this, { id, url: section.url })}
										toggleMenu={this.toggleMenu.bind(this)}
									>
										{section.children}
									</MainMenuSection>
								);
							})}
					</div>
					<IntersectionObserverElement
						hideShadow
						onChange={(intersecting) => {
							// const shadow = this.ref.current.closest("bottom-scroll-shadow");
							const shadow = document.querySelector("#mainmenu-shadow");
							if (shadow) {
								if (intersecting) {
									shadow.classList.remove("visible");
									shadow.classList.add("invisible");
								} else {
									shadow.classList.remove("invisible");
									shadow.classList.add("visible");
								}
							}
						}}
						parent={this.ref.current}
						shadowStyle={{ width: this.ref.current && this.ref.current.clientWidth }}
					/>
				</div>
			</Navigation>
		);

		const modals = (
			<React.Fragment>
				<IntegrationModal
					open={this.state.integration_modal}
					type="all"
					onCreate={(type) => {}}
					onClose={this.closeModal.bind(this, "integration")}
				/>

				<WorkspaceModal
					resourceName={{
						singular: this.props.t("mainmenu.sections.workspace.singular", "projekt"),
						plural: this.props.t("mainmenu.sections.workspace.plural", "projekt"),
					}}
					open={this.state.workspace_modal}
					parent={this.state.parentWorkspace || {}}
					onCreate={(workspace) => {
						BoardHelper.fetchWorkSpaces();
						this.closeModal("workspace");
						if (workspace.type === "shareable") {
							this.setState({ showMembersModal: true, workspace });
						}
					}}
					onClose={this.closeModal.bind(this, "workspace")}
				/>

				{this.state.showMembersModal && (
					<BoardMembers
						hideTrigger
						show={this.state.showMembersModal}
						onClose={() => this.setState({ showMembersModal: false, workspace: null })}
						workspace={this.state.workspace}
						type="workspace"
						onUpdateWorkspace={this.onUpdateWorkspace.bind(this)}
						hideNewExternal
					/>
				)}
				<BoardTemplateModal
					open={this.state.board_modal}
					workspace={this.state.parentWorkspace}
					onCreate={(board, type) => {
						if (board.type === "shareable" || type === "shareable") {
							this.props.history.push("/admin/workspaces/" + board.workspace_id + "/boards/" + board.id, {
								openBoardMembers: true,
							});
						} else {
							this.props.history.push("/admin/workspaces/" + board.workspace_id + "/boards/" + board.id);
						}
						BoardHelper.fetchWorkSpaces();
						this.closeModal("board");
					}}
					onClose={this.closeModal.bind(this, "board")}
				/>
			</React.Fragment>
		);

		return (
			<React.Fragment>
				{navigationMarkup}
				{modals}
				{ReactDOM.createPortal(
					<div className="mainmenu-item__secondary__collapse-btn" onClick={this.toggleMenu.bind(this)}>
						<Icon source={CircleChevronRightMinor} />
					</div>,
					document.querySelector("body")
				)}
				<div id="mainmenu-shadow" className="bottom-scroll-shadow" style={{ width: "100px", pointerEvents: "none" }} />
			</React.Fragment>
		);
	}
}

export default connect(
	(state) => ({
		account: state.account,
		user: state.user,
		favorite_boards: state.favorite_boards,
		workspaces: state.workspaces,
	}),
	{}
)(withTranslation(["mainmenu", "common"], { withRef: true })(MainMenu));

class MainMenuWorkspaceNavigation extends Component {
	constructor(props) {
		super(props);
		this.state = { search: "" };
	}

	render() {
		const { actions, workspace } = this.props;
		const favorites =
			workspace &&
			workspace.boards.filter(
				(wb) => store.getState().favorite_boards.find((b) => b.id === wb.id) && wb.title.toLowerCase().includes(this.state.search.toLowerCase())
			);

		return (
			<React.Fragment>
				<MainMenuSectionSearch value={this.state.search} onChange={(e) => this.setState({ search: e })} actions={actions} />

				{favorites && !!favorites.length && <MainSectionFavorites history={this.props.history} location={this.props.location} items={favorites} />}

				{/* eslint-disable-next-line react/jsx-props-no-spreading */}
				<WorkspaceNavigation {...this.props} search={this.state.search} style={{ marginTop: "-0.4688rem" }} />
			</React.Fragment>
		);
	}
}
