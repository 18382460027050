import React, { Component } from "react";
import { Icon, LegacyStack } from "@shopify/polaris";

import { CircleCancelMinor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import getColumnValuesToCheckFor from "./Triggers/getColumnValuesToCheckFor";

class TriggerCondition extends Component {
	constructor(props) {
		super(props);
		this.state = {
			focused: props.focused,
			event: props.event,
			condition: props.condition ? Object.assign({}, props.condition) : null,
			focus: null,
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (
			props.focused != this.state.focused ||
			props.event != this.state.event ||
			JSON.stringify(props.condition) != JSON.stringify(this.state.condition)
		) {
			if (props.focused != this.state.focused) {
				this.state.editingTarget = props.focused;
				this.setState({ editingTarget: props.focused });
			}
			this.setState({ focused: props.focused, event: props.event, condition: props.condition ? Object.assign({}, props.condition) : null });
			this.updateActions(props);
		}
	}

	componentDidMount() {
		this.updateActions(this.props);
	}

	changeFocus(target) {
		if (target == "value") {
			this.setState({ editingTarget: false }, this.updateActions.bind(this, this.props));
		} else if (target == "target") {
			this.setState({ editingTarget: true }, this.updateActions.bind(this, this.props));
		}
	}

	changeEvent(event) {
		this.state.event = event;
		this.props.onChangeEvent(event);
		this.changeFocus("target");
	}

	getColumns(types) {
		return this.props.board.columns.reduce((acc, column) => {
			if (types.indexOf("*") >= 0 || types.indexOf(column.type) >= 0) {
				acc.push(column);
			} else if (types.indexOf("status") >= 0 && column.type == "mirror" && column.connected_column && column.connected_column.type == "status") {
				acc.push(column);
			}
			return acc;
		}, []);
	}

	getEventTitle(event, targetTitle = null, valueToCheckForTitle = null) {
		if (this.props.condition && this.props.condition.field == "old_status") {
			return (
				<span>
					{this.props.t("trigger.terms.from", " från")}{" "}
					<span className="target-title" onClick={this.changeFocus.bind(this, "target")}>
						{targetTitle || this.props.t("trigger.terms.status", "status")}
					</span>
				</span>
			);
		} else if (this.props.condition && this.props.condition.field == "new_status") {
			return (
				<span>
					{this.props.t("trigger.terms.to", " till")}{" "}
					<span className="target-title" onClick={this.changeFocus.bind(this, "target")}>
						{targetTitle || this.props.t("trigger.terms.status", "status")}
					</span>
				</span>
			);
		} else if (this.props.condition && this.props.condition.field == "column") {
			return (
				<span>
					<span className="target-title" onClick={this.changeFocus.bind(this, "target")}>
						{targetTitle || this.props.t("trigger.terms.column", "kolumn")}
					</span>{" "}
					ändras
					{this.props.t("trigger.terms.value_to_check_for.pre_placeholder", " till")}{" "}
					<span className="value-title" onClick={this.changeFocus.bind(this, "value_to_check_for")}>
						{valueToCheckForTitle ||
							this.props.condition?.value_to_check_for?.label ||
							this.props.t("trigger.terms.value_to_check_for.placeholder", "värde")}
					</span>
				</span>
			);
		} else if (event == "board_row.value.date_arrive") {
			return (
				<span>
					<span className="target-title" onClick={this.changeFocus.bind(this, "target")}>
						{targetTitle ||
							(this.props.condition ? this.props.t("trigger.terms.when", "När") : this.props.t("trigger.terms.date_occurs", "datum inträffar"))}
					</span>
				</span>
			);
		} else if (event == "board_row.click_button") {
			return (
				<span>
					<span className="target-title" onClick={this.changeFocus.bind(this, "target")}>
						{targetTitle || this.props.t("trigger.terms.button", "knapp")}
					</span>{" "}
					klickas
				</span>
			);
		} else if (event == "board_row.value.change_status") {
			return (
				<span>
					<span className="target-title" onClick={this.changeFocus.bind(this, "target")}>
						{targetTitle || this.props.t("trigger.terms.status", "status")}
					</span>{" "}
					ändras
				</span>
			);
		} else if (event == "board_row.value.update") {
			return (
				<span>
					<span className="target-title" onClick={this.changeFocus.bind(this, "target")}>
						{targetTitle || this.props.t("trigger.terms.column", "kolumn")}
					</span>{" "}
					ändras
				</span>
			);
		} else if (event == "board_row.create") {
			return <span>{this.props.t("trigger.terms.row_create", "rad skapas")}</span>;
		} else if (event == "board_row.comment") {
			return <span>{this.props.t("trigger.terms.comment_create", "kommentar skapas")}</span>;
		} else if (event == "board_row.move_group") {
			return (
				<span>
					{this.props.t("trigger.terms.row_move_to", "rad flyttas till")}{" "}
					<span className="target-title" onClick={this.changeFocus.bind(this, "target")}>
						{targetTitle || this.props.t("trigger.terms.group", "grupp")}
					</span>
				</span>
			);
		} else if (event == "board_row.move_board") {
			return <span>{this.props.t("trigger.terms.row_move_to_this_board", "rad flyttas till denna board")}</span>;
		}

		return event;
	}

	changeValueToCheckFor(value, focusNext = true) {
		this.props.condition.value_to_check_for = value;

		this.setState({ focus: null, valueToCheckForTitle: value.label || value.value }, () => {
			this.props.onUpdateCondition(this.props.condition, focusNext);
		});
	}

	changeTarget(target, focusNext = true) {
		this.props.condition.value = target.id;

		if (this.props.condition?.field === "column") {
			this.setState({ focus: "value_to_check_for" }, () => {
				this.props.onUpdateCondition(this.props.condition, focusNext);
			});
		} else {
			this.props.onUpdateCondition(this.props.condition, focusNext);
		}
	}

	updateActions(props) {
		if (!props.focused) {
			return;
		}
		if (!this.state.event) {
			const events = [
				"board_row.value.date_arrive",
				"board_row.value.change_status",
				"board_row.value.update",
				"board_row.create",
				"board_row.comment",
				"board_row.move_group",
				"board_row.move_board",
				"board_row.click_button",
			];
			const options = events.map((event) => ({
				label: this.getEventTitle(event),
				onMouseOver: () => {
					this.setState({ event });
				},
				onMouseOut: () => {
					this.setState({ event: null });
				},
				onAction: this.changeEvent.bind(this, event),
			}));
			props.updateOptions(this.props.t("trigger.title2", "Välj ett av dessa alternativ för att bygga din automatisering"), options);
		} else if (this.state.event && props.condition && props.condition.field == "timespan") {
			props.updateOptions(this.props.t("trigger.fields.timespan.title", "Välj tid"), [
				{
					label: this.props.t("trigger.fields.timespan.options.when.label", "När"),
					onMouseOver: () => {
						this.setState({ targetTitle: this.props.t("trigger.fields.timespan.options.when.label", "När") });
					},
					onMouseOut: () => {
						this.setState({ targetTitle: null });
					},
					onAction: this.changeTarget.bind(this, { id: 0 }),
				},
				{
					label: this.props.t("trigger.fields.timespan.options.5min.label", "5 minuter innan"),
					onMouseOver: () => {
						this.setState({ targetTitle: this.props.t("trigger.fields.timespan.options.5min.label", "5 minuter innan") });
					},
					onMouseOut: () => {
						this.setState({ targetTitle: null });
					},
					onAction: this.changeTarget.bind(this, { id: -5 }),
				},
				{
					label: this.props.t("trigger.fields.timespan.options.1h.label", "1 timme innan"),
					onMouseOver: () => {
						this.setState({ targetTitle: this.props.t("trigger.fields.timespan.options.1h.label", "1 timme innan") });
					},
					onMouseOut: () => {
						this.setState({ targetTitle: null });
					},
					onAction: this.changeTarget.bind(this, { id: -60 }),
				},
			]);
		} else if (this.state.event && props.condition && (props.condition.field == "new_status" || props.condition.field == "old_status")) {
			if (this.props.extraCondition && this.props.extraCondition.field == "column") {
				const statusColumnId = this.props.extraCondition.value;
				for (let i = 0; i < this.props.board.columns.length; i++) {
					if (this.props.board.columns[i].id == statusColumnId) {
						const statuses =
							this.props.board.columns[i].type === "mirror" &&
							this.props.board.columns[i].connected_column &&
							this.props.board.columns[i].connected_column.type === "status"
								? this.props.board.columns[i].connected_column.options.statuses
								: this.props.board.columns[i].options.statuses;
						props.updateOptions(
							this.props.t("trigger.fields.mirror.title", "Välj status"),

							statuses
								?.filter((s) => !s.removed)
								.map((status) => ({
									label: status.label,
									onMouseOver: () => {
										this.setState({ targetTitle: status.label });
									},
									onMouseOut: () => {
										this.setState({ targetTitle: null });
									},
									onAction: this.changeTarget.bind(this, { id: status.id }),
								}))
						);
						break;
					}
				}
			}
		} else if (this.state.event && props.condition && props.condition.field == "column") {
			if (this.state.focus === "value_to_check_for") {
				const column = this.props.board.columns.find((column) => column.id == this.props.condition.value);

				if (column) {
					const { valuesToCheckFor = [], custom = null } = getColumnValuesToCheckFor(
						props.condition,
						column,
						this.props.board,
						this.changeValueToCheckFor.bind(this)
					);

					const columnValues = [
						{
							label: this.props.t("trigger.terms.any_value", "något"),
							value: undefined, //"*"
						},
						...valuesToCheckFor,
					];

					props.updateOptions(
						this.props.t("trigger.fields.column.action.value_to_check_for", "Välja vilket värde att kolla efter."),

						columnValues.map((columnValue) => ({
							label: columnValue.label,
							onMouseOver: () => {
								this.setState({ valueToCheckForTitle: columnValue.label });
							},
							onMouseOut: () => {
								this.setState({ valueToCheckForTitle: null });
							},
							onAction: this.changeValueToCheckFor.bind(this, columnValue),
						})),
						custom
					);
					return;
				}
			}

			let acceptedColumnTypes = ["*"];

			if (this.state.event == "board_row.value.date_arrive") {
				acceptedColumnTypes = ["datetime", "timeline"];
			}
			if (this.state.event == "board_row.click_button") {
				acceptedColumnTypes = ["button"];
			}
			if (this.state.event == "board_row.value.change_status") {
				acceptedColumnTypes = ["status"];
			}
			const options = this.getColumns(acceptedColumnTypes).map((column) => ({
				label: column.title,
				onMouseOver: () => {
					this.setState({ targetTitle: column.title });
				},
				onMouseOut: () => {
					this.setState({ targetTitle: null });
				},
				onAction: this.changeTarget.bind(this, column, false),
			}));
			props.updateOptions(this.props.t("trigger.fields.column.title", "Välj kolumn"), options);
		} else if (this.state.event && props.condition && props.condition.field == "group") {
			const options = props.groups.map((group) => ({
				label: group.title,
				onMouseOver: () => {
					this.setState({ targetTitle: group.title });
				},
				onMouseOut: () => {
					this.setState({ targetTitle: null });
				},
				onAction: this.changeTarget.bind(this, group, false),
			}));
			props.updateOptions(this.props.t("trigger.fields.group.title", "Välj grupp"), options);
		}
	}

	render() {
		const column = this.props.condition?.field === "column" && this.props.board.columns.find((column) => column.id == this.props.condition?.value);
		const targetTitle = this.state.targetTitle || column?.title;
		const valueToCheckForTitle = this.state.valueToCheckForTitle || this.props.condition?.value_to_check_for?.label;

		const eventLabel = this.state.event
			? this.getEventTitle(this.state.event, targetTitle, valueToCheckForTitle)
			: this.props.event
			? this.getEventTitle(this.props.event, targetTitle, valueToCheckForTitle)
			: this.props.t("trigger.terms.this_is_happening", "detta händer");
		return (
			<div
				onClick={(event) => {
					event.stopPropagation();
					if (!this.state.focused) {
						this.props.onFocus();
					}
				}}
				className={"entry" + (this.state.focused ? " focused" : "")}
			>
				<LegacyStack spacing="tight">
					{!this.props.extraCondition && this.props.event != "board_row.value.date_arrive" ? (
						<span>{this.props.t("trigger.terms.when", "När")} </span>
					) : null}
					<div className={this.state.editingTarget ? "editing-target" : ""}>{eventLabel}</div>
				</LegacyStack>
				{this.props.onRemove ? (
					<span className="clear-button" onClick={this.props.onRemove}>
						<Icon source={CircleCancelMinor} />
					</span>
				) : null}
			</div>
		);
	}
}
export default withTranslation(["trigger", "common"], { withRef: true })(TriggerCondition);
