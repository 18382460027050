import React, { Component } from "react";
import { Button, Collapsible, LegacyCard } from "@shopify/polaris";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import API from "../API";
import { toastr } from "./toastr.js";
import { store } from "../store";
import SellerSelector from "./SellerSelector.js";
import RelationshipSelector from "./RelationshipSelector.js";
import RemoveModal from "./remove_modal.js";
import Skeleton1Card from "./skeleton-1card.js";

class ContactRelationships extends Component {
	constructor(props) {
		super(props);

		this.state = { form: props.contact || {} };
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({ form: props.contact || {} });
	}

	changeUser(field, user) {
		if (this.state.form && Array.isArray(this.state.form.fields)) {
			for (let i = 0; i < this.state.form.fields.length; i++) {
				if (this.state.form.fields[i].field_id == field.id) {
					this.state.form.fields[i].user = user;
				}
			}
			this.setState({ form: this.state.form || {} });
			this.props.onUpdate?.(this.state.form);
		}

		API.put(
			"/api/contacts/" + this.state.form.id + "/fields/" + field.id + "/user.json",
			{
				user,
				is_company: this.state.form.is_company,
			},
			{ params: {} }
		)
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}

				if (this.props.onUpdate) {
					this.props.onUpdate(result.data.contact);
				}

				toastr.success(this.props.t("contact.responses.customer_manager.changed", "Kundansvarig ändrad"));
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	doRemoveTag() {
		const tags =
			this.state.form && Array.isArray(this.state.form.tags) ? this.state.form.tags.filter((tag) => tag.title !== this.state.currentTag.title) : [];
		this.state.form.tags = tags;
		this.setState({ form: this.state.form || {}, saving: true });
		this.props.onUpdate?.(this.state.form);

		API.put(
			"/api/contacts/" + this.state.form.id + ".json",
			{
				tags: this.state.form.tags,
			},
			{ params: {} }
		)
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				toastr.success(this.props.t("contact.responses.tag.removed", "Tog bort tagg"));
				this.state.form.tags = result.data.contact.tags;
				this.setState({
					saving: false,
					removeModalOpen: false,
					form: this.state.form,
				});
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	changeRelationship(field, relationship) {
		if (this.state.form && Array.isArray(this.state.form.fields)) {
			for (let i = 0; i < this.state.form.fields.length; i++) {
				if (this.state.form.fields[i].field_id == field.id) {
					this.state.form.fields[i].relationship = relationship;
				}
			}
			this.setState({ form: this.state.form || {} });
			this.props.onUpdate?.(this.state.form);
		}

		API.put(
			"/api/contacts/" + this.state.form.id + "/fields/" + field.id + "/relationship.json",
			{
				relationship,
				is_company: this.state.form.is_company,
			},
			{ params: {} }
		)
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}

				if (this.props.onUpdate) {
					this.props.onUpdate(result.data.contact);
				}
				this.setState({ form: result.data.contact });
				toastr.success(this.props.t("contact.responses.relation.updated", "Relation uppdaterades"));
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	getUser(field) {
		if (this.state.form && Array.isArray(this.state.form.fields)) {
			for (let i = 0; i < this.state.form.fields.length; i++) {
				if (this.state.form.fields[i].field_id == field.id) {
					return this.state.form.fields[i].user;
				}
			}
			return null;
		}
		return null;
	}

	getRelationship(field) {
		if (this.state.form && Array.isArray(this.state.form.fields)) {
			for (let i = 0; i < this.state.form.fields.length; i++) {
				if (this.state.form.fields[i].field_id == field.id) {
					return this.state.form.fields[i].relationship;
				}
			}
			return "none";
		}
		return "none";
	}

	renderReletionShip({ field, key }) {
		return (
			<div
				// style={{ padding: 0, borderLeft: `5px solid ${field.color}` }}
				key={key}
				className={"Polaris-Card__Section " + (this.state.form.is_company ? "company" : "people") + " relations"}
			>
				<div className="left-status-line" style={{ backgroundColor: field.color }} />
				<div className="relations__inner">
					<div>
						{store.getState().fields && store.getState().fields.length > 1 && <b>{field.title}</b>}

						<div className="seller-selector" style={{ width: "100%", position: "relative" }}>
							<SellerSelector
								noPadding
								user={this.getUser(field)}
								onChange={this.changeUser.bind(this, field)}
								compact={this.props.compact}
								clearable
								plain
								disabled={this.state.form?.removed}
							/>
						</div>
					</div>
					<RelationshipSelector
						disabled={this.state.form?.removed}
						relationship={this.getRelationship(field)}
						onChange={this.changeRelationship.bind(this, field)}
						compact={this.props.compact}
						fontSize="1em"
						style={{ padding: "0.4375rem 1.0000rem", borderRadius: "20px" }}
						// button={({ title, icon, color, onClick, disabled }) => (
						// 	<button onClick={onClick} disabled={disabled} className="Polaris-Button" style={{ color }}>
						// 		<span className="Polaris-Button__Content">
						// 			{icon}
						// 			{icon && " "}
						// 			{title}
						// 		</span>
						// 	</button>
						// )}
					/>
				</div>
			</div>
		);
	}

	getFields() {
		const usedFields = [];
		const unusedFields = [];
		if (store.getState().fields) {
			store.getState().fields.forEach((field) => {
				const rel = this.getRelationship(field);
				if (this.getUser(field) || (rel && rel != "none")) {
					usedFields.push(field);
				} else {
					unusedFields.push(field);
				}
			});
		}

		if (usedFields.length < 1 && unusedFields.length > 0) {
			const firstField = unusedFields.shift();
			usedFields.push(firstField);
		}

		return { usedFields, unusedFields };
	}

	render() {
		const { usedFields, unusedFields } = this.getFields();
		const form = this.state.form;

		return (
			<div className="contact_relationships">
				<LegacyCard>
					{this.props.loading && (
						<div>
							<Skeleton1Card />
						</div>
					)}

					<div className="relations-wrapper">
						{!form.fromRoaring && usedFields && !!usedFields.length && usedFields.map((field, key) => this.renderReletionShip({ field, key }))}

						{!form.fromRoaring && unusedFields && !!unusedFields.length && (
							<div>
								<Collapsible open={this.state.showUnusedFields}>
									{unusedFields.map((field, key) => this.renderReletionShip({ field, key }))}
								</Collapsible>

								<div
									style={{
										textAlign: "right",
										padding: "1.2500rem",
										background: "var(--cardbg)",
										borderBottomLeftRadius: "0.4375rem",
										borderBottomRightRadius: "0.4375rem",
									}}
								>
									<Button
										disclosure={!this.state.showUnusedFields}
										plain
										onClick={() =>
											this.setState({
												showUnusedFields: !this.state.showUnusedFields,
											})
										}
									>
										{!this.state.showUnusedFields ? this.props.t("common.actions.show", "Visa") : this.props.t("common.actions.hide", "Dölj")}{" "}
										{unusedFields.length} affärsområden
									</Button>
								</div>
							</div>
						)}
					</div>

					<RemoveModal
						open={this.state.removeModalOpen}
						loading={this.state.saving}
						onClose={() => {
							this.setState({ removeModalOpen: false });
						}}
						numItems={1}
						objectName={this.state.currentTagName}
						onConfirm={this.doRemoveTag.bind(this)}
						resourceName={{
							singular: this.props.t("contact.tags.singular", "tagg"),
							plural: this.props.t("contact.tags.plural", "taggar"),
						}}
					/>
				</LegacyCard>
			</div>
		);
	}
}
// <AttributeForm metafields={this.state.form.metafields} onUpdate={this.updateMetafields.bind(this)} />
const mapStateToProps = (state, ownProps) => ({
	fields: state.fields,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(
	withTranslation(["contact", "relation", "common"], { withRef: true })(ContactRelationships)
);
