import React, { Component } from "react";
import { Spinner, EmptyState, ExceptionList, Badge } from "@shopify/polaris";
import moment from "moment";
// import httpBuildQuery from "http-build-query";
import {
	ExportMinor,
	ImportMinor,
	ProfileMajor,
	NoteMajor,
	HeartMajor,
	AddProductMajor,
	RemoveProductMajor,
	DeleteMajor,
} from "@shopify/polaris-icons";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import API from "../../API";
import { store } from "../../store";
import ContactModal from "../../components/ContactModal.js";
import { toastr } from "../../components/toastr.js";
import SellerSelector from "../../components/SellerSelector.js";
import RelationshipSelector from "../../components/RelationshipSelector.js";
import ChangeRelationshipModal from "../../components/ChangeRelationshipModal.js";
import ChangeUserModal from "../../components/ChangeUserModal.js";
import TagModal from "../../components/tag_modal.js";
import UpdateContactMetafieldModal from "../../components/UpdateContactMetafieldModal.js";
import ImportModal from "../Workspaces/components/ImportModal.js";
import getDefaultFilters from "../OrderFlow/getDefaultFilters";
import IndexTable from "src/js/components/IndexTable";
import Modal from "src/js/components/modal";

class ContactPeople extends Component {
	constructor(props) {
		super(props);
		this.state = {
			empty: false,
			firstFetch: true,
			uploading: false,
			params: {},
			selectedItems: [],
			visibleItems: [],
			currentContact: {
				tags: [],
			},
		};
	}

	onSelectionChange(selectedItems) {
		this.setState({ selectedItems });
	}

	updateParams(params) {
		this.setState({ params });
	}

	openContactModal(item) {
		this.setState({ showContactModal: true, currentContact: item });
	}

	closeContactModal() {
		this.setState({ showContactModal: false });
	}

	gotoContact(item) {
		item.path = "/admin/contacts/" + item.id;
		this.props.history.push("/admin/contacts/" + item.id, {
			stacks: [
				{
					parent_path: this.props.location.pathname + this.props.location.search,
					parent_title: this.props.t("contact.person.plural", "Kontaktpersoner"),
					item: { id: item.id, item, path: "/admin/contacts/" + item.id },
					items: this.state.visibleItems,
				},
			],
			path: {
				title: this.props.t("contact.person.plural", "Kontaktpersoner"),
				url: "/admin/contacts/people",
			},
		});
	}

	exportExcel() {
		this.setState({ showExportModal: true });
		const params = Object.assign(this.state.params, { is_company: 0, is_visitor: 0 });
		API.post(
			"/api/contacts/export.json",
			{},
			{
				params,
			}
		);
		// const params = Object.assign(this.state.params, { is_company: 0, is_visitor: 0 });
		// window.open("/api/contacts/export.xls?" + httpBuildQuery(params));
	}

	importExcel() {
		this.setState({ showImportModal: true });
	}

	getUser(field, user) {
		for (let i = 0; i < user.fields.length; i++) {
			if (user.fields[i].field_id == field.id) {
				return user.fields[i].user;
			}
		}
		return null;
	}

	getRelationship(field, user) {
		for (let i = 0; i < user.fields.length; i++) {
			if (user.fields[i].field_id == field.id) {
				return user.fields[i].relationship;
			}
		}
		return "none";
	}

	changeContactRelationship(field, contact, relationship) {
		this.setState({ saving: true });
		API.put("/api/contacts/" + contact.id + "/fields/" + field.id + "/relationship.json", {
			relationship,
		})
			.then((result) => {
				toastr.success(this.props.t("contact.responses.relation.changed", "Relation ändrad"));
				this.refresh();
				this.setState({ saving: false });
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	changeContactUser(field, contact, user) {
		this.setState({ saving: true });
		API.put("/api/contacts/" + contact.id + "/fields/" + field.id + "/user.json", {
			user,
		})
			.then((result) => {
				toastr.success(this.props.t("contact.responses.user_contact.changed", "Kundansvarig ändrad"));
				this.refresh();
				this.setState({ saving: false });
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	onItemsFetched(items) {
		if (items.length < 1 && this.state.firstFetch && this.bigDataRef && !this.bigDataRef.state?.appliedFilters?.length) {
			this.setState({ empty: true });
		} else {
			this.setState({ empty: false });
		}
		for (let i = 0; i < items.length; i++) {
			items[i].path = "/admin/contacts/" + items[i].id;
		}
		this.setState({ visibleItems: items, firstFetch: false });
	}

	getBulkParams() {
		if (this.state.selectedItems == "All") {
			const params = this.state.params;
			params.is_company = 0;
			params.is_visitor = 0;
			return params;
		} else {
			const params = {};
			params.ids = this.state.selectedItems;
			return params;
		}
	}

	closeAddTagModal() {
		this.setState({ showAddTagModal: false });
	}

	addTags() {
		this.setState({ showAddTagModal: true });
	}

	closeRemoveTagModal() {
		this.setState({ showRemoveTagModal: false });
	}

	removeTags() {
		this.setState({ showRemoveTagModal: true });
	}

	doAddTags(tags) {
		this.setState({ saving: true });
		API.post(
			"/api/contacts/tags.json",
			{
				tags,
			},
			{ params: this.getBulkParams() }
		)
			.then((result) => {
				this.refresh();
				toastr.success(this.props.t("contact.responses.tags.added", "La till taggar"));
				this.setState({ saving: false, showAddTagModal: false });
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	doRemoveTags(tags) {
		this.setState({ saving: true });
		API.post(
			"/api/contacts/remove_tags.json",
			{
				tags,
			},
			{ params: this.getBulkParams() }
		)
			.then((result) => {
				this.refresh();

				toastr.success(this.props.t("contact.responses.tags.removed", "Tog bort taggar"));
				this.setState({ saving: false, showRemoveTagModal: false });
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	closeRelationshipModal() {
		this.setState({ showRelationshipModal: false });
	}

	changeRelationship() {
		this.setState({ showRelationshipModal: true });
	}

	doChangeRelationship(relationship, field) {
		this.setState({ saving: true });
		API.put(
			"/api/contacts/bulk_edit.json",
			{
				relationship,
				field,
			},
			{ params: this.getBulkParams() }
		)
			.then((result) => {
				this.refresh();
				toastr.success(this.props.t("contact.responses.relation.changed_plural", "Ändrade relationer"));
				this.setState({ saving: false, showRelationshipModal: false });
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	closeSellerModal() {
		this.setState({ showSellerModal: false });
	}

	changeSeller() {
		this.setState({ showSellerModal: true });
	}

	doChangeSeller(seller, field) {
		this.setState({ saving: true });
		API.put(
			"/api/contacts/bulk_edit.json",
			{
				user: seller,
				field,
			},
			{ params: this.getBulkParams() }
		)
			.then((result) => {
				this.refresh();
				toastr.success(this.props.t("contact.responses.customer_user.changed_plural", "Kundansvarig ändrad"));
				this.setState({ saving: false, showSellerModal: false });
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	updateMetafield(metafield) {
		this.setState({
			showUpdateContactMetafieldModal: true,
			currentMetafield: metafield,
		});
	}

	closeUpdateContactMetafieldModal() {
		this.setState({
			showUpdateContactMetafieldModal: false,
		});
	}

	doUpdateContactMetafield(value) {
		this.setState({ saving: true });
		API.put(
			"/api/contacts/bulk_edit_metafield.json",
			{
				metafield: this.state.currentMetafield,
				value,
			},
			{ params: this.getBulkParams() }
		)
			.then((result) => {
				this.refresh();
				toastr.success(`${this.props.t("common.terms.changed", "Ändrade")} ${this.state.currentMetafield.title}`);
				this.setState({ saving: false, showUpdateContactMetafieldModal: false });
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	async bulkRemoveContacts(ids) {
		try {
			if (!ids?.length) {
				throw new Error("No contacts selected");
			}

			this.setState({ removing: true });
			const result = await API.delete("/api/contacts.json", {
				data: { ids },
			});
			this.refresh();
			toastr.success(this.props.t("contact.responses.removed_people", "Tog bort {{count}}st kontakter", { count: result.data.count }));
			this.setState({ bulkRemoveModalIsOpen: false });
		} catch (error) {
			toastr.error(error);
		} finally {
			this.setState({ removing: false });
		}
	}

	render() {
		const filters = getDefaultFilters("people");

		const bulkActions = [
			{
				items: [
					{
						icon: ProfileMajor,
						content: this.props.t("contact.actions.customer.change", "Ändra kundansvarig"),
						onAction: this.changeSeller.bind(this),
					},
					{
						icon: HeartMajor,
						content: this.props.t("contact.actions.relation.change", "Ändra relation"),
						onAction: this.changeRelationship.bind(this),
					},
				],
			},
			{
				items: [
					{
						icon: AddProductMajor,
						content: this.props.t("contact.actions.tags.add", "Lägg till taggar"),
						onAction: this.addTags.bind(this),
					},
					{
						icon: RemoveProductMajor,
						content: this.props.t("contact.actions.tags.remove", "Ta bort taggar"),
						onAction: this.removeTags.bind(this),
					},
				],
			},
		];

		if (store.getState().user.roles?.includes("ROLE_ADMIN")) {
			bulkActions.push({
				items: [
					{
						icon: DeleteMajor,
						content: this.props.t("contact.actions.remove", "Ta bort"),
						onAction: () => {
							this.setState({ bulkRemoveModalIsOpen: true });
						},
						destructive: true,
						loading: this.state.removing,
					},
				],
			});
		}

		const columns = [
			{
				handle: "NAME",
				label: this.props.t("contact.columns.name.label", "Namn"),
				sortable: true,

				render: (item) => {
					const exceptions = [];

					if (item.note) {
						exceptions.push({
							icon: NoteMajor,
							description: item.note,
						});
					}

					const exceptionList = exceptions.length ? (
						<div className="CustomerListItem__Exceptions">
							<ExceptionList items={exceptions} />
						</div>
					) : null;

					return (
						<div>
							<div className="CustomerListItem__Profile">
								<h3 className="CustomerListItem__Title">{item.name}</h3>
								<span className="CustomerListItem__Location">{item.parent ? item.parent.name : null}</span>
							</div>
							<div>
								{item.tags.map((prop, key) => (
									<Badge key={key}>{prop.title}</Badge>
								))}
							</div>
							{exceptionList}
						</div>
					);
				},
			},
			{
				handle: "EMAIL",
				label: this.props.t("contact.columns.email.label", "E-post"),
				sortable: true,
				render: (item) => item.email,
			},
			{
				handle: "PHONE",
				label: this.props.t("contact.columns.phone.label", "Telefon"),
				sortable: true,
				render: (item) => item.phone,
			},
			{
				handle: "MOBILEPHONE",
				label: this.props.t("contact.columns.mobilephone.label", "Mobiltelefon"),
				sortable: true,
				render: (item) => item.mobile_phone,
			},
			{
				handle: "EMAIL",
				label: this.props.t("contact.columns.email.label", "E-post"),
				sortable: true,
				type: "text",
			},
			{
				handle: "PHONE",
				label: this.props.t("contact.columns.phone.label", "Telefon"),
				sortable: true,
				type: "text",
			},
			{
				handle: "MOBILEPHONE",
				label: this.props.t("contact.columns.mobilephone.label", "Mobiltelefon"),
				sortable: true,
				type: "text",
			},
			{
				handle: "LAST_ACTIVITY",
				label: this.props.t("contact.columns.last_activity.label", "Senast aktivitet"),
				sortable: true,
				render: (item) => (item.last_activity_at ? moment(item.last_activity_at).locale("sv").calendar() : "–"),
			},
		];

		for (let i = 0; i < store.getState().fields.length; i++) {
			columns.push({
				handle: `USER-${store.getState().fields[i].id}`,
				field: store.getState().fields[i],
				label:
					this.props.t("contact.columns.contact_user.label", "Kundansvarig") +
					(store.getState().fields.length > 1 ? " " + store.getState().fields[i].title : ""),
				sortable: false,
				render: (item, column) => {
					<SellerSelector user={this.getUser(column.field, item)} onChange={this.changeContactUser.bind(this, column.field, item)} clearable />;
				},
			});
			columns.push({
				handle: `RELATIONSHIP-${store.getState().fields[i].id}`,
				field: store.getState().fields[i],
				label:
					this.props.t("contact.columns.relation.label", "Relation") +
					(store.getState().fields.length > 1 ? " " + store.getState().fields[i].title : ""),
				sortable: false,
				render: (item, column) => {
					<RelationshipSelector
						relationship={this.getRelationship(column.field, item)}
						onChange={this.changeContactRelationship.bind(this, column.field, item)}
					/>;
				},
			});
		}

		const metafieldActions = { items: [] };

		for (let i = 0; i < store.getState().contact_metafields.length; i++) {
			if (store.getState().contact_metafields[i].for == "person") {
				columns.push({
					handle: "METAFIELD_" + store.getState().contact_metafields[i].id,
					label: store.getState().contact_metafields[i].title,
					sortable: false,
					render: (item, column) => {
						const metafieldId = parseInt(column.handle.substring(10));
						let metaField = null;
						for (let i = 0; i < store.getState().contact_metafields.length; i++) {
							if (store.getState().contact_metafields[i].id == metafieldId) {
								metaField = store.getState().contact_metafields[i];
							}
						}

						if (metaField) {
							for (let i = 0; i < item.metafields.length; i++) {
								if (item.metafields[i].metafield_id == metafieldId) {
									if (metaField.type === "boolean") return item.metafields[i].value ? "Ja" : "Nej";

									return item.metafields[i].value + "";
								}
							}
						}
						return "–";
					},
				});

				metafieldActions.items.push({
					icon: AddProductMajor,
					content: store.getState().contact_metafields[i].title,
					onAction: this.updateMetafield.bind(this, store.getState().contact_metafields[i]),
				});
			}
		}

		if (store.getState().contact_metafields.length) {
			bulkActions.push(metafieldActions);
		}

		return (
			<div>
				<IndexTable
					ref={(ref) => {
						this.bigDataRef = ref;
					}}
					history={this.props.history}
					setRefreshHandler={(refreshHandler) => {
						this.refresh = refreshHandler;
					}}
					title={this.props.t("contact.person.title", "Kontaktpersoner")}
					secondaryActions={
						store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0
							? [
									{ icon: ExportMinor, content: this.props.t("contact.actions.export", "Exportera"), onAction: this.exportExcel.bind(this) },
									{
										icon: ImportMinor,
										content: this.state.uploading ? <Spinner size="small" color="teal" /> : this.props.t("contact.actions.import", "Importera"),
										onAction: this.importExcel.bind(this),
									},
							  ]
							: []
					}
					primaryAction={{
						content: this.props.t("contact.person.actions.new", "Ny kontaktperson"),
						onAction: () => {
							this.openContactModal({
								name: "",
								company: "",
								email: "",
								phone: "",
								zip: "",
								note: "",
								address1: "",
								address2: "",
								country: "SE",
								tags: [],
							});
						},
					}}
					savedSearchHandle="contact_people"
					resourceUrl="/api/contacts.json?is_company=0&is_visitor=0"
					resourceHandle="contacts"
					onItemsFetched={this.onItemsFetched.bind(this)}
					onSelectionChange={this.onSelectionChange.bind(this)}
					resourceName={{
						singular: this.props.t("contact.person.singular", "kontaktperson"),
						plural: this.props.t("contact.person.plural", "kontaktpersoner"),
					}}
					// renderMedia={(item) => <ProfileAvatar source={item.email ? item.avatar : null} user={item} name={item.name} />}
					selectedItems={this.state.selectedItems}
					onUpdateParams={this.updateParams.bind(this)}
					filters={filters}
					columns={columns}
					defaultSort="LAST_ACTIVITY_DESC"
					onClickRow={this.gotoContact.bind(this)}
					bulkActions={bulkActions}
					emptyState={
						this.state.empty && (
							<div>
								<EmptyState
									heading={this.props.t("contact.person.error.empty.heading", "Inga kontaktpersoner")}
									action={{
										content: this.props.t("contact.person.actions.create", "Skapa kontaktperson"),
										onAction: this.openContactModal.bind(this, {
											name: "",
											company: "",
											email: "",
											phone: "",
											zip: "",
											note: "",
											address1: "",
											address2: "",
											country: "SE",
											tags: [],
										}),
									}}
									image="/assets/images/empty_state/People.png"
								>
									<p>{this.props.t("contact.person.error.empty.text", "Du har inga kontaktpersoner")}</p>
								</EmptyState>
								<ContactModal
									open={this.state.showContactModal}
									onUpdate={() => {
										this.setState({ empty: false });
									}}
									contact={this.state.currentContact}
									onCreate={(contact) => {
										this.gotoContact(contact);
									}}
									onClose={this.closeContactModal.bind(this)}
								/>
							</div>
						)
					}
				/>
				<ContactModal
					open={this.state.showContactModal}
					onUpdate={() => {
						this.refresh();
					}}
					contact={this.state.currentContact}
					onCreate={(contact) => {
						this.gotoContact(contact);
					}}
					onClose={this.closeContactModal.bind(this)}
				/>
				<TagModal
					open={this.state.showAddTagModal}
					onClose={this.closeAddTagModal.bind(this)}
					resourceName={{
						singular: this.props.t("contact.singular", "kontakt"),
						plural: this.props.t("contact.plural", "kontakter"),
					}}
					resource="contacts"
					numItems={this.state.selectedItems.length}
					onConfirm={this.doAddTags.bind(this)}
					loading={this.state.saving}
				/>
				<TagModal
					open={this.state.showRemoveTagModal}
					onClose={this.closeRemoveTagModal.bind(this)}
					resourceName={{
						singular: this.props.t("contact.singular", "kontakt"),
						plural: this.props.t("contact.plural", "kontakter"),
					}}
					resource="contacts"
					numItems={this.state.selectedItems.length}
					onConfirm={this.doRemoveTags.bind(this)}
					loading={this.state.saving}
					remove
				/>
				<ChangeRelationshipModal
					open={this.state.showRelationshipModal}
					loading={this.state.saving}
					onClose={this.closeRelationshipModal.bind(this)}
					onConfirm={this.doChangeRelationship.bind(this)}
					numItems={this.state.selectedItems.length}
				/>
				<ChangeUserModal
					open={this.state.showSellerModal}
					loading={this.state.saving}
					onClose={this.closeSellerModal.bind(this)}
					onConfirm={this.doChangeSeller.bind(this)}
					numItems={this.state.selectedItems.length}
				/>
				<UpdateContactMetafieldModal
					open={this.state.showUpdateContactMetafieldModal}
					loading={this.state.saving}
					onClose={this.closeUpdateContactMetafieldModal.bind(this)}
					onConfirm={this.doUpdateContactMetafield.bind(this)}
					numItems={this.state.selectedItems.length}
					metafield={this.state.currentMetafield}
				/>
				<ImportModal
					open={this.state.showImportModal}
					type="people"
					onClose={() => {
						this.setState({ showImportModal: false });
					}}
					onComplete={() => {
						this.refresh();
					}}
				/>
				<Modal
					open={this.state.showExportModal}
					onClose={() => {
						this.setState({ showExportModal: false });
					}}
					title={this.props.t("contact.export.contacts.title", "Exportera kontaktpersoner")}
					primaryAction={{
						content: this.props.t("buttons.close", "Stäng"),
						onAction: () => {
							this.setState({ showExportModal: false });
						},
					}}
				>
					<Modal.Section>
						<p>{this.props.t("contact.export.please_wait", "Din export är på väg. Du kommer att få ett mail när den är klar.")}</p>
					</Modal.Section>
				</Modal>

				<Modal
					sectioned
					title={this.props.t("contacts.actions.bulk_remove_people.confirm.title", `Ta bort {{count}}st kontakter?`, {
						count: this.bigDataRef?.getSelectedItems()?.length,
					})}
					open={this.state.bulkRemoveModalIsOpen}
					onClose={() => {
						this.setState({ bulkRemoveModalIsOpen: false });
					}}
					secondaryActions={[
						{
							content: this.props.t("common.actions.cancel", "Avbryt"),
							onAction: () => {
								this.setState({ bulkRemoveModalIsOpen: false });
							},
						},
					]}
					primaryAction={{
						content: this.props.t("common.actions.remove", "Ta bort"),
						loading: this.state.removing,
						onAction: () => {
							this.bulkRemoveContacts(this.bigDataRef?.getSelectedItems());
						},
					}}
				>
					<p>
						{this.props.t("contacts.actions.bulk_remove_people.confirm.text", "	Säker på att du vill ta bort {{count}}st kontakter?", {
							count: this.bigDataRef?.getSelectedItems()?.length,
						})}
					</p>
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	board_contacts: state.board_contacts,
	fields: state.fields,
	contact_metafields: state.contact_metafields,
});

export default connect(mapStateToProps)(withTranslation(["contact", "common"], { withRef: true })(ContactPeople));
