import React from "react";
import { Icon, Tabs as PolarisTabs } from "@shopify/polaris";
import styled from "styled-components";
import Spinner from "../Spinner";

const Tabs = ({ children = null, tabs, selected, onSelect, ...rest }) => {
	const formattedTabs = tabs.map((tab) => {
		tab.loading = false;

		const prefix = tab.loading ? (
			<Spinner size="small" />
		) : React.isValidElement(tab.icon) ? (
			tab.icon
		) : (
			(tab.icon && <Icon source={tab.icon} />) || null
		);

		return {
			...tab,
			content: (
				<TabWrapper>
					{prefix && <IconWrapper>{prefix}</IconWrapper>}
					{tab.title || tab.content}
					{tab.badge}
					{/* {tab.loading && <Spinner size="small" />} */}
				</TabWrapper>
			),

			icon: undefined,
			badge: undefined,
		};
	});

	return (
		<PolarisTabs tabs={formattedTabs} selected={selected} onSelect={onSelect} {...rest}>
			{children}
		</PolarisTabs>
	);
};
export default Tabs;

const TabWrapper = styled.div`
	display: flex;
	gap: 0.5rem;
	align-items: center;
`;

const IconWrapper = styled.div`
	height: 20px;
	width: 20px;
	display: flex;
	align-items: center;
	justify-content: start;
`;
