/* eslint-disable no-unused-vars */
import { Button, LegacyCard, Icon, TextField, Tooltip, LegacyStack, DropZone, ProgressBar, Text, Badge } from "@shopify/polaris";
import { AttachmentMajor, ChevronRightMinor, EditMajor, ImportMinor, MentionMajor, PlayMinor } from "@shopify/polaris-icons";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import axios from "axios";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import circularReferenceReplacer from "src/js/Utilities/circularReferenceReplacer";
import API from "../../../../API";
import Attachment from "../../../../components/Attachment";
import InfoView from "../../../../components/InfoView";
import Mention from "../../../../components/Mention";
import { toastr } from "../../../../components/toastr";
import { store } from "../../../../store";
import BoardHelper from "../../BoardHelper";
import CellStatus from "../../CellStatus";
import CellCompanyNumber from "../../CellCompanyNumber";
import ResourcePicker from "../../../../components/resource_picker";
import BoardEmptyScreen from "./BoardEmptyScreen";
import RowModal from "../../components/RowModal";
import Banner from "../../../../components/banner";
import TimeLineRow from "../../../../components/TimeLineRow";
import OutcomeButton from "./OutcomeButton";
import { getRowTypeLabel } from "../../boardutils/BoardUtils";
import RowCommentsModal from "./RowCommentsModal";

class RowCard extends Component {
	constructor(props) {
		super(props);
		const defaultForm = {
			activity: store.getState().contact_activities.find((activity) => activity.type === "phone"),
			content: "",
			files: [],
			mentions: [],
			date: moment().format("YYYY-MM-DD"),
			asd: moment().format("YYYY-MM-DD HH:mm:ss"),
		};
		this.state = {
			form: { ...defaultForm },
			deal: {},
			rowComments: [],
		};

		this.defaultForm = { ...defaultForm };
	}

	componentDidMount() {
		if (this.props.row) {
			this.onFetchAcitivities(this.props.row);
			this.onFetchBoardRowComments(this.props.row);
		}
		this.setAndGetDealBoardAndGroups();
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (!props.row && !props.empty) {
			this.props.selectFirstRow();
		}

		if (this.props.row?.id !== props.row?.id) {
			this.cancelRequests();
			this.onFetchAcitivities(props.row);
			this.onFetchBoardRowComments(props.row);
			// eslint-disable-next-line react/no-unused-state
			this.state.form = { ...this.defaultForm };
			this.setState({
				progress: 0,
				comments: [],
				rowComments: [],
				form: { ...this.defaultForm },
				submitting: false,
			});
		}
		if (
			this.props.row?.id !== props.row?.id ||
			JSON.stringify(props.board) !== JSON.stringify(this.props.board) ||
			JSON.stringify(props.row, circularReferenceReplacer()) !== JSON.stringify(this.props.row, circularReferenceReplacer())
		) {
			this.setState({
				details:
					props.row &&
					props.board.columns
						.map((column) => {
							const values = props.row.column_values[column.id];
							if (column?.type === "tags" && values?.value) {
								return {
									label: column?.title,
									component: (
										<LegacyStack spacing="tight">
											{values?.value?.map((id) => {
												const tag = props.board.tags.find((t) => String(t.id) === String(id));
												return <Badge key={id}>{tag?.title}</Badge>;
											})}
										</LegacyStack>
									),
									type: column?.type,
								};
							}

							if (column?.type === "contact" && values?.value) {
								const contacts = BoardHelper.getContact(values?.value);
								return {
									label: column?.title,
									component:
										!!contacts?.length &&
										contacts.map((contact, index, arr) => {
											return (
												<span key={index}>
													<Link to={`/admin/contacts/${contact?.id}`}>{contact?.name}</Link>
													{index + 1 < arr?.length ? ", " : ""}
												</span>
											);
										}),
									type: column?.type,
								};
							}

							return {
								label: column?.title,
								value: values?.value,
								type: column?.type,
							};
						})
						.filter(({ type, value, component }) => !["datetime", "company_number", "status", "person"].includes(type) && (value || component)),

				// props.row &&
				// Object.values(props.row.column_values).map((values) => {
				// 	const column = props.board.columns.find((c) => String(c.id) === String(values.column_id));
				// 	return {
				// 		label: column?.title,
				// 		value: values.value || "-",
				// 		type: column?.type,
				// 	};
				// }),
			});
		}
	}

	async setAndGetDealBoardAndGroups() {
		const dealBoard = await this.fetchBoard();
		const dealGroups = await this.fetchGroups();
		this.setState({ dealBoard, dealGroups });

		return {
			dealBoard,
			dealGroups,
		};
	}

	onChangeForm(form) {
		this.setState({ form: { ...form } });
		if (this.props.onUpdateForm) {
			this.props.onUpdateForm(form);
		}
	}

	async updateCell(column, data) {
		const result = await BoardHelper.onUpdateValue(this.props.row, column, data);
		return result;
	}

	addFile(file, event) {
		const data = event.target.result;
		const files = this.state.form.files;
		files.push({ name: file.name, size: file.size, data });
		this.state.form.files = files;

		this.onChangeForm(this.state.form);
	}

	updateFile(index, file) {
		this.state.form.files[index] = file;
		this.onChangeForm(this.state.form);
	}

	async fetchBoard() {
		try {
			const result = await API.get("/api/boards/" + store.getState().account.sales_board_id + ".json", {
				params: {},
			});
			store.dispatch({ type: "UPDATE_BOARD", board: result.data.board });
			return result.data.board;
		} catch (error) {
			toastr.error(error);
		}
	}

	async fetchGroups() {
		try {
			const result = await API.get("/api/boards/" + store.getState().account.sales_board_id + "/groups.json", {
				params: {},
			});
			return result.data.groups;
		} catch (error) {
			toastr.error(error);
		}
	}

	onSkip(selectFirstRow = true) {
		this.setState({ form: { ...this.defaultForm } });
		const index = this.props.view.options.order.indexOf(this.props.row.id);
		if (index >= 0) {
			this.props.view.options.order.splice(index, 1);
		}
		this.props.view.options.order.push(this.props.row.id);
		this.props.onUpdateView(this.props.view);
		if (selectFirstRow) this.props.selectFirstRow();
	}

	getCompanyForm(obj = {}) {
		const titleColumn =
			this.props.board.columns.find((column) => String(column.id) === String(this.props.view.options.title_column)) ||
			this.props.view.options.title_column;

		const companyForm = Object.values(this.props.row.column_values).reduce(
			(acc, columnValue) => {
				const column = this.props.board.columns.find((col) => String(col.id) === String(columnValue.column_id));

				switch (column.title.toLowerCase()) {
					case "organisationsnummer":
						acc.orgnr = BoardHelper.getColumnValues(this.props.row, column)?.[0]?.value?.number;
						break;
					case "telefon":
						acc.phone = BoardHelper.getColumnValues(this.props.row, column)?.[0]?.value;
						break;
					case "e-post":
						acc.email = BoardHelper.getColumnValues(this.props.row, column)?.[0]?.value;
						break;
					case "hemsida":
						acc.website = BoardHelper.getColumnValues(this.props.row, column)?.[0]?.value;
						break;
					case "gatuadress":
						acc.address1 = BoardHelper.getColumnValues(this.props.row, column)?.[0]?.value;
						break;
					case "postnummer":
						acc.zip = BoardHelper.getColumnValues(this.props.row, column)?.[0]?.value;
						break;
					case "ort":
						acc.city = BoardHelper.getColumnValues(this.props.row, column)?.[0]?.value;
						break;
					case "taggar":
						acc.tags = BoardHelper.getColumnValues(this.props.row, column)?.[0]?.value?.map((id) => {
							const tag = this.props.board.tags.find((t) => String(t.id) === String(id));
							return tag?.title || null;
						});
						break;
					default:
						break;
				}

				return acc;
			},
			{
				name: titleColumn === "row_title" ? this.props.row.title : BoardHelper.getColumnValues(this.props.row, titleColumn)?.[0]?.value || "",
				is_company: true,
				...(obj || {}),
			}
		);

		return companyForm;
	}

	getContactIDs() {
		const contactColumn = this.props.board.columns.find((column) => String(column.id) === String(this.props.view.options.contact_column));
		const rowContactIds = BoardHelper.getColumnValues(this.props.row, contactColumn)?.[0]?.value;

		return rowContactIds;
	}

	async createContact(obj = {}) {
		const companyForm = this.getCompanyForm(obj);

		const contactPersonColumn = this.props.board.columns.find((column) => column.title.toLowerCase() === "kontaktperson");
		const contactPersonValue = this.props.row.column_values[contactPersonColumn?.id]?.value;
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const [fullname, lastName, firstName, title] = (contactPersonValue || "").match(/(.*), (.*) \((.*)\)/) || [];

		const contactColumn = this.props.board.columns.find((column) => String(column.id) === String(this.props.view.options.contact_column));
		const rowContactId = BoardHelper.getColumnValues(this.props.row, contactColumn)?.[0]?.value?.[0];

		const contact = rowContactId
			? BoardHelper.getContact(rowContactId)
			: await API.post("/api/contacts.json", companyForm)
					.then((result) => {
						if (result.data.error) {
							console.error("error:", result.data.error);
							toastr.error(result.data.error);
						}

						return result.data.contact;
					})
					.catch((error) => {
						console.error("error:", error);
						toastr.error(error);
					});

		store.dispatch({ type: "SET_BOARD_CONTACTS", contacts: [contact] });

		const company = contact?.parent || contact;
		if (!rowContactId && contactPersonValue && company?.is_company) {
			const contactPerson = await API.post("/api/contacts.json", {
				parent: company,
				first_name: firstName,
				last_name: lastName,
				title,
				tags: company.tags || null,
			})
				.then((result) => {
					if (result.data.error) {
						console.error("error:", result.data.error);
						toastr.error(result.data.error);
					}

					return result.data.contact;
				})
				.catch((error) => {
					console.error("error:", error);
					toastr.error(error);
				});
			store.dispatch({ type: "SET_BOARD_CONTACTS", contacts: [contactPerson] });

			// return contactPerson?.parent || contactPerson;
			return contactPerson;
		}

		return contact;
	}

	async sendComment({ contact, comment }) {
		// this.state.form.activity = activity;

		return API.post(
			`/api/contacts/${contact?.id}/comments.json`,
			{ ...this.state.form, ...comment },
			{
				params: {},
			}
		)
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.state.form.files = [];
				this.state.form.mentions = [];
				this.state.form.content = "";
				this.state.form.activity = null;
				this.state.form.row = null;
				this.onChangeForm(this.state.form);
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	createCancelToken(c) {
		this.setState({ cancelToken: c });
	}

	cancelRequests() {
		if (this.state.cancelToken) {
			this.state.cancelToken();
			this.setState({ cancelToken: null });
		}
	}

	async onFetchBoardRowComments(row) {
		const comments = await API.get("/api/boards/" + row.board_id + "/rows/" + row.id + "/comments.json", {
			cancelToken: new axios.CancelToken(this.createCancelToken.bind(this)),
			params: {},
		})
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}

				this.setState({
					rowComments: result.data.comments,
				});
				return result.data.comments;
			})
			.catch((error) => {
				if (axios.isCancel(error)) {
					return;
				}
				toastr.error(error);
			});

		return comments;
	}

	async onFetchAcitivities(row) {
		const contactColumn = this.props.board.columns.find((column) => String(column.id) === String(this.props.view.options.contact_column));
		const rowContactId = BoardHelper.getColumnValues(row, contactColumn)?.[0]?.value?.[0];

		const orgnrColumn = row && this.props.board.columns.find((column) => column.type === "company_number");

		const contactId = rowContactId || BoardHelper.getCompanyNumber(row?.column_values?.[orgnrColumn?.id]?.value)?.find((v) => v?.is_company)?.id;

		if (!contactId) {
			this.setState({ comments: [] });
			return;
		}

		const comments = await API.get("/api/contacts/" + contactId + "/comments.json", {
			cancelToken: new axios.CancelToken(this.createCancelToken.bind(this)),
			params: {},
		})
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}

				this.setState({
					comments: result.data.comments /* row_comments: result.data.row_comments */,
				});
				return result.data;
			})
			.catch((error) => {
				if (axios.isCancel(error)) {
					return;
				}
				toastr.error(error);
			});

		return comments;
	}

	async actionSubmit(status, comment) {
		this.setState({ progress: 10 });

		if (status.activity) {
			const contact = await this.createContact();
			this.setState({ progress: 30 });
			const contactColumn = this.props.board.columns.find((column) => String(column.id) === String(this.props.view.options.contact_column));
			await this.updateCell(contactColumn, { value: [contact.id] });
			this.setState({ progress: 60 });

			await this.sendComment({ contact, comment });
			this.setState({ progress: 80 });
		}

		const statusColumn = this.props.board.columns.find((column) => String(column.id) === String(this.props.view.options.status_column));
		const value = status.id;
		await this.updateCell(statusColumn, { value });
		this.setState({ progress: 100, submitting: false });

		this.onSkip();
		toastr.success("Klar");
		return true;
	}

	onClearFilters() {
		this.props.onChangeFilters?.([]);
	}

	async preActionClick(status) {
		if (status?.activity) {
			try {
				this.setState({ loadingContact: true, submitting: true, progress: 10 });
				const contact = await this.createContact();
				const contactColumn = this.props.board.columns.find((column) => String(column.id) === String(this.props.view.options.contact_column));
				const res = await this.updateCell(contactColumn, { value: [contact.id] });

				return res;
			} catch (error) {
				console.error("preActionClick error:", error);
			} finally {
				this.setState({ loadingContact: false });
			}
		}
	}

	async saveComment() {
		this.setState({ savingComment: true });

		try {
			const result = await API.post("/api/boards/" + this.props.row.board_id + "/rows/" + this.props.row.id + "/comments.json", this.state.form, {
				params: {},
			});

			if (result.data?.comment) {
				toastr.success(this.props.t("timeline.responses.comment_added", "Kommentar tillagd"));
				this.state.form.files = [];
				this.state.form.mentions = [];
				this.state.form.content = "";
				this.onChangeForm(this.state.form);

				this.setState({ rowComments: [result.data.comment, ...(this.state.rowComments || [])] });
			}
		} catch (e) {
			console.error("Error", e);
		}

		this.setState({ savingComment: false });
	}

	render() {
		const { row, board, view, empty } = this.props;
		const statusColumn = row && board.columns.find((column) => String(column.id) === String(view.options.status_column));
		const orgnrColumn = row && board.columns.find((column) => column.type === "company_number");
		// const phoneColumn = board.columns.find((column) => String(column.id) === String(view.options.phone_column));

		const commentTextField = (
			<TextField
				placeholder={this.props.t("timeline.fields.content.label", "Kommentar...")}
				value={this.state.form.content}
				onChange={(value) => {
					this.state.form.content = value;
					this.setState({ showTextfieldError: false });
					this.onChangeForm(this.state.form);
				}}
				readOnly={this.state.submitting}
				error={this.state.showTextfieldError && "Skriv en kommentar"}
				suffix={
					<LegacyStack spacing="extraTight">
						<Tooltip preferredPosition="above" content={this.props.t("timeline.text.upload", "Ladda upp fil")}>
							<Button
								plain
								size="slim"
								onClick={() => {
									this.setState({ openFileDialog: true });
								}}
							>
								<Icon color="skyDark" source={ImportMinor} />
							</Button>
						</Tooltip>
						<Tooltip preferredPosition="above" content={this.props.t("timeline.text.mention.coworker", "Nämn en medarbetare")}>
							<ResourcePicker
								size="slim"
								caption={<Icon color="skyDark" source={MentionMajor} />}
								fixedCaption
								resource="users"
								fullWidth={false}
								item={null}
								onChange={(user) => {
									this.state.form.mentions.push(user);
									this.onChangeForm(this.state.form);
								}}
								plain
								id_handle="id"
								resource_handle="users"
								label_handle="fullname"
								resourceName={{
									singular: this.props.t("timeline.users.singular", "användare"),
									plural: this.props.t("timeline.users.plural", "användare"),
								}}
								external={this.props.external}
							/>
						</Tooltip>
					</LegacyStack>
				}
				multiline
			/>
		);
		const banner = (() => {
			const value = row?.column_values?.[statusColumn?.id]?.value;
			const color = statusColumn?.options?.statuses[value]?.color;
			const dateColumn = board.columns.find((column) => column.type === "datetime" && column.connected_column_id);
			const date = row?.column_values?.[dateColumn?.id]?.value?.datetime;

			if (color === "#579bfc") {
				return (
					<Banner
						icon={null}
						style={{ marginBottom: "1.0000rem" }}
						type="info"
						title={this.props.t("board.calllist.card.actions.return", "Återkom senare")}
					>
						<p>
							{this.props.t("board.text.return", "Återkom")} {date || `[${this.props.t("board.text.missing_date", "saknar datum")}]`}
						</p>
					</Banner>
				);
			}

			return null;
		})();

		const actions = this.props.statusColumn?.options?.statuses
			?.filter((status) => status.color !== "#c4c4c4")
			.map((status) => ({
				...status,
				activity: store.getState().contact_activities.find((activity) => {
					return !activity.removed && activity.id == this.props?.view.options?.status_acivities?.[status.id];
				}),
			}));

		return (
			<>
				<div className="board_view_callist_card actions">
					<LegacyCard sectioned>
						<div style={{ height: !row ? "100%" : "unset" }}>
							{this.state.progress ? (
								<ProgressBar progress={this.state.progress} size="small" color="success" />
							) : (
								<div className="Polaris-ProgressBar--sizeSmall" />
							)}
							{!row ? (
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										gap: "0.6250rem",
										alignItems: "center",
										height: "100%",
										justifyContent: "center",
									}}
								>
									<Text variant="headingMd" as="h2">
										{this.props.t("board.calllist.card.start.title", "Börja jaga leads")}
									</Text>
									{!empty ? (
										<>
											<Text variant="headingSm" el="h3">
												{this.props.t("board.calllist.card.start.text", "Klicka här för att börja gå igenom din ringlista")}
											</Text>
											<Button onClick={this.props.onStart} icon={PlayMinor} primary>
												{this.props.t("board.calllist.card.start.action.start", "Starta")}
											</Button>
										</>
									) : (
										<BoardEmptyScreen
											board={board}
											noheader={this.props.noheader}
											clear={!!this.props.filters?.length && this.onClearFilters.bind(this)}
											text={this.props.t("board.call_list.empty.text", "Prova att ändra filter/sökning eller importera bolagslista")}
											style={{ position: "relative" }}
										>
											{this.props.openImportCompanyBoardModal && (
												<Button primary onClick={this.props.openImportCompanyBoardModal}>
													{this.props.t("board.calllist.actions.import", "Importera bolagslista")}
												</Button>
											)}
										</BoardEmptyScreen>
									)}
								</div>
							) : (
								<>
									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
										}}
									>
										<h2>{row?.title}</h2>
										<OutcomeButton
											status={{ label: this.props.t("common.actions.skip", "Skippa") }}
											onSubmit={this.onSkip.bind(this)}
											icon={<Icon source={ChevronRightMinor} />}
										/>
									</div>

									{!!actions?.length && (
										<div className="pagination_wrapper">
											{actions.map((status) => {
												return (
													<OutcomeButton
														key={`${status.id}-${this.props.row?.id}`}
														onSubmit={this.actionSubmit.bind(this)}
														comment={this.state.form}
														activity={status.activity}
														status={status}
														onClose={() => {
															this.setState({ form: { ...this.defaultForm }, progress: 0, submitting: false });
														}}
														loadingContact={this.state.loadingContact}
														onClick={this.preActionClick.bind(this, status)}
														contacts={this.getContactIDs()?.map((id) => BoardHelper.getContact(id))}
														// color={outcome.color}
														// backgroundColor={colorToRgba(outcome.color, 0.45)}
													/>
												);
											})}
										</div>
									)}
								</>
							)}
						</div>
						{row && <div className="timeline__rows__input">{commentTextField}</div>}

						<div style={{ display: "flex", gap: "0.6250rem" }}>
							{row && (
								<Button
									disabled={!this.state.form.content}
									plain
									removeUnderline
									onClick={this.saveComment.bind(this)}
									loading={this.state.savingComment}
								>
									{this.props.t("board.calllist.card.actions.save_comment", "Spara kommentar")}
								</Button>
							)}
							{row && (
								<Button
									plain
									removeUnderline
									disabled={!this.state.rowComments?.length}
									onClick={() => {
										this.setState({
											commentsModalIsOpen: true,
										});
									}}
								>
									{this.props.t("board.calllist.card.actions.comments", "Visa kommentarer")} ({this.state.rowComments?.length})
								</Button>
							)}
						</div>

						{this.state.form.row && (
							<div>
								<b>{getRowTypeLabel(this.state.form?.row)}:</b>
								<span>{this.state.form.row.title}</span>
							</div>
						)}
						{this.state.form.mentions.map((user, index) => (
							<div className="field" key={user.id}>
								<Icon source={MentionMajor} />

								<Mention
									icon={null}
									user={user}
									onRemove={() => {
										this.state.form.mentions.splice(index, 1);
										this.onChangeForm(this.state.form);
									}}
								/>
							</div>
						))}

						{this.state.form.files.map((file, index) => (
							<div className="field" key={file.id}>
								<Icon source={AttachmentMajor} />

								<Attachment
									plain
									editable
									icon={null}
									key={index}
									onChange={this.updateFile.bind(this, index)}
									file={file}
									onRemove={() => {
										this.state.form.files.splice(index, 1);
										this.onChangeForm(this.state.form);
									}}
								/>
							</div>
						))}
					</LegacyCard>
				</div>
				<div className="board_view_callist_card details">
					<LegacyCard sectioned>
						{!!row && (
							<>
								{banner}
								{!!this.state.comments?.length && (
									<Banner style={{ marginBottom: "1.0000rem", paddingLeft: 0, paddingBottom: 0 }} type="info" title={null} icon={null}>
										<p>{/* {this.state.comments[0]?.content} */}</p>

										<TimeLineRow
											event={Object.assign(this.state.comments[0] || {}, {
												isComment: true,
											})}
										/>
									</Banner>
								)}
								<div style={{ display: "flex", justifyContent: "space-between" }}>
									<h2>{row?.title}</h2>

									<Button
										plain
										icon={EditMajor}
										onClick={() => {
											this.setState({ editingRow: true });
										}}
									/>
								</div>
								<div className="status card_detail">
									<div>
										{this.props.t("board.callist.fields.status.label", "Status")}:{" "}
										{statusColumn && (
											<CellStatus
												// inheritColor
												style={{ borderRadius: "100vh" }}
												// onChange={this.updateCell.bind(this, statusColumn)}
												column={statusColumn}
												// onFocus={this.focusCell.bind(this)}
												// onBlur={this.blurCell.bind(this)}
												onUpdateColumn={BoardHelper.updateColumn}
												value={row?.column_values[statusColumn?.id]?.value}
											/>
										)}
									</div>
									<div>
										{this.props.t("board.callist.fields.orgnr.label", "Orgnr")}:{" "}
										{statusColumn && (
											<CellCompanyNumber
												// onChange={this.updateCell.bind(this, orgnrColumn)}
												column={orgnrColumn}
												// onFocus={this.focusCell.bind(this)}
												// onBlur={this.blurCell.bind(this)}
												onUpdateColumn={BoardHelper.updateColumn}
												value={row?.column_values[orgnrColumn?.id]?.value}
												row={row}
											/>
										)}
									</div>
								</div>

								<InfoView fullView gap="0.3125rem" details={this.state.details} />
							</>
						)}
					</LegacyCard>
				</div>

				<div style={{ width: 50, height: 50, display: "none" }}>
					<DropZone
						openFileDialog={this.state.openFileDialog}
						onFileDialogClose={() => {
							this.setState({ openFileDialog: false });
						}}
						onDrop={(files, acceptedFiles, rejectedFiles) => {
							for (let i = 0; i < acceptedFiles.length; i++) {
								const reader = new FileReader();
								reader.onload = this.addFile.bind(this, acceptedFiles[i]);
								reader.readAsDataURL(acceptedFiles[i]);
							}
						}}
					>
						<DropZone.FileUpload
							actionTitle={this.props.t("common.upload.actions.choose", "Välj fil")}
							actionHint={this.props.t("common.upload.actions.helptext", "Eller dra och släpp en fil")}
						/>
					</DropZone>
				</div>

				<RowModal
					open={this.state.editingRow}
					row={this.props.row}
					board={this.props.board}
					groups={this.props.groups}
					onUpdateValue={(row, column, data) => {
						BoardHelper.onUpdateValue(row, column, data);
					}}
					defaultValues={[]}
					getContact={() => {}}
					onClose={() => {
						this.setState({ editingRow: false });
					}}
					onUpdateRow={(row) => {
						BoardHelper.updateRows([row], "update");
					}}
				/>

				<RowModal
					open={this.state.dealModalIsOpen}
					contact={this.state.deal.contact}
					// row={this.state.deal}
					board={this.state.dealBoard}
					groups={this.state.dealGroups || []}
					onUpdateRow={(row) => {}}
					defaultValues={this.state.deal?.values || []}
					getContact={() => {}}
					onClose={() => {
						this.props.selectFirstRow();
						this.setState({ dealModalIsOpen: false, deal: {} });
					}}
					onCreateRow={(row) => {
						this.props.selectFirstRow();
						this.setState({ dealModalIsOpen: false, deal: {} });
					}}
				/>

				<RowCommentsModal
					open={this.state.commentsModalIsOpen}
					comments={this.state.rowComments}
					onClose={() => this.setState({ commentsModalIsOpen: false })}
				/>
			</>
		);
	}
}
const mapStateToProps = (state) => ({
	board_companies: state.board_companies,
	board_contacts: state.board_contacts,
	contact_activities: state.contact_activities,
});
export default connect(mapStateToProps)(withTranslation(["board", "timeline", "common"], { withRef: true })(RowCard));
