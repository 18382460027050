import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { BlogMajor, CirclePlusMajor, JobsMajor, LegalMajor, MoneyMinor, NoteMajor, OrdersMajor, ReceiptMajor } from "@shopify/polaris-icons";
import { LegacyCard, Icon, Layout } from "@shopify/polaris";
import { connect } from "react-redux";
import ExpandableSection from "src/js/components/ExpandableSection";
import Badge from "src/js/components/Badge";
import Colors from "src/js/Colors";
import styled from "styled-components";
import useQuery from "src/js/hooks/useQuery";
import API from "../../../API";
import DocumentListSheetContent from "../../../components/DocumentListSheetContent";
import RelatedResources from "../../../components/RelatedResources";
import { toastr } from "../../../components/toastr";
import { store } from "../../../store";
import ContactContactsList from "../ContactContactsList";
import ContactTabsBar from "./ContactTabsBar";
import { DashboardIcon, DealingsIcon, People3Icon, HorizonalDots, DocumentIcon, SupportIcon } from "../../../icons";
import BoardHelper from "../../Workspaces/BoardHelper";
import ContactDocumentGroups from "../ContactDocumentGroups";
import { StyledDocumentItem } from "../../Workspaces/components/BoardUploadSheet";
import Miniboard2 from "../../Workspaces/components/Miniboard2";
// import ContactDashboard from "../dashboards/ContactDashboard";
import TimeLine from "../../../components/TimeLine";
import ContactCard from "../../../components/ContactCard";
import UpcomingActivity from "../UpcomingActivity";
import LateActivity from "../LateActivity";
import DocumentListsTabsWrapper from "../DocumentListsTabsWrapper";
import FortnoxOffersContactList from "../../Fortnox/Offers/List/FortnoxOffersContactList";
import FortnoxInvoicesContactList from "../../Fortnox/Invoices/List/FortnoxInvoicesContactList";
import FortnoxBillingContractsContactList from "../../Fortnox/BillingContracts/List/FortnoxBillingContractsContactList";
import FortnoxOrdersContactList from "../../Fortnox/Orders/List/FortnoxOrdersContactList";
import { fetchFortnoxCounts } from "../../Fortnox/Utilities";
import ContactDashboardContainer from "../dashboards/ContactDashboardContainer";
import OrdersList from "../../OrderFlow/Orders/List";

const ContactTabsWrapper = (props) => {
	const contact = props.contact;

	const fetchDocumentCount = async () => {
		if (!contact) return false;

		return await API.get(`/api/documents/${contact.id}/count.json`)
			.then((result) => {
				if (result.data.error) return;

				return result.data.document_groups;
			})
			.catch((error) => {
				console.error("error:", error);
			});
	};

	const fetchResourceCounts = async () => {
		try {
			const res = await API.get(`/api/contacts/${contact?.id}/counts.json`);
			const counts = res.data?.counts || {};

			return counts;
		} catch (e) {
			console.error("Error", e);
		}
	};

	const fetch = async () => {
		try {
			return await fetchFortnoxCounts(contact);
		} catch (error) {
			console.error("error:", error);
		}
	};
	const { data = {} } = useQuery({
		queryKey: [contact?.id && `contact_${contact?.id}_fortnox_counts`],
		queryFn: fetch,
		refetchOnWindowFocus: false,
	});
	const { data: documentGroups = [] } = useQuery({
		queryKey: [contact?.id && `contact_${contact?.id}_document_groups`],
		queryFn: fetchDocumentCount,
		refetchOnWindowFocus: false,
	});

	const { data: resourceCounts = {} } = useQuery({
		queryKey: [contact?.id && `contact_${contact?.id}_counts`],
		queryFn: fetchResourceCounts,
		refetchOnWindowFocus: false,
	});

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <ContactTabs {...props} counts={data} resourceCounts={resourceCounts} documentGroups={documentGroups} />;
};

class ContactTabs extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activeTabId: props.contactTab || (props.contact && props.contact.is_company ? "dashboard" : "dealings"),
			documentGroups: props.documentGroups || [],
			counts: props.counts || {},
		};
		this.onHashChange = this.handleHashChange.bind(this);
		this.refreshDocumentCount = this.fetchDocumentCount.bind(this, true);
	}

	handleHashChange() {
		const hash = new URL(document.URL).hash?.replace("#", "");
		if (hash) this.setState({ activeTabId: hash });
	}

	componentDidMount() {
		window.addEventListener("hashchange", this.onHashChange);
		window.addEventListener("refreshContactResources", this.refreshDocumentCount);

		const hash = new URL(document.URL).hash?.replace("#", "");
		const contactTabId = this.props.contactTab || hash;
		const enabledTabs = this.getTabs().enabledTabs;
		const tab = enabledTabs.find((tab) => tab.id === contactTabId);

		if (tab) {
			this.setState({ activeTabId: tab.id });
		} else if (enabledTabs?.[0]?.id) {
			this.setTab(enabledTabs?.[0]?.id);
		}
	}

	componentWillUnmount() {
		window.removeEventListener("hashchange", this.onHashChange);
		window.removeEventListener("refreshContactResources", this.refreshDocumentCount);
	}

	setTab(tab) {
		this.setState({ activeTabId: tab });
		const url = new URL(document.URL);
		const params = new URLSearchParams(url.search);
		const hash = `#${tab}`;
		this.props.history.replace(url.pathname + "?" + params.toString() + hash);
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.contact?.id !== this.props.contact?.id) {
			// this.fetchDocumentCount();
		}

		if (props.counts) {
			this.setState((c) => ({ counts: { ...(c?.counts || {}), ...(props.counts || {}) } }));
		}
		if (props.documentGroups) {
			this.setState({ documentGroups: props.documentGroups });
		}
	}

	fetchDocumentCount(silent) {
		if (!this.props.contact) return false;
		if (!silent) this.setState({ loadingDocuments: true });
		API.get(`/api/documents/${this.props.contact.id}/count.json`)
			.then((result) => {
				if (result.data.error) {
					console.error("error:", result.data.error);
					toastr.error(result.data.error);
					this.setState({ loadingDocuments: false });
					return;
				}

				store.dispatch({
					type: "SET_BOARDS",
					boards: result.data.boards,
				});

				this.setState({ loadingDocuments: false, documentGroups: result.data.document_groups });
			})
			.catch((error) => {
				console.error("error:", error);
				this.setState({ loadingDocuments: false });
				toastr.error(error);
			});
	}

	setCounts(id, count) {
		this.state.counts[String(id)] = count;
		this.setState({ counts: this.state.counts });
	}

	getTabs() {
		const haveSalesBoard =
			store.getState().account.sales_board_id && store.getState().workspaces && store.getState().workspaces.find((w) => w.title === "Försäljning");
		const serviceDeskWorkspace = store.getState().workspaces?.find((w) => w.title === "Servicedesk");
		const serviceDeskBoard =
			store.getState().boards[store.getState().account.support_board_id] ||
			serviceDeskWorkspace?.boards?.find((b) => b.id === store.getState().account.support_board_id);

		const haveServiceDesk =
			(serviceDeskWorkspace
				? store.getState().user.roles.includes("ROLE_WORKSPACE_" + serviceDeskWorkspace.id)
				: store.getState().user.roles.includes("ROLE_SUPPORT")) && serviceDeskBoard;
		const fortnoxScopes = store.getState().account.fortnox_integration?.authentication.scope?.split(" ") || [];

		const tabs = [
			{
				title: this.props.t("contact.tabs.dashboard.label", "Dashboard"),
				id: "dashboard",
				icon: <DashboardIcon />,
				enabled: this.props.contact.is_company,
				content: (
					// <div style={{ display: this.state.activeTabId === "dashboard" ? "unset" : "none" }}>
					<ContactDashboardContainer
						setTab={this.setTab.bind(this)}
						updateForm={this.props.updateForm}
						contact={this.props.contact}
						history={this.props.history}
						setCounts={this.setCounts.bind(this)}
					/>
				),
				// </div>
			},
			{
				title: this.props.t("contact.tabs.company_and_activity.label", "Företagsinfo & Aktivitet"),
				id: "info",
				icon: <Icon source={JobsMajor} />,
				// icon: <PinIcon />,
				enabled: this.props.contact.is_company,
				content: (
					<Layout>
						<Layout.Section>
							<ContactCard
								expanded
								contact={this.props.contact}
								openContactModal={() => {
									this.props.openContactModal(this.props.contact);
								}}
								openContactModalParent={() => {
									this.openContactModalParent(this, this.props.contact.parent);
								}}
								compact
								onUpdate={(contact) => {
									if (!contact.is_company && contact.id != this.props.contact.id) {
										this.props.history.push("/admin/contacts/" + contact.id);
									}
								}}
								gotoContact={this.props.gotoContact}
								loading={this.props.loading}
								external={this.props.external}
							/>
						</Layout.Section>
						<Layout.Section secondary>
							<TimeLine
								expanded
								ref={(ref) => {
									this.timeLine = ref;
								}}
								disabled={this.props.contact?.removed}
								history={this.props.history}
								contact={this.props.contact}
								isCompany={this.props.contact.is_company}
							/>
						</Layout.Section>
					</Layout>
				),
			},
			{
				title: this.props.t("contact.tabs.contacts.label", "Kontakter"),
				id: "contacts",
				enabled: this.props.contact && this.props.contact.is_company,
				icon: <People3Icon />,
				count: this.props.children && this.props.children.length,
				loading: this.props.loadingChildren,
				content: (
					<div>
						<ContactContactsList
							items={this.props.children}
							contact={this.props.contact}
							// addTags={this.addTags.bind(this)}
							// removeTags={this.removeTags.bind(this)}
							openContactModal={this.props.openContactModal}
							gotoContact={this.props.gotoContact}
							updateForm={this.props.updateForm}
							loading={this.props.loadingChildren}
						/>
					</div>
				),
			},
			{
				title: this.props.t("contact.tabs.upcoming_tasks.label", "Kommande aktiviteter"),
				id: "upcoming_tasks",
				icon: <DealingsIcon color="var(--textColor)" />,
				count: this.state.counts.upcoming_tasks,
				content: (
					<div style={{ marginTop: "1.0000rem", position: "relative" }}>
						<UpcomingActivity
							contact={this.props.contact}
							onFetch={(data) => {
								this.setCounts("upcoming_tasks", data?.count);
							}}
						/>
					</div>
				),
			},
			{
				title: this.props.t("contact.tabs.late_tasks.label", "Försenade aktiviteter"),
				id: "late_tasks",
				icon: <DealingsIcon color="var(--textColor)" />,
				count: this.state.counts.late_tasks,
				content: (
					<div style={{ marginTop: "1.0000rem", position: "relative" }}>
						<LateActivity
							contact={this.props.contact}
							onFetch={(data) => {
								this.setCounts("late_tasks", data?.count);
							}}
						/>
					</div>
				),
			},
			{
				title: this.props.t("contact.tabs.dealings.label", "Affärer"),
				id: "dealings",
				icon: MoneyMinor,
				enabled: haveSalesBoard,
				count: this.state.counts[String(store.getState().account.sales_board_id)],
				content: (
					<div style={{ marginTop: "1.0000rem", position: "relative" }}>
						<Miniboard2
							board={store.getState().boards[store.getState().account.sales_board_id]}
							board_id={store.getState().account.sales_board_id}
							contact_id={this.props.contact.id}
							contact={this.props.contact}
							setCount={this.setCounts.bind(this, store.getState().account.sales_board_id)}
							params={{ sort: "CREATED_AT_DESC" }}
						/>
					</div>
				),
			},
			{
				title: this.props.t("contact.tabs.service_desk.label", "Servicedesk"),
				id: "service_desk",
				icon: <SupportIcon color="var(--textColor)" />,
				enabled: haveServiceDesk,
				count: this.state.counts[String(serviceDeskBoard?.id)],
				content: (
					<div style={{ marginTop: "1.0000rem", position: "relative" }}>
						<Miniboard2
							board={store.getState().boards[serviceDeskBoard?.id]}
							board_id={serviceDeskBoard?.id}
							contact_id={this.props.contact.id}
							contact={this.props.contact}
							setCount={this.setCounts.bind(this, serviceDeskBoard?.id)}
							params={{ sort: "CREATED_AT_DESC" }}
						/>
					</div>
				),
			},
			{
				title: this.props.t("contact.tabs.offers.label", "Offerter"),
				id: "offers",
				ids: ["offers", "fortnox_offers"],
				icon: BlogMajor,
				loading: this.state.loadingDocuments,
				count: (() => {
					if (this.state.counts.fortnox_offers) return this.state.counts.fortnox_offers;
					const group =
						this.state.documentGroups &&
						this.state.documentGroups.find((g) => g.board_id === store.getState().account.sales_board_id && g.column_title === "Offerter");

					return group && group.count;
				})(),
				enabled:
					haveSalesBoard ||
					(store.getState().account.fortnox_integration && this.props.contact?.fortnox_customer_id && fortnoxScopes.includes("order")),
				content: (
					<DocumentListsTabsWrapper
						tabs={[
							{
								title: this.props.t("contact.tabs.fortnox_offers.label", "Fortnox offerter"),
								badge: <Badge color={Colors.green}>{this.state.counts.fortnox_offers}</Badge>,
								id: "fortnox_offers",
								icon: BlogMajor,
								content: (
									<FortnoxOffersContactList
										contact={this.props.contact}
										history={this.props.history}
										setCount={this.setCounts.bind(this, "fortnox_offers")}
									/>
								),
								enabled:
									store.getState().account.fortnox_integration &&
									this.props.contact?.fortnox_customer_id &&
									fortnoxScopes.includes("order") &&
									(!store.getState().account?.contact_tabs?.length || store.getState().account?.contact_tabs?.includes("fortnox_offers")),
							},
							{
								title: this.props.t("contact.tabs.offerts.label", "Offerter"),
								badge: (
									<Badge color={Colors.green}>
										{(() => {
											const group =
												this.state.documentGroups &&
												this.state.documentGroups.find(
													(g) => g.board_id === store.getState().account.sales_board_id && g.column_title === "Offerter"
												);

											return group && group.count;
										})()}
									</Badge>
								),

								id: "offers",
								icon: BlogMajor,
								content: (
									<DocumentListSheetContent
										noHeader
										createButtonTitle={this.props.t("contact.tabs.actions.new_quote", "Ny offert")}
										column={this.getColumn("offerter")}
										contact={this.props.contact}
										board={this.getBoard("offerter")}
										refresh={this.fetchDocumentCount.bind(this, true)}
										noSheet
										loading={this.state.loadingDocuments}
										history={this.props.history}
									/>
								),
								enabled: haveSalesBoard && this.getColumn("offerter"),
							},
						].filter((i) => !("enabled" in i) || i.enabled)}
					/>
				),
			},
			{
				title: this.props.t("contact.tabs.orders.label", "Ordrar"),
				id: "orders",
				ids: ["orders", "fortnox_orders"],
				icon: OrdersMajor,
				count: (this.state.counts.fortnox_orders || 0) + (this.props.resourceCounts?.orders || 0),

				content: (
					<DocumentListsTabsWrapper
						tabs={[
							{
								title: this.props.t("contact.tabs.fortnox_orders.label1", "Fortnox ordrar"),
								badge: <Badge color={Colors.green}>{this.state.counts.fortnox_orders}</Badge>,
								id: "fortnox_orders",
								icon: BlogMajor,
								content: (
									<FortnoxOrdersContactList
										contact={this.props.contact}
										history={this.props.history}
										setCount={this.setCounts.bind(this, "fortnox_orders")}
									/>
								),
								enabled:
									this.props.contact?.fortnox_customer_id &&
									fortnoxScopes.includes("order") &&
									(!store.getState().account?.contact_tabs?.length || store.getState().account?.contact_tabs?.includes("fortnox_orders")),
							},
							{
								title: this.props.t("contact.tabs.orders.label", "Ordrar"),
								badge: <Badge color={Colors.green}>{this.props.resourceCounts?.orders}</Badge>,

								id: "orders",
								icon: BlogMajor,
								content: (
									<OrdersList
										params={{ contact_id: this.props.contact.id }}
										noPage
										titleHidden
										history={this.props.history}
										primaryAction={{
											content: this.props.t("contact.tabs.actions.new_order", "Ny order"),
											onAction: () => {
												this.props.history.push("/admin/orders/create", { contact: this.props.contact, state: { contact: this.props.contact } });
											},
											plain: true,
											icon: CirclePlusMajor,
											primary: false,
										}}
										// createButtonTitle={this.props.t("contact.tabs.actions.new_quote", "Ny offert")}
										// column={this.getColumn("offerter")}
										contact={this.props.contact}
										// board={this.getBoard("offerter")}
										// refresh={this.fetchDocumentCount.bind(this, true)}
										// noSheet
										// loading={this.state.loadingDocuments}
										// history={this.props.history}
									/>
								),
								enabled:
									(!store.getState().account?.contact_tabs?.length || store.getState().account?.contact_tabs?.includes("orders")) &&
									//!TEMP FOR TESTING
									([1514, 24, 1566].includes(store.getState().account?.id) || [2, 3, 419].includes(store.getState().user?.id)),
							},
						].filter((i) => !("enabled" in i) || i.enabled)}
					/>
				),
			},
			{
				title: this.props.t("contact.tabs.fortnox_invoices.label", "Fakturor"),
				id: "fortnox_invoices",
				icon: ReceiptMajor,
				loading: this.state.loadingInvoices,
				count: this.state.counts.fortnox_invoices,
				enabled: !!store.getState().account.fortnox_integration && this.props.contact?.fortnox_customer_id,
				content: (
					<FortnoxInvoicesContactList
						contact={this.props.contact}
						history={this.props.history}
						setCount={this.setCounts.bind(this, "fortnox_invoices")}
					/>
				),
			},
			{
				title: this.props.t("contact.tabs.fortnox_contracts.label", "Avtalsfakturering"),
				id: "fortnox_contracts",
				icon: LegalMajor,
				count: this.state.counts.fortnox_contracts,
				enabled: !!store.getState().account.fortnox_integration && this.props.contact?.fortnox_customer_id,
				content: (
					<FortnoxBillingContractsContactList
						contact={this.props.contact}
						history={this.props.history}
						setCount={this.setCounts.bind(this, "fortnox_contracts")}
					/>
				),
			},

			{
				title: this.props.t("contact.tabs.contracts.label", "Avtal"),
				id: "contracts",
				icon: NoteMajor,
				loading: this.state.loadingDocuments,
				count: (() => {
					const group =
						this.state.documentGroups &&
						this.state.documentGroups.find((g) => g.board_id === store.getState().account.sales_board_id && g.column_title === "Avtal");

					return group && group.count;
				})(),
				enabled: haveSalesBoard,
				content: this.getColumn("avtal") ? (
					<DocumentListSheetContent
						noHeader
						createButtonTitle={this.props.t("contact.tabs.actions.new_deal", "Nytt avtal")}
						column={this.getColumn("avtal")}
						contact={this.props.contact}
						board={this.getBoard("avtal")}
						refresh={this.fetchDocumentCount.bind(this, true)}
						noSheet
						loading={this.state.loadingDocuments}
						history={this.props.history}
					/>
				) : (
					<React.Fragment>
						<StyledDocumentItem style={{ height: "125px" }} />
						<StyledDocumentItem style={{ height: "100px" }} />
						<StyledDocumentItem style={{ height: "125px" }} />
					</React.Fragment>
				),
			},

			{
				title: this.props.t("contact.tabs.documents.label", "Dokument"),
				id: "documents",
				icon: <DocumentIcon />,
				loading: this.state.loadingDocuments,
				count: (() => {
					const count =
						this.state.documentGroups &&
						this.state.documentGroups.reduce((acc, g) => {
							if (g.board_id === store.getState().account.sales_board_id && (g.column_title === "Avtal" || g.column_title === "Offerter")) {
								return acc;
							}

							return acc + g.count;
						}, 0);

					return count;
				})(),
				content: (
					<ContactDocumentGroups
						contact={this.props.contact}
						refresh={this.fetchDocumentCount.bind(this, true)}
						documentGroups={
							this.state.documentGroups &&
							this.state.documentGroups.filter((g) => {
								if (g.board_id === store.getState().account.sales_board_id && (g.column_title === "Avtal" || g.column_title === "Offerter"))
									return false;

								return true;
							})
						}
						loading={this.state.loadingDocuments}
						history={this.props.history}
					/>
				),
			},
		].filter((i) => !("enabled" in i) || i.enabled);

		const enabledTabs = store.getState().account?.contact_tabs?.length
			? tabs.filter(
					(tab) =>
						store.getState().account?.contact_tabs?.includes(tab.id) || store.getState().account?.contact_tabs?.some((t) => tab.ids?.includes(t))
			  )
			: tabs;

		return { tabs, enabledTabs };
	}

	getBoard(columnTilte) {
		if (columnTilte === "offerter" || columnTilte === "avtal") {
			const board = BoardHelper.getBoard(store.getState().account.sales_board_id);
			return board;
		}
	}

	getColumn(columnTilte) {
		const board = this.getBoard(columnTilte);

		const group =
			this.state.documentGroups &&
			this.state.documentGroups.find((g) => g.column_title.toLowerCase() === columnTilte && g.board_id === store.getState().account.sales_board_id);
		if (board && !group) return null;
		return board && group && board.columns.find((column) => column.id === group.column_id);
	}

	render() {
		const { tabs, enabledTabs } = this.getTabs();

		const enabledTabsIds = enabledTabs.map((tab) => tab.id);
		const hiddenTabs = tabs.filter((tab) => !enabledTabsIds?.includes(tab.id));

		const restTab = {
			title: this.props.t("contact.tabs.rest.label", "Övrigt"),
			id: "rest",
			icon: <HorizonalDots />,
			content: (
				<div style={{ display: this.state.activeTabId === "rest" ? "block" : "none" }}>
					{!!hiddenTabs?.length && (
						<div style={{ marginBottom: "0.6250rem" }}>
							<LegacyCard>
								{hiddenTabs?.map((tab) => {
									const count = typeof tab.count === "function" ? tab.count() : tab.count;
									return (
										<TabInRestWrapper key={tab.id}>
											<ExpandableSection
												icon={tab.icon}
												// link={boardLink}
												title={tab.title}
												resourceName={{}}
												onExpand={() => {}}
												count={count && <Badge color={Colors.yellow}>{count}</Badge>}
												history={this.props.history}
											>
												{tab.content}
											</ExpandableSection>
										</TabInRestWrapper>
									);
								})}
							</LegacyCard>
						</div>
					)}

					<div style={{ position: "relative" }}>
						<RelatedResources
							type="contact"
							// ref={(r) => {
							// 	this.relatedResources = r;
							// }}
							contact={this.props.contact}
							is_company={this.props.contact.is_company}
							name={this.props.contact.name}
							id={this.props.contact.id}
							parent={this.props.contact.parent}
							location={this.props.location}
							history={this.props.history}
							onUpload={() => {
								toastr.success(this.props.t("common.responses.file.uploaded", "File uppladdad"));
								this.timeLine?.fetchComments();
							}}
							setCounts={(counts) => {
								this.setState((c) => ({ counts: Object.assign(c.counts, counts) }));
							}}
							setSecondaryActions={this.props.setSecondaryActions}
							refresh={this.props.refresh}
						/>
					</div>
				</div>
			),
		};

		const activeTab = enabledTabs.find((tab) => tab.id === this.state.activeTabId);
		return (
			<div className="contact_tabs" style={this.props.style}>
				<ContactTabsBar
					tabs={[...enabledTabs, restTab]}
					activeTabId={this.state.activeTabId}
					onChange={this.setTab.bind(this)}
					contact={this.props.contact}
				>
					{this.props.children}
				</ContactTabsBar>

				<div key={activeTab?.id}>{activeTab?.content}</div>

				<div style={{ display: this.state.activeTabId === restTab.id ? "block" : "none" }}>{restTab.content}</div>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	// caused modals in children  to renreder an close the modal
	// user: state.user,
	account: state.account,
});

export default connect(mapStateToProps)(withTranslation(["contact", "common"], { withRef: true })(ContactTabsWrapper));

const TabInRestWrapper = styled.div`
	display: flex;
	flex-direction: column;

	.icon svg {
		width: 19px;
	}
`;
