import React, { useRef, useState } from "react";
import { Badge, Avatar, HorizontalStack } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import UserInviter from "../../components/UserInviter.js";
import IndexTable from "src/js/components/IndexTable/IndexTable";
import Modal from "src/js/components/modal";

const CustomerIndex = ({ t, ...props }: any) => {
	const [showInviteModal, setShowInviteModal] = useState(false);
	const refreshHandler = useRef<any>();

	const filters = [
		{
			key: "role",
			label: t("user.filters.package.label", "Användarpaket"),
			type: "select",
			operatorText: "har",
			options: [
				{ value: "ROLE_MARKETING", label: t("user.roles.marketing", "Marknadsföring") },
				{ value: "ROLE_SUPPORT", label: t("user.roles.support", "Servicedesk") },
				{ value: "ROLE_SALES", label: t("user.roles.sales", "Försäljning") },
				{ value: "ROLE_PROJECT", label: t("user.roles.project", "Projekthantering") },
				{ value: "ROLE_ADMIN", label: t("user.roles.admin", "Administratör") },
			],
			shortcut: true,
		},
		{
			key: "enabled",
			label: t("user.filters.disabled.label", "avstängd"),
			type: "select",
			options: [
				{ value: "0", label: t("common.terms.yes", "Ja") },
				{ value: "1", label: t("common.terms.no", "Nej") },
			],
			shortcut: true,
		},
	];

	return (
		<div>
			<IndexTable
				onClickRow={(item) => {
					props.history.push("/admin/users/" + item.id);
				}}
				columns={[
					{
						handle: "name",
						label: t("user.fields.name", "Namn"),
						sortable: true,
						render: (item) => {
							return (
								<HorizontalStack blockAlign="center" gap="2">
									<Avatar size="medium" initials={item.initials} name={item.name} source={item.avatar} />
									{item.name}
									{!item.enabled ? <Badge>{t("user.statuses.disabled", "avstängd")}</Badge> : null}
								</HorizontalStack>
							);
						},
					},
					{
						handle: "group",
						label: t("user.fields.group", "Grupp"),
						sortable: true,
						render: (item) => {
							return item.group ? item.group.name : "";
						},
					},
				]}
				history={props.history}
				setRefreshHandler={(refreshHandler) => {
					refreshHandler.current = refreshHandler;
				}}
				title={t("user.title", "Användare")}
				primaryAction={{
					content: t("user.actions.invite", "Bjud in användare"),
					onAction: () => {
						setShowInviteModal(true);
					},
				}}
				savedSearchHandle="users"
				resourceUrl="/api/users.json"
				resourceHandle="users"
				resourceName={{
					singular: t("user.singular", "användare"),
					plural: t("user.plural", "användare"),
				}}
				filters={filters}
			/>
			<Modal
				open={showInviteModal}
				onClose={() => {
					setShowInviteModal(false);
				}}
				title={t("user.actions.invite", "Bjud in användare")}
				secondaryActions={[
					{
						content: t("common.actions.close", "Stäng"),
						onAction: () => {
							setShowInviteModal(false);
						},
					},
				]}
			>
				<Modal.Section>
					<UserInviter
						onSave={() => {
							setShowInviteModal(false);
							refreshHandler.current();
						}}
					/>
				</Modal.Section>
			</Modal>
		</div>
	);
};

export default withTranslation(["user", "common"], { withRef: true })(CustomerIndex);
